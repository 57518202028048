<template>
  <div>
    <div class="overlay_layer" v-if="isShowRange">
      <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
      <div class="summary_rangecalendar_layer">
        <div class="summary_rangecalendar">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector">
              <RangeCalendar
                :range="dateRangeSelector.timeYmdRange"
                :todayYmd="getTodayYmd()"
                @rangecalendarevent="rangecalendarevent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_content_wrapper">
      <div class="adx_report_keyword">
        <div class="left_main_content">
          <div style="background: white; width: calc(100% - 20px)">
            <div class="left_main_content__header" style="position: relative">
              <ContentHeader
                title="サマリー"
                :timeRange="dateRangeSelector"
                :adx="adx"
                @timerangeevent="timerangeevent"
              />
              <div style="position: absolute; top: 76px; left: 0px; right: 0">
                <ScreenBarProgress2 :process="page.process" />
              </div>
            </div>
          </div>

          <div class="left_main_content__body">
            <div class="box" style="width: calc(100% - 20px)">
              <div class="frame frame--expansion" style="width: 100%">
                <div class="box" style="width: 100%">
                  <div style="width: 100%">
                    <div class="frame__header frame__header--expansion_lock" v-if="!isRangeMode()">
                      <div :style="{ paddingLeft: '1px' }">
                        <div
                          :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                          v-on:click="copyToClipboard('summary_table')"
                        >
                          {{ title01() }}
                        </div>
                      </div>
                    </div>
                    <BoxSpinner
                      v-if="page.isMonthlyTableNetworking || !isLoad"
                      style="height: 143px; width: 85.7143%; max-width: 1600px"
                    />
                    <div
                      class="frame__body scroll_bar"
                      v-if="!isRangeMode()"
                      style="overflow: auto"
                    >
                      <Spinner v-if="page.isMonthlyTableNetworking && false" />
                      <div
                        :style="
                          summarytalbewidth(
                            'summary',
                            this.page.monthlyTable.getHeaderCell('種別'),
                            'summary'
                          )
                        "
                      >
                        <table
                          id="summary_table_tag"
                          v-bind:class="[dragstate > 0 ? 'draglock' : '']"
                          v-if="!page.isMonthlyTableNetworking && isLoad"
                          class="floater_table floater_table--full summary_table expansion_table"
                          style="table-layout: auto"
                        >
                          <thead class="floater_table__header expansion_table__header">
                            <tr
                              v-if="
                                isCells2SpanCells(
                                  page.monthlyTable.getHeaderCell('種別'),
                                  'summary'
                                )
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  page.monthlyTable.getHeaderCell('種別'),
                                  'summary'
                                )"
                                :key="index"
                              >
                                <th
                                  style="background: white; border: initial; position: relative"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <template
                                v-for="(item, index) in page.monthlyTable.getHeaderCell('種別')"
                                :key="index"
                              >
                                <th
                                  style="cursor: pointer; position: relative"
                                  ref="summary_header_ref"
                                  :id="'summary_header_id_' + index"
                                  v-on:click.stop="showShowHide($event, index, 'summary')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  v-if="isShowSummaryColum(index, 'summary')"
                                  :style="[teststyle('summary', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'summary'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('summary', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ spanTilte2HeaderTitle(item.getString('consultant')) }}
                                </th>
                              </template>
                            </tr>
                          </thead>

                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.monthlyTable.getMonthSummary()"
                              :key="index"
                            >
                              <tr
                                v-bind:class="[
                                  index == 0 ? 'consultant_record__trmark' : '',
                                  index == 3 ? 'consultant_record__trmark2' : '',
                                ]"
                                v-if="(index != 2 || (index == 2 && page.isCurrentMonth() == true))"
                              >
                                <template v-for="(cell, cindex) in cellColms" :key="cindex">
                                  <td
                                    v-if="(index == 3) & isShowSummaryColum(cindex, 'summary')"
                                    :set="
                                      (cellDesign = page.monthlyTable.getMonthSummaryCellDesign(
                                        cell.data,
                                        cindex
                                      ))
                                    "
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__summary_weekly"
                                  >
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                      :class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    >
                                      <div
                                        :class="[cindex == 0 ? 'font_smaller' : '']"
                                        style="position: relative; margin-left: auto"
                                        :style="[
                                          cindex != 0 ? 'width: fit-content' : '',
                                          cellDesign.cellclass != null
                                            ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px'
                                            : '',
                                        ]"
                                      >
                                        <svg
                                          v-if="cellDesign.cellclass != null"
                                          v-bind:class="cellDesign.cellclass"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 11.268 13.002"
                                        >
                                          <path
                                            id="合体_22"
                                            data-name="合体 22"
                                            d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                            transform="translate(-10732.001 -12773.001)"
                                          />
                                        </svg>

                                        {{ titlewrapper(cell.getString('consultant')) }}
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    v-if="index != 3 && isShowSummaryColum(cindex, 'summary')"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__summary_weekly expansion_table__td"
                                  >
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                      :class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    >
                                      <div :class="[cindex == 0 ? 'font_smaller' : '']">
                                        {{ titlewrapper(cell.getString('consultant')) }}
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" style="width: calc(100% - 20px)">
              <div class="frame frame--expansion" style="width: 100%">
                <div class="box" style="width: 100%">
                  <div style="width: 100%">
                    <div class="frame__header frame__header--expansion_lock" v-if="!isRangeMode()">
                      <div :style="{ paddingLeft: '1px' }">
                        <div
                          :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                          v-on:click="
                            copyToClipboard(
                              page.selectedMode == 1
                                ? 'monthly_table'
                                : page.selectedMode == 2
                                ? 'weekly_table'
                                : page.selectedMode == 3
                                ? 'daily_table'
                                : ''
                            )
                          "
                        >
                          {{ getModeName() }}実績
                        </div>
                      </div>
                    </div>
                    <div class="frame__body">
                      <div :style="{ paddingLeft: '1px' }" />
                      <div style="display: flex">
                        <div
                          class="selectbtn clickable"
                          v-bind:class="[page.selectedMode == 1 ? 'selectbtn--active' : '']"
                          v-on:click="changeMode(1)"
                        >
                          月別
                        </div>
                        <div
                          class="selectbtn clickable"
                          v-bind:class="[page.selectedMode == 2 ? 'selectbtn--active' : '']"
                          v-on:click="changeMode(2)"
                        >
                          週別
                        </div>
                        <div
                          class="selectbtn clickable"
                          v-bind:class="[page.selectedMode == 3 ? 'selectbtn--active' : '']"
                          v-on:click="changeMode(3)"
                        >
                          日別
                        </div>
                      </div>
                    </div>
                    <BoxSpinner
                      v-if="page.isMonthlyTableNetworking || !isLoad"
                      style="height: 263px; width: 85.7143%; max-width: 1600px"
                    />
                    <div
                      class="frame__body scroll_bar"
                      v-if="page.selectedMode == 1"
                      style="overflow: auto"
                    >
                      <div
                        class="nodataline"
                        v-if="!page.isMonthlyResultNetworking && page.isMonthlyResultNoData"
                      >
                        NoData
                      </div>
                      <div
                        :style="
                          summarytalbewidth(
                            'mwd',
                            page.monthlyTable.getHeaderCell('種別'),
                            'monthly'
                          )
                        "
                      >
                        <Spinner v-if="page.isMonthlyTableNetworking && false" />
                        <table
                          id="mwd_table_tag"
                          class="floater_table floater_table--full monthly_table expansion_table"
                          v-else
                        >
                          <thead class="floater_table__header expansion_table__header">
                            <tr
                              v-if="
                                isCells2SpanCells(
                                  page.monthlyTable.getHeaderCell('種別'),
                                  'monthly'
                                )
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  page.monthlyTable.getHeaderCell('種別'),
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <th
                                  class="sticky_title"
                                  style="background: white; border: initial"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <template
                                v-for="(item, index) in page.monthlyTable.getHeaderCell('種別')"
                                :key="index"
                              >
                                <th
                                  style="position: relative; cursor: pointer"
                                  ref="mwd_header_ref"
                                  :id="'mwd_header_id_' + index"
                                  v-on:click.stop="showShowHide($event, index, 'monthly')"
                                  v-if="isShowSummaryColum(index, 'monthly')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  :style="[teststyle('mwd', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'mwd'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('mwd', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ mwd_title_change(spanTilte2HeaderTitle(item.getString('consultant'))) }}
                                </th>
                              </template>
                            </tr>
                          </thead>

                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.monthlyTable.getTable()"
                              :key="index"
                            >
                              <tr>
                                <template v-for="(cell, cindex) in cellColms" :key="cindex">
                                  <td
                                    v-if="false"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__summary_weekly"
                                  >
                                    {{ cell.getString('consultant') }}
                                  </td>

                                  <td
                                    v-if="isShowSummaryColum(cindex, 'monthly')"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__summary_weekly"
                                    style="padding: 0px; position: relative"
                                    :set="
                                      (cellDesign = page.monthlyTable.getCellDesign(
                                        cell.data,
                                        cindex
                                      ))
                                    "
                                  >
                                    <div
                                      v-bind:style="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        padding: cellDesign.design == 'box' ? '0px' : '4px',
                                        paddingLeft: '0px',
                                      }"
                                    >
                                      <div
                                        style="height: 100%; width: "
                                        v-bind:style="{
                                          width: cellDesign.isColored
                                            ? cellDesign.percent + '%'
                                            : '0%',
                                          background: cellDesign.isColored
                                            ? cellDesign.color
                                            : '#FFFFFF',
                                          border:
                                            cellDesign.border != null ? cellDesign.border : '',
                                        }"
                                      ></div>
                                    </div>
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                    >
                                      <div
                                        v-if="!cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant') }}
                                      </div>
                                      <div
                                        v-if="cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant').replace('(着地想定)', '') }}
                                        <div style="font-size: 80%; display: inline-block">
                                          (着地想定)
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="frame__body scroll_bar"
                      style="overflow: auto"
                      v-if="page.selectedMode == 2"
                    >
                      <div
                        class="nodataline"
                        v-if="!page.isMonthlyResultNetworking && page.isMonthlyResultNoData"
                      >
                        NoData
                      </div>
                      <div
                        :style="
                          summarytalbewidth(
                            'mwd',
                            page.monthlyTable.getHeaderCell('種別'),
                            'monthly'
                          )
                        "
                      >
                        <Spinner v-if="page.isWeeklyTableNetworking" />
                        <table
                          id="mwd_table_tag"
                          v-else
                          class="floater_table floater_table--full weekly_table expansion_table"
                        >
                          <thead class="floater_table__header expansion_table__header">
                            <tr
                              v-if="
                                page.weeklyTable.cellRows.length > 0 &&
                                isCells2SpanCells(page.weeklyTable.getHeaderCell('週'), 'monthly')
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  page.weeklyTable.getHeaderCell('週'),
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <th
                                  style="background: white; border: initial"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                              </template>
                            </tr>
                            <tr v-if="page.weeklyTable.cellRows.length > 0">
                              <template
                                v-for="(item, index) in page.weeklyTable.getHeaderCell('週')"
                                :key="index"
                              >
                                <th
                                  ref="mwd_header_ref"
                                  :id="'mwd_header_id_' + index"
                                  style="position: relative; cursor: pointer"
                                  v-on:click.stop="showShowHide($event, index, 'monthly')"
                                  v-if="isShowSummaryColum(index, 'monthly')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  :style="[teststyle('mwd', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'mwd'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('mwd', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ mwd_title_change(spanTilte2HeaderTitle(item.getString('consultant'))) }}
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.weeklyTable.getTable()"
                              :key="index"
                            >
                              <tr>
                                <template v-for="(cell, cindex) in cellColms.cells" :key="cindex">
                                  <td
                                    v-if="isShowSummaryColum(cindex, 'monthly')"
                                    class="floater_table__summary_weekly"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    style="padding: 0px; position: relative"
                                    :set="
                                      (cellDesign = page.weeklyTable.getCellDesign(
                                        cell.data,
                                        cindex
                                      ))
                                    "
                                  >
                                    <div
                                      v-bind:style="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        padding: cellDesign.design == 'box' ? '0px' : '4px',
                                        paddingLeft: '0px',
                                      }"
                                    >
                                      <div
                                        style="height: 100%; width: "
                                        v-bind:style="{
                                          width: cellDesign.isColored
                                            ? cellDesign.percent + '%'
                                            : '0%',
                                          background: cellDesign.isColored
                                            ? cellDesign.color
                                            : '#FFFFFF',
                                          border:
                                            cellDesign.border != null ? cellDesign.border : '',
                                        }"
                                      ></div>
                                    </div>
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                    >
                                      <div
                                        v-if="!cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant') }}
                                      </div>
                                      <div
                                        v-if="cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant').replace('(着地想定)', '') }}
                                        <div style="font-size: 80%; display: inline-block">
                                          (着地想定)
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="frame__body scroll_bar"
                      style="overflow: auto"
                      v-if="page.selectedMode == 3"
                    >
                      <div
                        class="nodataline"
                        v-if="!page.isMonthlyResultNetworking && page.isMonthlyResultNoData"
                      >
                        NoData
                      </div>
                      <div
                        :style="
                          summarytalbewidth(
                            'mwd',
                            page.monthlyTable.getHeaderCell('種別'),
                            'monthly'
                          )
                        "
                      >
                        <Spinner v-if="page.isDailyTableNetWorking" />
                        <table
                          id="mwd_table_tag"
                          v-else
                          class="floater_table floater_table--full daily_table expansion_table"
                        >
                          <thead class="floater_table__header expansion_table__header" v-if="true">
                            <tr
                              v-if="
                                isCells2SpanCells(
                                  titles2Cell(page.dailyTable.getTableTitle()),
                                  'monthly'
                                )
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  titles2Cell(page.dailyTable.getTableTitle()),
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <th
                                  style="background: white; border: initial"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                                <th
                                  style="background: white; border: initial"
                                  v-if="index==0">
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <template
                                v-for="(title, index) in page.dailyTable.getTableTitle()"
                                :key="index"
                              >
                                <th
                                  ref="mwd_header_ref"
                                  :id="'mwd_header_id_' + index"
                                  style="position: relative; cursor: pointer"
                                  v-on:click.stop="showShowHide($event, index, 'monthly')"
                                  v-if="isShowSummaryColum(index, 'monthly')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  :style="[teststyle('mwd', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'mwd'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('mwd', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ mwd_title_change(spanTilte2HeaderTitle(title)) }}
                                </th>
                                <th class="floater_table__summary_weekly table_dow_header" v-if="index==0" style="width: 47px">
                                  曜日
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.dailyTable.getTable()"
                              :key="index"
                            >
                              <tr v-if="true">
                                <td
                                  class="floater_table__summary_weekly floater_table__titlecell"
                                  style="padding: 0px; position: relative"
                                >
                                  <div
                                    style="
                                      padding: 8px;
                                      position: relative;
                                      top: 0;
                                      left: 0;
                                      right: 0;
                                      bottom: 0;
                                    "
                                  >
                                    {{ cellColms.name }}
                                  </div>
                                </td>
                                <td class="table_dow_cell" style="width: 47px">{{ cellColms.dayOfWeek }}</td>
                                <template v-for="(item, cindex) in cellColms.items" :key="cindex">
                                  <td
                                    v-if="isShowSummaryColum(cindex + 1, 'monthly')"
                                    class="floater_table__summary_weekly"
                                    style="padding: 0px; position: relative"
                                    :set="
                                      (cellDesign = page.dailyTable.getCellDesign(
                                        item.cell.data,
                                        cindex
                                      ))
                                    "
                                  >
                                    <div
                                      v-bind:style="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        padding: cellDesign.design == 'box' ? '0px' : '4px',
                                        paddingLeft: '0px',
                                      }"
                                    >
                                      <div
                                        style="height: 100%; width: "
                                        v-bind:style="{
                                          width: cellDesign.isColored
                                            ? cellDesign.percent + '%'
                                            : '0%',
                                          background: cellDesign.isColored
                                            ? cellDesign.color
                                            : '#FFFFFF',
                                          border:
                                            cellDesign.border != null ? cellDesign.border : '',
                                        }"
                                      ></div>
                                    </div>
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                    >
                                      <div
                                        v-if="
                                          !item.cell.getString('consultant').includes('(着地想定)')
                                        "
                                      >
                                        {{ item.cell.getString('consultant') }}
                                      </div>
                                      <div
                                        v-if="
                                          item.cell.getString('consultant').includes('(着地想定)')
                                        "
                                      >
                                        {{
                                          item.cell
                                            .getString('consultant')
                                            .replace('(着地想定)', '')
                                        }}
                                        <div style="font-size: 80%; display: inline-block">
                                          (着地想定)
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style="height: 8px; width: 100%" v-on:click="toggleAdtDbg"></div>
                    <div class="frame__body" style="margin-top: 20px" v-if="isDbgAdTable">
                      <div :style="{ paddingLeft: '1px' }" />
                      <div style="display: flex">
                        <template
                          v-for="(provider, index) in page.getProviderTableProviderList()"
                          :key="index"
                        >
                          <div
                            class="selectbtn clickable"
                            v-bind:class="[
                              isProviderSelected(provider) == true ? 'selectbtn--active' : '',
                            ]"
                            v-on:click="providerSelect(provider)"
                          >
                            {{ provider.label }}
                          </div>
                        </template>
                      </div>
                    </div>
                    <div style="overflow: auto" v-if="isDbgAdTable">
                      <template
                        v-for="(provider, index) in page
                          .getProviderTableProviderList()
                          .filter((p) => p.name != 'ALL')"
                        :key="index"
                      >
                        <div class="frame__body" v-if="isProviderSelected(provider)">
                          <div style="width: 100%">
                            <div style="width: 100%">
                              <div style="display: flex">
                                <div
                                  v-if="
                                    page.isProviderTableNetworking(
                                      provider.name,
                                      page.selectedMode == 1
                                        ? 'monthly'
                                        : page.selectedMode == 2
                                        ? 'weekly'
                                        : page.selectedMode == 3
                                        ? 'daily'
                                        : ''
                                    )
                                  "
                                >
                                  <div>
                                    {{ provider.name }}
                                  </div>
                                  <Spinner v-if="false" />
                                  <BoxSpinner style="height: 785px; width: 1600px" />
                                </div>
                                <div
                                  v-else
                                  style="margin-right: 20px; margin-bottom: 8px"
                                  v-for="category in page.getProviderTableCategoryList(
                                    provider.name,
                                    page.selectedMode == 1
                                      ? 'monthly'
                                      : page.selectedMode == 2
                                      ? 'weekly'
                                      : page.selectedMode == 3
                                      ? 'daily'
                                      : ''
                                  )"
                                  class="providerTable"
                                  :key="category"
                                >
                                  <div
                                    :style="{
                                      paddingBottom: '16px',
                                      backgroundColor: 'white',
                                      zIndex: '1',
                                    }"
                                    class="providerTableTitle"
                                  >
                                    <div
                                      v-on:click="
                                        copyToClipboard(
                                          `table_${provider.name}${category}${page.selectedMode}`
                                        )
                                      "
                                      style="display: table"
                                    >
                                      {{ provider.name }} {{ category }}
                                    </div>
                                  </div>
                                  <table
                                    class="floater_table floater_table--full"
                                    :id="provider.name"
                                    :class="[
                                      `table_${provider.name}${category}${page.selectedMode}`,
                                    ]"
                                    :style="{
                                      width: getProviderTableWidth(),
                                    }"
                                  >
                                    <thead class="floater_table__header" v-if="true">
                                      <tr v-if="isCells2SpanCells(page.getProviderTableHeader())">
                                        <template
                                          v-for="(item, index) in cells2SpanCells(
                                            page.getProviderTableHeader()
                                          )"
                                          :key="index"
                                        >
                                          <th
                                            style="background: white; border: initial"
                                            v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                          ></th>
                                          <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                            {{ item.getTitle() }}
                                          </th>
                                          <th
                                            v-if="item.spanCount() > 1"
                                            :colspan="item.spanCount()"
                                          >
                                            {{ item.getTitle() }}
                                          </th>
                                        </template>
                                      </tr>
                                      <tr>
                                        <template
                                          v-for="(cell, i1) in page.getProviderTableHeader()"
                                          :key="i1"
                                        >
                                          <th
                                            v-bind:class="[]"
                                            class="floater_table__summary_weekly"
                                          >
                                            {{
                                              spanTilte2HeaderTitle(cell.getString('consultant'))
                                            }}
                                          </th>
                                        </template>
                                      </tr>
                                    </thead>

                                    <tbody class="floater_table__body">
                                      <template
                                        :set="
                                          (providerTable = page.getProviderTable(
                                            provider.name,
                                            category,
                                            page.selectedMode == 1
                                              ? 'monthly'
                                              : page.selectedMode == 2
                                              ? 'weekly'
                                              : page.selectedMode == 3
                                              ? 'daily'
                                              : ''
                                          ))
                                        "
                                      />
                                      <template v-for="(row, i2) in providerTable" :key="i2">
                                        <tr>
                                          <template v-for="(cell, i3) in row" :key="i3">
                                            <td
                                              v-bind:class="[
                                                i3 == 0 ? 'floater_table__titlecell' : '',
                                              ]"
                                              class="floater_table__summary_weekly"
                                              style="padding: 0px; position: relative"
                                              :set="
                                                (cellDesign = page.getProviderTableCellDesign(
                                                  cell.data,
                                                  providerTable,
                                                  i3
                                                ))
                                              "
                                            >
                                              <div
                                                v-bind:style="{
                                                  position: 'absolute',
                                                  top: 0,
                                                  left: 0,
                                                  right: 0,
                                                  bottom: 0,
                                                  padding:
                                                    cellDesign.design == 'box' ? '0px' : '4px',
                                                  paddingLeft: '0px',
                                                }"
                                              >
                                                <div
                                                  style="height: 100%; width: "
                                                  v-bind:style="{
                                                    width: cellDesign.isColored
                                                      ? cellDesign.percent + '%'
                                                      : '0%',
                                                    background: cellDesign.isColored
                                                      ? cellDesign.color
                                                      : '#FFFFFF',
                                                    border:
                                                      cellDesign.border != null
                                                        ? cellDesign.border
                                                        : '',
                                                  }"
                                                ></div>
                                              </div>
                                              <div
                                                style="
                                                  padding: 8px;
                                                  position: relative;
                                                  top: 0;
                                                  left: 0;
                                                  right: 0;
                                                  bottom: 0;
                                                "
                                              >
                                                <div
                                                  v-if="
                                                    !cell
                                                      .getString('consultant')
                                                      .includes('(着地想定)')
                                                  "
                                                >
                                                  {{ cell.getString('consultant') }}
                                                </div>
                                                <div
                                                  v-if="
                                                    cell
                                                      .getString('consultant')
                                                      .includes('(着地想定)')
                                                  "
                                                >
                                                  {{
                                                    cell
                                                      .getString('consultant')
                                                      .replace('(着地想定)', '')
                                                  }}
                                                  <div
                                                    style="font-size: 80%; display: inline-block"
                                                  >
                                                    (着地想定)
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div>
                      <div class="frame__header">
                        <div :style="{ paddingLeft: '1px' }">
                          <div
                            :style="{
                              borderLeft: '3px solid #39434E',
                              paddingLeft: '8px',
                            }"
                            v-if="
                              page.dimensionSelector.getSelectedTags() == 0 ||
                              (page.dimensionSelector.getSelectedTags().length > 0 &&
                                page.dimensionHolder.getProviderCategoryTableByTags(
                                  page.dimensionSelector.getSelectedTags()
                                ).length == 0)
                            "
                          >
                            {{ page.dimensionSelector.getTitle() }}
                          </div>
                          <div
                            :style="{
                              borderLeft: '3px solid #39434E',
                              paddingLeft: '8px',
                            }"
                            v-if="
                              page.dimensionHolder.getProviderCategoryTableByTags(
                                page.dimensionSelector.getSelectedTags()
                              ).length > 0
                            "
                            v-on:click="copyToClipboard('provider_category_table')"
                          >
                            {{
                              page.dimensionHolder
                                .getProviderCategoryTableByTags(
                                  page.dimensionSelector.getSelectedTags()
                                )[0]
                                .title()
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="frame__body">
                        <div
                          class="dimension_selector"
                          v-if="!(page.isProviderCategoryTableNetworking || !isLoad)"
                        >
                          <template
                            v-for="(item, index) in page.dimensionSelector.getTags()"
                            :key="index"
                          >
                            <div
                              class="dimension_selector__button"
                              v-bind:class="
                                page.dimensionSelector.getClassDimensionNumberByTag(item)
                              "
                              v-on:click="page.dimensionSelector.setSelectByTag(item)"
                            >
                              {{ item }}
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>

                    <Spinner v-if="page.isProviderCategoryTableNetworking && false" />
                    <BoxSpinner
                      v-if="page.isProviderCategoryTableNetworking || !isLoad"
                      style="height: 30px; width: 200px; margin-bottom: 30px"
                    />
                    <div style="display: flex; max-width: 1600px" v-if="false">
                      <BoxSpinner
                        style="height: 400px; width: calc(10% - 5px); margin-right: 5px"
                      />
                      <BoxSpinner style="height: 400px; width: 90%" />
                    </div>
                    <div style="display: flex; max-width: 1600px" v-if="page.isDimensionNetwork">
                      <BoxSpinner style="height: 200px; width: 100%" />
                    </div>
                    <div style="width: 100%; height: 1px;" id="dm0position"></div>
                    <div v-if="!page.isDimensionNetwork">
                      <template
                      v-for="(
                        dimensionOrdered, index
                      ) in page.dimensionHolder.getProviderCategoryTableByTags(
                        page.dimensionSelector.getSelectedTags()
                      )"
                      :key="index"
                    >
                      <div v-if="dimensionOrdered.count() == 3">
                        <div class="frame__body scroll_bar">
                          <div
                            :style="
                              summarytalbewidth(
                                'd3',
                                dimensionOrdered.getHeaderCell(),
                                'dimension3'
                              )
                            "
                          >
                            <table
                              id="d3_table_tag"
                              class="
                                floater_table floater_table--full
                                provider_category_table
                                expansion_table
                              "
                            >
                              <thead id="dm_header_position" class="floater_table__header expansion_table__header" style="position: relative;">
                                <tr
                                  v-if="
                                    isCells2SpanCells(
                                      dimensionOrdered.getHeaderCell(),
                                      'dimension3'
                                    )
                                  "
                                >
                                  <template
                                    v-for="(item, ih) in cells2SpanCells(
                                      dimensionOrdered.getHeaderCell(),
                                      'dimension3'
                                    )"
                                    :key="ih"
                                  >
                                    <th
                                      style="background: white; border: initial"
                                      v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                    ></th>
                                    <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                      {{ item.getTitle() }}
                                    </th>
                                    <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                      {{ item.getTitle() }}
                                    </th>
                                  </template>
                                </tr>

                                <tr>
                                  <template
                                    v-for="(cell, index2) in dimensionOrdered.getHeaderCell()"
                                    :key="index2"
                                  >
                                    <th
                                      ref="d3_header_ref"
                                      :id="'d3_header_id_' + index2"
                                      style="position: relative; cursor: pointer"
                                      v-on:click.stop="showShowHide($event, index2, 'dimension3')"
                                      class="floater_table__summary_weekly expansion_table__th"
                                      v-if="isShowSummaryColum(index2, 'dimension3')"
                                      :style="[teststyle('d3', index2)]"
                                    >
                                      <div
                                        class="expansion_table__dragmarker"
                                        :class="[
                                          expMarkerId == index2 && expType == 'd3'
                                            ? 'expansion_table__dragmarker--active'
                                            : '',
                                        ]"
                                        v-on:click.stop=""
                                        v-on:mousedown.stop="dragstart('d3', index2, $event)"
                                      >
                                        <div class="expansion_table__dragmarker__arrow">
                                          <div class="expansion_table__dragmarker__lrarrow">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="18"
                                              viewBox="0 0 16 18"
                                              transform="rotate(90)"
                                            >
                                              <g
                                                id="g484"
                                                data-name="g484"
                                                transform="translate(-8 -123)"
                                              >
                                                <path
                                                  id="p530"
                                                  data-name="p530"
                                                  d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                  transform="translate(4 120)"
                                                  fill="#fff"
                                                />
                                                <rect
                                                  id="s_949"
                                                  data-name="s949"
                                                  width="16"
                                                  height="2"
                                                  transform="translate(8 131)"
                                                  fill="#fff"
                                                />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div style="padding-top: 4px; padding-bottom: 4px; box-sizing: border-box;">{{ spanTilte2HeaderTitle(cell.getString('consultant')) }}</div>
                                      <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 0) || (dmCt1label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt1label }}</div></div>
                                      <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 1) || (dmCt2label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt2label }}</div></div>
                                      <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 2) || (dmCt3label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt3label }}</div></div>
                                    </th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody class="floater_table__body">
                                <template
                                  v-for="(pdimension, pindex) in dimensionOrdered.dimensions"
                                  :key="pindex"
                                >
                                  <template
                                    v-for="(dimension, index2) in dcgtmpsplice(
                                      pdimension.dimensionCategoryGroups
                                    )"
                                    :key="index2"
                                  >
                                    <template
                                      v-for="(group, gindex) in dctmpsplice(
                                        dimension.dimensionCategories
                                      )"
                                      :key="gindex"
                                    >
                                      <template
                                        v-for="(
                                          cells, index3
                                        ) in page.dimensionHolder.generateProviderCategoryRows(
                                          group.cellRows
                                        )"
                                        :key="index3"
                                      >
                                        <tr
                                          v-if="cells.length > 0 && !isreoffset(cells)"
                                          v-bind:class="[
                                            group.cellRows.length == 1 ? 'tr_one_line' : '',
                                            index3 == 0 ? 'consultant_record__trmark' : '',
                                          ]"
                                        >
                                          <td
                                            :class="[true ? 'dm1position' : '']"
                                            style="background: white; padding: 15px"
                                            class="
                                              floater_table__summary_weekly
                                              floater_table__keyword__1categorytitle
                                            "
                                            v-if="
                                              gindex == 0 &&
                                              index3 == 0 &&
                                              index2 == 0
                                            "
                                            :rowSpan="pdcount(pdimension.dimensionCategoryGroups)"
                                          >
                                            <div style="position: absolute; top: 15px">
                                              <div style="text-align: left">
                                                {{ pdimension.group }}
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            :class="[true ? 'dm2position' : '']"
                                            style="background: white; padding: 15px"
                                            class="
                                              floater_table__summary_weekly
                                              floater_table__keyword__1categorytitle
                                            "
                                            v-if="
                                              gindex == 0 &&
                                              index3 == 0
                                            "
                                            :rowSpan="dcount(dimension.dimensionCategories)"
                                          >
                                            <div style="position: absolute; top: 15px">
                                              <div style="text-align: left">
                                                {{ dimension.group }}
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            :class="[true ? 'dm3position' : '']"
                                            style="background: white; padding: 15px"
                                            class="
                                              floater_table__summary_weekly
                                              floater_table__keyword__1categorytitle
                                            "
                                            :rowSpan="cells.pairent.length - (isreoffsetinclude(group.cellRows) ? 1 : 0)"
                                            v-if="
                                              index3 == 0
                                            "
                                          >
                                            <div style="position: absolute; top: 15px">
                                              <div style="text-align: left">
                                                {{ group.categoryCell.getString('consultant') }}
                                              </div>
                                            </div>
                                          </td>
                                          <template v-for="(cell, index4) in cells" :key="index4">
                                            <td
                                              v-if="isShowSummaryColum(index4 + 3, 'dimension3')"
                                              v-bind:class="[
                                                index3 == 0 ? 'consultant_record__trmark_' : '',
                                                index3 == 3 ? 'consultant_record__trmark2' : '',
                                              ]"
                                              class="floater_table__summary_weekly"
                                              style="padding: 0px; position: relative"
                                              :set="
                                                (cellDesign =
                                                  page.dimensionHolder.getProviderCategoryReportCellDesign(
                                                    cell.data,
                                                    pdimension.dimensionCategoryGroups,
                                                    group.cellRows,
                                                    index3,
                                                    index4
                                                  ))
                                              "
                                            >
                                              <div
                                                v-bind:style="{
                                                  position: 'absolute',
                                                  top: 0,
                                                  left: 0,
                                                  right: 0,
                                                  bottom: 0,
                                                  padding:
                                                    index3 == 2 ? '4px' : index3 == 3 ? '0px' : '',
                                                  paddingLeft: '0px',
                                                }"
                                              >
                                                <div
                                                  v-if="index3 < 3"
                                                  style="height: 100%; width: "
                                                  v-bind:style="{
                                                    width: cellDesign.isColored
                                                      ? cellDesign.percent + '%'
                                                      : '0%',
                                                    background: cellDesign.isColored
                                                      ? cellDesign.color
                                                      : '#FFFFFF',
                                                    border:
                                                      cellDesign.border != null
                                                        ? cellDesign.border
                                                        : '',
                                                  }"
                                                ></div>
                                              </div>
                                              <div
                                                style="
                                                  padding: 8px;
                                                  position: relative;
                                                  top: 0;
                                                  left: 0;
                                                  right: 0;
                                                  bottom: 0;
                                                "
                                                :class="[
                                                  index4 == 0 ? 'floater_table__titlecell' : '',
                                                ]"
                                              >
                                                <div
                                                  :class="[index4 == 0 ? 'font_smaller' : '']"
                                                  v-if="index3 != 3"
                                                >
                                                  {{ titlewrapper(cell.getString('consultant')) }}
                                                </div>
                                                <div
                                                  :class="[index4 == 0 ? 'font_smaller' : '']"
                                                  v-if="index3 == 3"
                                                  style="margin-left: auto; position: relative"
                                                  :style="[
                                                    index4 != 0 ? 'width: fit-content;' : '',
                                                    cellDesign.cellclass != null
                                                      ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px;'
                                                      : '',
                                                  ]"
                                                >
                                                  <svg
                                                    v-if="cellDesign.cellclass != null"
                                                    v-bind:class="cellDesign.cellclass"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 11.268 13.002"
                                                  >
                                                    <path
                                                      id="合体_22"
                                                      data-name="合体 22"
                                                      d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                      transform="translate(-10732.001 -12773.001)"
                                                    />
                                                  </svg>

                                                  {{ titlewrapper(cell.getString('consultant')) }}
                                                </div>
                                              </div>
                                            </td>
                                          </template>
                                        </tr>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div v-if="dimensionOrdered.count() == 2">
                        <div class="frame__body scroll_bar">
                          <div
                            :style="
                              summarytalbewidth(
                                'd2',
                                dimensionOrdered.getHeaderCell(),
                                'dimension2'
                              )
                            "
                          >
                            <table
                              id="d2_table_tag"
                              class="
                                floater_table floater_table--full
                                provider_category_table
                                expansion_table
                              "
                            >
                              <thead id="dm_header_position" class="floater_table__header expansion_table__header" style="position: relative;">
                                <tr
                                  v-if="
                                    isCells2SpanCells(
                                      dimensionOrdered.getHeaderCell(),
                                      'dimension2'
                                    )
                                  "
                                >
                                  <template
                                    v-for="(item, ih) in cells2SpanCells(
                                      dimensionOrdered.getHeaderCell(),
                                      'dimension2'
                                    )"
                                    :key="ih"
                                  >
                                    <th
                                      style="background: white; border: initial"
                                      v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                    ></th>
                                    <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                      {{ item.getTitle() }}
                                    </th>
                                    <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                      {{ item.getTitle() }}
                                    </th>
                                  </template>
                                </tr>

                                <tr>
                                  <template
                                    v-for="(cell, index2) in dimensionOrdered.getHeaderCell()"
                                    :key="index2"
                                  >
                                    <th
                                      ref="d2_header_ref"
                                      :id="'d2_header_id_' + index2"
                                      style="position: relative; cursor: pointer"
                                      v-on:click.stop="showShowHide($event, index2, 'dimension2')"
                                      class="floater_table__summary_weekly expansion_table__th"
                                      v-if="isShowSummaryColum(index2, 'dimension2')"
                                      :style="[teststyle('d2', index2)]"
                                    >
                                      <div
                                        class="expansion_table__dragmarker"
                                        :class="[
                                          expMarkerId == index2 && expType == 'd2'
                                            ? 'expansion_table__dragmarker--active'
                                            : '',
                                        ]"
                                        v-on:click.stop=""
                                        v-on:mousedown.stop="dragstart('d2', index2, $event)"
                                      >
                                        <div class="expansion_table__dragmarker__arrow">
                                          <div class="expansion_table__dragmarker__lrarrow">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="18"
                                              viewBox="0 0 16 18"
                                              transform="rotate(90)"
                                            >
                                              <g
                                                id="g484"
                                                data-name="g484"
                                                transform="translate(-8 -123)"
                                              >
                                                <path
                                                  id="p530"
                                                  data-name="p530"
                                                  d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                  transform="translate(4 120)"
                                                  fill="#fff"
                                                />
                                                <rect
                                                  id="s_949"
                                                  data-name="s949"
                                                  width="16"
                                                  height="2"
                                                  transform="translate(8 131)"
                                                  fill="#fff"
                                                />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div style="padding-top: 4px; padding-bottom: 4px; box-sizing: border-box;">{{ spanTilte2HeaderTitle(cell.getString('consultant')) }}</div>
                                      <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 0) || (dmCt1label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt1label }}</div></div>
                                      <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 1) || (dmCt2label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt2label }}</div></div>
                                    </th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody class="floater_table__body">
                                <template
                                  v-for="(dimension, index2) in dimensionOrdered.dimensions"
                                  :key="index2"
                                >
                                  <template
                                    v-for="(group, gindex) in dctmpsplice(
                                      dimension.dimensionCategories
                                    )"
                                    :key="gindex"
                                  >
                                    <template
                                      v-for="(
                                        cells, index3
                                      ) in page.dimensionHolder.generateProviderCategoryRows(
                                        group.cellRows
                                      )"
                                      :key="index3"
                                    >
                                      <tr
                                        v-if="cells.length > 0 && !isreoffset(cells)"
                                        v-bind:class="[
                                          group.cellRows.length == 1 ? 'tr_one_line' : '',
                                          index3 == 0 ? 'consultant_record__trmark' : '',
                                        ]"
                                      >
                                        <td
                                          :class="[true ? 'dm1position' : '']"
                                          style="background: white; padding: 15px"
                                          class="
                                            floater_table__summary_weekly
                                            floater_table__keyword__1categorytitle
                                          "
                                          v-if="
                                            gindex == 0 &&
                                            index3 == 0 &&
                                            isShowSummaryColum(0, 'dimension2')
                                          "
                                          :rowSpan="dcount(dimension.dimensionCategories)"
                                        >
                                          <div style="position: absolute; top: 15px">
                                            <div style="text-align: left">
                                              {{ dimension.group }}
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          :class="[true ? 'dm2position' : '']"
                                          style="background: white; padding: 15px"
                                          class="
                                            floater_table__summary_weekly
                                            floater_table__keyword__1categorytitle
                                          "
                                          :rowSpan="cells.pairent.length - (isreoffsetinclude(group.cellRows) ? 1 : 0)"
                                          v-if="index3 == 0 && isShowSummaryColum(1, 'dimension2')"
                                        >
                                          <div style="position: absolute; top: 15px">
                                            <div style="text-align: left">
                                              {{ group.categoryCell.getString('consultant') }}
                                            </div>
                                          </div>
                                        </td>
                                        <template v-for="(cell, index4) in cells" :key="index4">
                                          <td
                                            v-if="isShowSummaryColum(index4 + 2, 'dimension2')"
                                            v-bind:class="[
                                              index3 == 0 ? 'consultant_record__trmark_' : '',
                                              index3 == 3 ? 'consultant_record__trmark2' : '',
                                            ]"
                                            class="floater_table__summary_weekly"
                                            style="padding: 0px; position: relative"
                                            :set="
                                              (cellDesign =
                                                page.dimensionHolder.getProviderCategoryReportCellDesign(
                                                  cell.data,
                                                  dimensionOrdered.dimensions,
                                                  group.cellRows,
                                                  index3,
                                                  index4
                                                ))
                                            "
                                          >
                                            <div
                                              v-bind:style="{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                padding:
                                                  index3 == 2 ? '4px' : index3 == 3 ? '0px' : '',
                                                paddingLeft: '0px',
                                              }"
                                            >
                                              <div
                                                v-if="index3 < 3"
                                                style="height: 100%; width: "
                                                v-bind:style="{
                                                  width: cellDesign.isColored
                                                    ? cellDesign.percent + '%'
                                                    : '0%',
                                                  background: cellDesign.isColored
                                                    ? cellDesign.color
                                                    : '#FFFFFF',
                                                  border:
                                                    cellDesign.border != null
                                                      ? cellDesign.border
                                                      : '',
                                                }"
                                              ></div>
                                            </div>
                                            <div
                                              style="
                                                padding: 8px;
                                                position: relative;
                                                top: 0;
                                                left: 0;
                                                right: 0;
                                                bottom: 0;
                                              "
                                              :class="[
                                                index4 == 0 ? 'floater_table__titlecell' : '',
                                              ]"
                                            >
                                              <div
                                                :class="[index4 == 0 ? 'font_smaller' : '']"
                                                v-if="index3 != 3"
                                              >
                                                {{ cell.getString('consultant') }}
                                              </div>
                                              <div
                                                :class="[index4 == 0 ? 'font_smaller' : '']"
                                                v-if="index3 == 3"
                                                style="margin-left: auto; position: relative"
                                                :style="[
                                                  index4 != 0 ? 'width: fit-content;' : '',
                                                  cellDesign.cellclass != null
                                                    ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px;'
                                                    : '',
                                                ]"
                                              >
                                                <svg
                                                  v-if="cellDesign.cellclass != null"
                                                  v-bind:class="cellDesign.cellclass"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 11.268 13.002"
                                                >
                                                  <path
                                                    id="合体_22"
                                                    data-name="合体 22"
                                                    d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                    transform="translate(-10732.001 -12773.001)"
                                                  />
                                                </svg>

                                                {{ titlewrapper(cell.getString('consultant')) }}
                                              </div>
                                            </div>
                                          </td>
                                        </template>
                                      </tr>
                                    </template>
                                  </template>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div v-if="dimensionOrdered.count() == 1">
                        <div class="frame__body scroll_bar">
                          <div
                            :style="
                              summarytalbewidth(
                                'd2',
                                dummyDimension1(dimensionOrdered.getHeaderCell()),
                                'dimension2'
                              )
                            "
                          >
                            <table
                              id="d2_table_tag"
                              class="
                                floater_table floater_table--full
                                provider_category_table
                                expansion_table
                              "
                            >
                              <thead id="dm_header_position" class="floater_table__header expansion_table__header" style="position: relative;">
                                <tr
                                  v-if="
                                    isCells2SpanCells(
                                      dummyDimension1(dimensionOrdered.getHeaderCell()),
                                      'dimension2'
                                    )
                                  "
                                >
                                  <template
                                    v-for="(item, ih) in cells2SpanCells(
                                      dummyDimension1(dimensionOrdered.getHeaderCell()),
                                      'dimension2'
                                    )"
                                    :key="ih"
                                  >
                                    <th
                                      style="background: white; border: initial"
                                      v-if="item.spanCount() == 1 && !is2SpanCell(item) && ih != 0"
                                    ></th>
                                    <th
                                      v-if="item.spanCount() == 1 && is2SpanCell(item) && ih != 0"
                                    >
                                      {{ item.getTitle() }}
                                    </th>
                                    <th
                                      v-if="item.spanCount() > 1 && ih != 0"
                                      :colspan="item.spanCount()"
                                    >
                                      {{ item.getTitle() }}
                                    </th>
                                  </template>
                                </tr>

                                <tr>
                                  <template
                                    v-for="(cell, index2) in dummyDimension1(
                                      dimensionOrdered.getHeaderCell()
                                    )"
                                    :key="index2"
                                  >
                                    <th
                                      ref="d2_header_ref"
                                      :id="'d2_header_id_' + index2"
                                      style="position: relative; cursor: pointer"
                                      v-if="isShowSummaryColum(index2, 'dimension2') && index2 != 0"
                                      v-on:click.stop="showShowHide($event, index2, 'dimension2')"
                                      class="floater_table__summary_weekly expansion_table__th"
                                      :style="[teststyle('d2', index2)]"
                                    >
                                      <div
                                        class="expansion_table__dragmarker"
                                        :class="[
                                          expMarkerId == index2 && expType == 'd2'
                                            ? 'expansion_table__dragmarker--active'
                                            : '',
                                        ]"
                                        v-on:click.stop=""
                                        v-on:mousedown.stop="dragstart('d2', index2, $event)"
                                      >
                                        <div class="expansion_table__dragmarker__arrow">
                                          <div class="expansion_table__dragmarker__lrarrow">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="18"
                                              viewBox="0 0 16 18"
                                              transform="rotate(90)"
                                            >
                                              <g
                                                id="g484"
                                                data-name="g484"
                                                transform="translate(-8 -123)"
                                              >
                                                <path
                                                  id="p530"
                                                  data-name="p530"
                                                  d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                  transform="translate(4 120)"
                                                  fill="#fff"
                                                />
                                                <rect
                                                  id="s_949"
                                                  data-name="s949"
                                                  width="16"
                                                  height="2"
                                                  transform="translate(8 131)"
                                                  fill="#fff"
                                                />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div style="padding-top: 4px; padding-bottom: 4px; box-sizing: border-box;">{{ spanTilte2HeaderTitle(cell.getString('consultant')) }}</div>
                                      <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 1) || (dmCt1label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt1label }}</div></div>
                                    </th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody class="floater_table__body">
                                <template
                                  v-for="(dimension, index2) in dimensionOrdered.dimensions"
                                  :key="index2"
                                >
                                  <template
                                    v-for="(
                                      cells, index3
                                    ) in page.dimensionHolder.generateProviderCategoryRows(
                                      dimension.cellRows
                                    )"
                                    :key="index3"
                                  >
                                    <tr
                                      v-if="cells.length > 0 && !isreoffset(cells)"
                                      v-bind:class="[
                                        dimension.cellRows.length == 1 ? 'tr_one_line' : '',
                                        index3 == 0 ? 'consultant_record__trmark' : '',
                                      ]"
                                    >
                                      <td
                                        :class="[true ? 'dm1position' : '']"
                                        style="background: white; padding: 15px"
                                        class="
                                          floater_table__summary_weekly
                                          floater_table__keyword__1categorytitle
                                        "
                                        :rowSpan="cells.pairent.length - (isreoffsetinclude(dimension.cellRows) ? 1 : 0)"
                                        v-if="index3 == 0 && isShowSummaryColum(0, 'dimension2')"
                                      >
                                        <div style="position: absolute; top: 15px">
                                          <div style="text-align: left">
                                            {{ dimension.categoryCell.getString('consultant') }}
                                          </div>
                                        </div>
                                      </td>
                                      <template v-for="(cell, index4) in cells" :key="index4">
                                        <td
                                          v-if="isShowSummaryColum(index4 + 2, 'dimension2')"
                                          v-bind:class="[
                                            index3 == 0 ? 'consultant_record__trmark_' : '',
                                            index3 == 3 ? 'consultant_record__trmark2' : '',
                                          ]"
                                          class="floater_table__summary_weekly"
                                          style="padding: 0px; position: relative"
                                          :set="
                                            (cellDesign =
                                              page.dimensionHolder.getProviderReportCellDesign(
                                                cell.data,
                                                dimensionOrdered.dimensions,
                                                dimension.cellRows,
                                                index3,
                                                index4
                                              ))
                                          "
                                        >
                                          <div
                                            v-bind:style="{
                                              position: 'absolute',
                                              top: 0,
                                              left: 0,
                                              right: 0,
                                              bottom: 0,
                                              padding:
                                                index3 == 2 ? '4px' : index3 == 3 ? '0px' : '',
                                              paddingLeft: '0px',
                                            }"
                                          >
                                            <div
                                              v-if="index3 < 3"
                                              style="height: 100%; width: "
                                              v-bind:style="{
                                                width: cellDesign.isColored
                                                  ? cellDesign.percent + '%'
                                                  : '0%',
                                                background: cellDesign.isColored
                                                  ? cellDesign.color
                                                  : '#FFFFFF',
                                                border:
                                                  cellDesign.border != null
                                                    ? cellDesign.border
                                                    : '',
                                              }"
                                            ></div>
                                          </div>
                                          <div
                                            style="
                                              padding: 8px;
                                              position: relative;
                                              top: 0;
                                              left: 0;
                                              right: 0;
                                              bottom: 0;
                                            "
                                            :class="[index4 == 0 ? 'floater_table__titlecell' : '']"
                                          >
                                            <div
                                              :class="[index4 == 0 ? 'font_smaller' : '']"
                                              v-if="index3 != 3"
                                            >
                                              {{ cell.getString('consultant') }}
                                            </div>
                                            <div
                                              :class="[index4 == 0 ? 'font_smaller' : '']"
                                              v-if="index3 == 3"
                                              style="margin-left: auto; position: relative"
                                              :style="[
                                                index4 != 0 ? 'width: fit-content;' : '',
                                                cellDesign.cellclass != null
                                                  ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px;'
                                                  : '',
                                              ]"
                                            >
                                              <svg
                                                v-if="cellDesign.cellclass != null"
                                                v-bind:class="cellDesign.cellclass"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11.268 13.002"
                                              >
                                                <path
                                                  id="合体_22"
                                                  data-name="合体 22"
                                                  d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                  transform="translate(-10732.001 -12773.001)"
                                                />
                                              </svg>

                                              {{ titlewrapper(cell.getString('consultant')) }}
                                            </div>
                                          </div>
                                        </td>
                                      </template>
                                    </tr>
                                  </template>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub_content_layer">
          <div class="sub_content_wrapper">
            <div id="lay_right_sidemenu" class="right_sidemenu_layer">
              <SideMenu :adx="adx" selectId="summary" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ColumnShowHide :proxy="csh" />
  </div>
</template>

<style scoped>
.draglock {
  user-select: none;
}

.expansion_table {
  table-layout: auto;
  width: initial;
  max-width: initial;
}

.expansion_table__th {
  width: initial;
  min-width: 104px;
}

.expansion_table__th:hover {
  user-select: none;
}

.expansion_table__td {
  width: initial;
  min-width: initial;
}

.expansion_table__dragmarker {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 8px;
  height: 29px;
}
.expansion_table__dragmarker--active {
  background: #2680ebdd;
}

.expansion_table__dragmarker:hover {
  background: #2680ebdd;
}

.frame--expansion {
  width: initial;
}

.frame__header--expansion_lock {
  width: 1544px;
}

.scroll_bar {
  overflow: auto;
}

.scroll_bar::-webkit-scrollbar {
  border-radius: 6px;
  width: 8px;
  height: 12px;
}
.scroll_bar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: rgba(241, 241, 241, 1);
}
.scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(193, 193, 193, 1);
}

.expansion_table__dragmarker__arrow {
  display: none;
  position: relative;
  pointer-events: none;
}
.expansion_table__dragmarker__larrow {
  position: absolute;
  top: 6px;
  left: -14px;
}
.expansion_table__dragmarker__rarrow {
  position: absolute;
  top: 6px;
  left: -2px;
}
.expansion_table__dragmarker__lrarrow {
  position: absolute;
  top: 6px;
  left: -4px;
}

.expansion_table__dragmarker:hover .expansion_table__dragmarker__arrow {
  display: block;
}
.expansion_table__dragmarker--active .expansion_table__dragmarker__arrow {
  display: block;
}
</style>

<style scoped>
.selectbtn {
  font-size: 12px;
  border-bottom: solid 2px #cccccc;
  padding: 0px 32px 16px;
  color: #cccccc;
  user-select: none;
}

.selectbtn--active {
  color: #2680eb;
  border-bottom: solid 2px #2680eb;
}
</style>

<style scoped>
.consultant_record__trmark {
  background: #e3f2fd;
}

.consultant_record__trmark:hover {
  background-color: rgba(212, 212, 212, 0.3);
}
.consultant_record__trmark2 {
  background: #eeeeee;
}

.consultant_record__trmark2:hover {
  background-color: rgba(212, 212, 212, 0.3);
}
</style>

<style scoped>
.dimension_selector__button {
  cursor: pointer;
}
</style>

<style scoped>
.tr_one_line {
  height: 80px;
}

.alert_color {
  color: #ff6565;
}

.success_color {
  color: #3b86ff;
}
</style>

<style>
.floater_table__body td .itemwithtitle {
  border: solid 0px;
}

.floater_table__keyword {
  padding: 10px;
  text-align: right;
  font: normal normal 300 10px/15px Noto Sans JP;
  width: 80px;
}

.floater_table__keyword__categorytitle {
  width: 220px;
  position: relative;
  text-align: left;
  padding: 15px;
}

.floater_table__body td .itemwithtitle-sm {
  padding-right: 5px;
}

.floater_table__keyword__2categorytitle {
  position: relative;
  text-align: left;
  width: 120px;
}

.floater_table__keyword__1categorytitle {
  position: relative;
  width: 140px;
}

.small_circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: rgb(59, 134, 255);
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -o-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
}

.small_circle--danger {
  background: #ff6565;
}

.low_campaign_card__info {
  font: normal normal 300 15px/21px Noto Sans JP;
}

.low_campaign_card__info_main {
  font-size: 25px;
}

.low_campaign_card__info_main_title {
  font-weight: bold;
}
.low_campaign_card__info_main_value {
  font: bold;
  color: #ff6565;
}
</style>

<style scoped>
.low_campaign_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000027;
  padding: 15px;
}

.low_campaign_card--infobox {
  position: relative;
  margin-right: 20px;
}

.low_campaign_card--right {
  margin-left: 20px;
}

.low_campaign_card__title {
  font: normal normal 300 12px Noto Sans JP;
}

.low_campaign_card__name {
  margin-top: 5px;
  font: normal normal 300 15px Noto Sans JP;
}

.low_campaign_card__info {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.low_campaign_card__info_sub {
  display: flex;
  color: #ff6565;
  margin-top: 10px;
}

.floater_table__summary_weekly--alert {
  color: #ff6565;
}
</style>

<style scoped>
.gbarrow3step--1 {
  position: absolute;
  top: -1px;
  left: -16px;
  width: 14px;
  height: 15px;

  fill: #088dff;
}
.gbarrow3step--2 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 11px;
  height: 12px;

  fill: #088dff;
}
.gbarrow3step--3 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;

  fill: #088dff;
}
.gbarrow3step--4 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--5 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 10px;
  height: 13px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--6 {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 14px;
  height: 15px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--11 {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 14px;
  height: 15px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--12 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 10px;
  height: 13px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--13 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--14 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;

  fill: #ff1744;
}
.gbarrow3step--15 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 11px;
  height: 12px;

  fill: #ff1744;
}
.gbarrow3step--16 {
  position: absolute;
  top: -1px;
  left: -16px;
  width: 14px;
  height: 15px;

  fill: #ff1744;
}

.sticky_title {
  background: white;
}
.sticky_title__span {
  top: 130px;
}

.selectdimension1 {
  background: #2680eb;
  color: #fff;
  border: 1px solid #e3e3e3;
}
.selectdimension2 {
  background: #5a9ff0;
  color: #fff;
}
.selectdimension3 {
  background: #088dff55;
}

.selectdimension0 {
  border: 1px solid #e3e3e3;
}

.dimension_selector {
  display: flex;
}

.dimension_selector__button {
  min-width: 80px;
  margin-left: 8px;
  border: 0.5px solid #e3e3e3;
  border-radius: 3px;
  font-size: 13px;
  padding: 5px;
  text-align: center;
  user-select: none;
}
</style>

<script>
// @ is an alias to /src
import SideMenu from '@/components/consultant/SideMenu.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';
import BoxSpinner from '@/components/layout/BoxSpinner.vue';
import ScreenBarProgress2 from '@/components/ScreenBarProgress2.vue';

import RangeCalendar from '@/components/RangeCalendar.vue';

import { SummaryPage } from '@/js/page/consultant/SummaryPage.js';

import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';
// import { ratescale3Step } from '@/js/setting.js';

import {
  cells2SpanCells,
  spanTilte2HeaderTitle,
  titles2Cell,
  is2SpanCell,
} from '@/js/components/spancell.js';

import ColumnShowHide from '@/components/ColumnShowHide.vue';
import { ColumnShowHideProxy, ColumnInfo } from '@/js/components/ColumnShowHideProxy.js';
import { Cell } from '@/js/page/data.js';

export default {
  name: 'Summary',
  data() {
    return {
      name: 'サマリ',
      page: new SummaryPage(),
      isLoad: false,
      dateRangeSelector: null,
      provider: '',
      isShowRange: false,
      isShowDailyAlert: false,
      isShowDailyAlertHover: false,
      isShowWeeklyAlert: false,
      isShowWeeklyAlertHover: false,
      isActive: true,
      isDbgAdTable: false,
      csh: new ColumnShowHideProxy(),
      isDragmode: false,
      dragstate: 0,
      baseClientX: 0,
      baseWidth: 100,
      exptarget: null,
      expMarkerId: -1,
      expType: '',
      expTemp: 3000,
      dmCt1label: '',
      dmCt2label: '',
      dmCt3label: '',
    };
  },
  emits: ['screenevent'],
  mounted() {
    let self = this;
    this.page.process.start();
    this.$emit('screenevent', 'keyword');
    let p = this.adx.getScreenStructureById('consultant').getPageByKey('サマリー');
    this.isActive = true;
    // this.page.providerLoadFinish = function () {
    //   self.adx.excelreport.ready();
    // };
    this.adx.excelreport.setCallback(function () {
      // self.page.xlsx();
      self.excelreportdownload();
    });
    if (p) {
      self.adx.excelreport.ready();
      this.dateRangeSelector = this.adx.getScreenStructureById('consultant').dateRangeSelector;
      this.dateRangeSelector.timeYmdRange.subject.removeAll();
      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        if (!self.isActive) {
          return;
        }
        self.isLoad = false;
        self.initialize(
          p.id,
          p.pageComponents,
          self.adx.getScreenStructureById('consultant').advertizerId,
          self.dateRangeSelector
        );
      });
      this.initialize(
        p.id,
        p.pageComponents,
        this.adx.getScreenStructureById('consultant').advertizerId,
        self.dateRangeSelector
      );
    } else {
      this.adx.getScreenStructureById('consultant').subject.addObserver(function () {
        if (!self.isActive) {
          return;
        }
        let p = self.adx.getScreenStructureById('consultant').getPageByKey('サマリー');
        if (p) {
          self.adx.excelreport.ready();
          if (!self.isLoad && p.pageComponents.length > 0) {
            self.dateRangeSelector =
              self.adx.getScreenStructureById('consultant').dateRangeSelector;
            self.dateRangeSelector.timeYmdRange.subject.removeAll();
            self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
              if (!self.isActive) {
                return;
              }

              self.isLoad = false;
              self.initialize(
                p.id,
                p.pageComponents,
                self.adx.getScreenStructureById('consultant').advertizerId,
                self.dateRangeSelector
              );
            });
            self.initialize(
              p.id,
              p.pageComponents,
              self.adx.getScreenStructureById('consultant').advertizerId,
              self.dateRangeSelector
            );
          }
        }
      });
    }

    window.onscroll = function () {
      let providerTables = document.getElementsByClassName('providerTable');
      Array.from(providerTables).forEach((providerTable) => {
        let header = providerTable.getElementsByClassName('floater_table__header')[0];
        let title = providerTable.getElementsByClassName('providerTableTitle')[0];
        if (!header || !title) return;
        if (
          providerTable.offsetTop < document.documentElement.scrollTop + 100 &&
          providerTable.offsetTop + providerTable.offsetHeight >
            document.documentElement.scrollTop + 100
        ) {
          header.style.position = 'relative';
          header.style.top =
            document.documentElement.scrollTop - providerTable.offsetTop + 100 + 'px';
          title.style.position = 'relative';
          title.style.top =
            document.documentElement.scrollTop - providerTable.offsetTop + 100 + 'px';
        } else {
          header.style.position = '';
          header.style.top = '';
          title.style.position = '';
          title.style.top = '';
        }
      });
    };
    window.onresize = function () {
      let monthlyTableWidth = document.getElementById('providerWidthTable')?.clientWidth;
      Array.from(document.getElementsByClassName('providerTable')).forEach((el) => {
        let table = el.getElementsByClassName('floater_table')[0];
        if (table) table.style.width = monthlyTableWidth + 'px';
      });
    };

    document.addEventListener('click', this.onClickEvent);
    document.addEventListener('mousemove', this.onMouseMoveEvent);
    document.addEventListener('mouseup', this.onMouseUpEvent);

    this.page.isDimensionNetworkEvent = function(isNetwork) {
      self.page.isDimensionNetwork = isNetwork;
    }

    document.addEventListener("scroll", this.scroller);
  },
  methods: {
    scroller() {
      const br = document.getElementById("dm0position").getBoundingClientRect();
      let dmCt1label = "";
      let dmCt2label = "";
      if (br) {        
        let h1p = document.getElementById("dm_header_position");
        if (h1p) {
          if (br.top - 90 < 0) {
            h1p.style.top = (((br.top - 90) * -1) + "px");
          }else{
            h1p.style.top = "0px";
          }
        }

        let dm1positions = document.getElementsByClassName("dm1position");
        if (dm1positions) {
          for (let i = dm1positions.length - 1 ; i >= 0 ; i--) {
            if (dm1positions[i].getBoundingClientRect().top < 160) {
              dmCt1label = dm1positions[i].innerText;
              break;
            }
          }
        }

        let dm2positions = document.getElementsByClassName("dm2position");
        if (dm2positions) {
          for (let i = dm2positions.length - 1 ; i >= 0 ; i--) {
            if (dm2positions[i].getBoundingClientRect().top < 160) {
              dmCt2label = dm2positions[i].innerText;
              break;
            }
          }
        }

      }
      this.dmCt1label = dmCt1label;
      this.dmCt2label = dmCt2label;
    },
    isreoffset(cells) {
      if (!this.page.isCurrentMonth()) {
        if (cells && cells.length > 0) {
          if (cells[0]?.data.indexOf("着地想定") >= 0) {
            return true;
          }
        }
      }
      return false;
    },
    isreoffsetinclude(records) {
      if (!this.page.isCurrentMonth()) {
        for (let i in records) {
          if (records[i].length > 0) {
            if (records[i][0]?.data.indexOf("着地想定") >= 0) {
              return true;
            }
          }
        }
      }

      return false;
    },
    reoffset() {
      return 0;
    },
    title01() {
      if (this.page.isCurrentMonth()) {
        return "全体予実";
      }
      return "全体実績";
    },
    titlewrapper(title) {
      if (!this.page.isCurrentMonth()) {
        if (title.indexOf("想定乖離率") >= 0) {
          return "乖離率";
        }
        if (title.indexOf("当月実績") >= 0) {
          return "実績";
        }
      }

      return title;
    },
    mwd_title_change(title) {
      if (title == "種別" || title == "週") {
        if (this.page.selectedMode == 1) {
          return "月別";
        }
        if (this.page.selectedMode == 2) {
          return "週別";
        }
        if (this.page.selectedMode == 3) {
          return "日別";
        }
      }
      return title;
    },
    dcgtmpsplice(dimensionCategoryGroups) {
      // let dcgs = [];
      // for (let i in dimensionCategoryGroups) {
      //   let dcs = this.dctmpsplice(dimensionCategoryGroups[i].dimensionCategories);
      //   if (dcs.length > 0) {
      //     dcgs.push(dimensionCategoryGroups[i]);
      //   }
      // }
      // return dcgs;
      return dimensionCategoryGroups;
    },
    dctmpsplice(dimensionCategories) {
      // let dcs = [];
      // for (let i in dimensionCategories) {
      //   try {
      //     let isActive = false;
      //     for (let x in dimensionCategories[i].cellRows) {
      //       let cls = dimensionCategories[i].cellRows[x];
      //       if (cls.length > 0) {
      //         if (cls[0].getString().indexOf('実績') >= 0) {
      //           isActive = true;
      //           break;
      //         }
      //       }
      //     }
      //     if (isActive) {
      //       dcs.push(dimensionCategories[i]);
      //     }
      //   } catch (ex) {
      //     console.log(ex);
      //   }
      // }
      // return dcs;
      return dimensionCategories;
    },
    dummyDimension1(cells) {
      let res = [];
      res.push(new Cell(''));
      for (let i in cells) {
        res.push(cells[i]);
      }
      return res;
    },
    is2SpanCell(cell) {
      return is2SpanCell(cell);
    },
    summarytalbewidth(type, hs, showtype = '') {
      if (this.dragstate == 2 && type == this.expType) {
        return {
          width: this.expTemp + 'px',
        };
      }
      let width = 0;
      for (let i = 0; i < hs.length; i++) {
        let css = this.page.columnStyleStocker
          .getColumnStyles(this.page.advertizerId, 'summary', type)
          ?.getColumnStyle(i);
        if (this.isShowSummaryColum(i, showtype)) {
          if (css) {
            if (css.width && css.width > 0) {
              width += css.width;
            }
          } else {
            width += 104;
          }
        }
      }

      if ( type == "mwd") {
        width = width + 50;
      }

      if (width > 0) {
        return {
          width: width + 'px',
        };
      }

      return {};
    },
    pdcount(pgs) {
      let cnt = 0;
      for (let i in pgs) {
        for (let j in pgs[i].dimensionCategories) {
          cnt += this.page.dimensionHolder.generateProviderCategoryRows(
            pgs[i].dimensionCategories[j].cellRows
          )?.length;

          if (!this.page.isCurrentMonth()) {
            if (pgs[i].dimensionCategories[j].cellRows) {
              if (pgs[i].dimensionCategories[j].cellRows.length > 0) {
                for (let x in pgs[i].dimensionCategories[j].cellRows) {
                  let c = pgs[i].dimensionCategories[j].cellRows[x];
                  if (c[0].data.indexOf("着地想定") >= 0) {
                    cnt = cnt - 1;
                    break;
                  }
                }
              }
            }
          }
        }
      }
      return cnt;
    },
    dcount(gs) {
      let cnt = 0;
      for (let i in gs) {
        cnt += this.page.dimensionHolder.generateProviderCategoryRows(gs[i].cellRows)?.length;
        if (!this.page.isCurrentMonth()) {
          if (gs[i].cellRows) {
            if (gs[i].cellRows.length > 0) {
              for (let j in gs[i].cellRows) {
                let c = gs[i].cellRows[j];
                if (c[0].data.indexOf("着地想定") >= 0) {
                  cnt = cnt - 1;
                  break;
                }
              }
            }
          }
        }

      }
      return cnt;
    },
    teststyle(type, index) {
      let css = this.page.columnStyleStocker
        .getColumnStyles(this.page.advertizerId, 'summary', type)
        ?.getColumnStyle(index);
      if (css) {
        return css.style;
      }
      return {};
    },
    onMouseUpEvent(/*event*/) {
      // console.log('onMouseUpEvent', event);
      if (this.dragstate > 0) {
        this.page.columnStyleStocker.setWidth(
          this.page.advertizerId,
          'summary',
          this.expType,
          this.expMarkerId,
          this.exptarget.clientWidth
        );
      }
      this.dragstate = 0;
      this.expMarkerId = -1;
      this.expTemp = 3000;
    },
    onMouseMoveEvent(event) {
      if (this.dragstate > 0) {
        // console.log('onMouseMoveEvent', event);
        this.dragstate = 2;
        this.exptarget.style.width = this.baseWidth + event.clientX - this.baseClientX + 'px';
      }
    },
    dragstart(type, index, e) {
      this.baseClientX = e.clientX;
      this.dragstate = 1;
      if (type == 'summary') {
        // this.exptarget = this.$refs.summary_header_ref[index];
        this.exptarget = document.getElementById('summary_header_id_' + index);
        this.expTemp = document.getElementById('summary_table_tag').clientWidth + 1000;
      } else if (type == 'mwd') {
        // this.exptarget = this.$refs.mwd_header_ref[index];
        this.exptarget = document.getElementById('mwd_header_id_' + index);
        this.expTemp = document.getElementById('mwd_table_tag').clientWidth + 1000;
      } else if (type == 'd3') {
        // this.exptarget = this.$refs.d2_header_ref[index];
        this.exptarget = document.getElementById('d3_header_id_' + index);
        this.expTemp = document.getElementById('d3_table_tag').clientWidth + 1000;
      } else if (type == 'd2') {
        // this.exptarget = this.$refs.d2_header_ref[index];
        this.exptarget = document.getElementById('d2_header_id_' + index);
        this.expTemp = document.getElementById('d2_table_tag').clientWidth + 1000;
      } else if (type == 'd1') {
        // this.exptarget = this.$refs.d1_header_ref[index];
        this.exptarget = document.getElementById('d1_header_id_' + index);
        this.expTemp = document.getElementById('d1_table_tag').clientWidth + 1000;
      }
      this.baseWidth = this.exptarget.clientWidth;
      this.expMarkerId = index;
      this.expType = type;
    },
    onClickEvent() {
      this.csh.clear();
    },
    showShowHide(e, index, type) {
      this.csh.setTop(e.pageY);
      this.csh.setLeft(e.pageX);

      let is = [];

      if (type == 'summary' || type == 'monthly') {
        let hs = this.page.monthlyTable.getHeaderCell('種別');
        for (let i = 0; i < hs.length; i++) {
          is.push(new ColumnInfo(i, hs[i].data));
        }
      } else if (type == 'dimension1') {
        for (let i in this.page.dimensionHolder.dimensions) {
          if (this.page.dimensionHolder.dimensions[i].count() == 1) {
            let hs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
            for (let x = 0; x < hs.length; x++) {
              is.push(new ColumnInfo(x, hs[x].data));
            }
            break;
          }
        }
      } else if (type == 'dimension2') {
        for (let i in this.page.dimensionHolder.dimensions) {
          if (this.page.dimensionHolder.dimensions[i].count() == 2) {
            let hs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
            for (let x = 0; x < hs.length; x++) {
              is.push(new ColumnInfo(x, hs[x].data));
            }
            break;
          }
        }
        if (is.length == 0) {
          for (let i in this.page.dimensionHolder.dimensions) {
            if (this.page.dimensionHolder.dimensions[i].count() == 1) {
              let xhs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
              is.push(new ColumnInfo(0, "媒体"));
              for (let x = 0; x < xhs.length; x++) {
                is.push(new ColumnInfo(x+1, xhs[x].data));
              }
              break;
            }
          }
        }
      } else if (type == 'dimension3') {
        for (let i in this.page.dimensionHolder.dimensions) {
          if (this.page.dimensionHolder.dimensions[i].count() == 3) {
            let hs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
            for (let x = 0; x < hs.length; x++) {
              is.push(new ColumnInfo(x, hs[x].data));
            }
            break;
          }
        }
      }
      this.csh.setColumnInfos(is);
      this.csh.setStocker(
        this.page.invisibleColumnStocker.getComponentInvisibleColumn(
          this.page.advertizerId,
          'summary',
          type
        ),
        index
      );
    },
    isShowSummaryColum(index, type = 'summary') {
      let ic = this.page.invisibleColumnStocker.getComponentInvisibleColumn(
        this.page.advertizerId,
        'summary',
        type
      );
      if (ic) {
        for (let i in ic.invisiblenumbers) {
          let number = ic.invisiblenumbers[i];
          if (index == number) {
            return false;
          }
        }
      }

      return true;
    },
    excelreportdownload() {
      const self = this;
      let p2 = this.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
      this.page.initializeProviderTab(p2.id, function () {
        self.page.providerTablesInitialize(
          p2.id,
          self.adx.getScreenStructureById('consultant').advertizerId,
          self.dateRangeSelector,
          self.adx.user,
          function () {
            self.page.xlsx();
          }
        );
      });
    },
    toggleAdtDbg() {
      this.isDbgAdTable = !this.isDbgAdTable;
      console.log(this.isDbgAdTable);
    },
    spanTilte2HeaderTitle(title) {
      return spanTilte2HeaderTitle(title);
    },
    isCells2SpanCells(cells, type = null) {
      let cs = cells2SpanCells(cells, type);
      for (let i in cs) {
        let c = cs[i];
        if (c.isSpan()) {
          return true;
        }
      }
      return false;
    },
    cells2SpanCells(cells, type = null) {
      let numbers = [];
      if (type != null) {
        let ic = this.page.invisibleColumnStocker.getComponentInvisibleColumn(
          this.page.advertizerId,
          'summary',
          type
        );
        if (ic) {
          numbers = ic.invisiblenumbers;
        }
      }
      return cells2SpanCells(cells, numbers);
    },
    titles2Cell(titles) {
      return titles2Cell(titles);
    },
    initialize(id, pageComponents, advertizerId, timeRange) {
      const self = this;
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      this.page.cancelAllAPICall();
      this.page.initialize(id, pageComponents, advertizerId, timeRange, this.adx.user, this.adx.getScreenStructureById('consultant').colorMaster);
      this.page.updateload(function (date) {
        if (self.isActive) {
          self.adx.changeLastUpdate(date);
        }
      });
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    mouseOverDaily() {
      this.isShowDailyAlertHover = true;
    },
    mouseLeaveDaily() {
      this.isShowDailyAlertHover = false;
    },
    mouseOverWeekly() {
      this.isShowWeeklyAlertHover = true;
    },
    mouseLeaveWeekly() {
      this.isShowWeeklyAlertHover = false;
    },
    toggleWeeklyAlert() {
      //this.isShowWeeklyAlert = !this.isShowWeeklyAlert;//[ALERT1]alertは１件でいい
    },
    isShowWeeklyAlertCheck() {
      return this.isShowWeeklyAlertHover || this.isShowWeeklyAlert;
    },
    toggleDailyAlert() {
      //this.isShowDailyAlert = !this.isShowDailyAlert;//[ALERT1]alertは１件でいい
    },
    isShowDailyAlertCheck() {
      return this.isShowDailyAlertHover || this.isShowDailyAlert;
    },
    isRangeMode() {
      if (this.dateRangeSelector) {
        return this.dateRangeSelector.mode == 1;
      }
      return false;
    },
    cpccpacvter(pmtf, cell) {
      if (cell) {
        if (cell.key === 'cpc') {
          return !pmtf;
        }
        if (cell.key.match(/cpa/)) {
          return !pmtf;
        }
      }
      return pmtf;
    },
    ratescale(rate) {
      let r = 0;
      let g = 0;
      let b = 0;
      let a = 0;
      let rt = parseFloat(rate);
      if (!isNaN(rt)) {
        if (rt > 100) {
          r = 92;
          g = 174;
          b = 76;
          a = (rt - 100) / 30;
        }
        if (rt < 100) {
          r = 233;
          g = 70;
          b = 55;

          a = (90 - rt) / 90;
        }
      }

      if (a > 1) {
        a = 1;
      }
      if (a < 0) {
        a = 0;
      }
      return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
    },
    rangecalendarevent: function (event, arg) {
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    timerangeevent: function (event) {
      console.log(event);
      this.isShowRange = true;
    },
    getIcon(id) {
      //TBD
      switch (id) {
        case 1:
          return require('@/assets/icon/Google.png');
        case 2:
          return require('@/assets/icon/Google.png');
        case 3:
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 4:
          return require('@/assets/icon/Google.png');
        case 5:
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 6:
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
        case 7:
          return require('@/assets/icon/LINE_APP_Android.png');
        case 8:
          return require('@/assets/thirdparty/icon/Criteo.png');
        case 9:
          return require('@/assets/icon/Google.png');
        case 10:
          return require('@/assets/icon/Google.png');
        case 11:
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
        case 12:
          return require('@/assets/icon/Google.png');
        case 13:
          return require('@/assets/icon/Google.png');
        case 14:
          return require('@/assets/icon/Google.png');
        case 126:
          return require('@/assets/icon/Google.png');
      }
      return '';
    },
    changeMode(mode) {
      this.page.selectedMode = mode;
    },
    getModeName() {
      let name = '';
      switch (this.page.selectedMode) {
        case 1:
          name = '月別';
          break;
        case 2:
          name = '週別';
          break;
        case 3:
          name = '日別';
          break;
      }
      return name;
    },
    providerSelect(provider) {
      this.page.changeProviderSelect(provider);
    },
    isProviderSelected(provider) {
      return this.page.selectedProvider.map((p) => p.name).includes(provider.name);
    },
    getAlignedTableWidth() {
      // レンダリングされる前に実行されちゃうので、広さが0になっちゃう。苦肉の策で固定値を返す。
      //let providerList = this.page.dimensionHolder.get2DimensionCategories().map(d => d.group);
      //let widthList = providerList
      //                    .filter(providerName => this.isDimensionSelected(providerName))
      //                    .map(providerName => document.getElementById(providerName+'0'))
      //                    .filter(e => e!=null)
      //                    .map(e => e.clientWidth)
      //if(widthList.length == 0) {
      //  return '100%';
      //}
      return this.page.selectedProvider.length == 0 ? '' : '1600px';
    },
    copyToClipboard: (className) => {
      // display copy message
      let copyMessage = document.createElement('div');
      copyMessage.className = 'copy_message';
      copyMessage.style.position = 'absolute';
      copyMessage.style.zIndex = '999';
      copyMessage.style.top = `${window.event.clientY + document.documentElement.scrollTop + 5}px`;
      copyMessage.style.left = `${
        window.event.clientX + document.documentElement.scrollLeft + 10
      }px`;
      copyMessage.style.backgroundColor = 'rgb(200,200,200)';
      copyMessage.style.padding = '3px';
      copyMessage.style.borderRadius = '5px';
      copyMessage.innerHTML = 'Copying...';
      document.getElementsByTagName('body')[0].appendChild(copyMessage);

      // copy element
      let applyInline = (el_clone, el_origin) => {
        const cs = window.getComputedStyle(el_origin);

        for (let key in cs) {
          if (!isNaN(parseInt(key))) continue;
          if (['length', 'parentRule'].includes(key)) continue;
          //if(! ["background", "font"].includes(key)) return ;
          if (['font', 'backgroundColor', 'color'].includes(key)) el_clone.style[key] = cs[key];
        }
        el_clone.style['fontSize'] = '100%';
        Array.from(el_clone.children).forEach((n, i) => applyInline(n, el_origin.children[i]));
      };
      try {
        let origin = document.getElementsByClassName(className)[0];
        let tbl = origin.cloneNode(true);
        applyInline(tbl, origin);
        var type = 'text/html';
        var blob = new Blob([tbl.outerHTML], { type });
        var data = [new window.ClipboardItem({ [type]: blob })];
        navigator.clipboard.write(data).then(
          () => {
            copyMessage.innerHTML = 'Copy done!';
            setTimeout(() => {
              copyMessage.remove();
            }, 1000);
          },
          () => {
            copyMessage.innerHTML = 'Copy failed!';
            setTimeout(() => {
              copyMessage.remove();
            }, 1000);
          }
        );
      } catch (e) {
        copyMessage.innerHTML = 'Copy failed!';
        setTimeout(() => {
          copyMessage.remove();
        }, 1000);
      }
    },
    getTableWidthByColumns(additional_columns = 0) {
      let cols = this.page.monthlyTable.getHeaderCell('種別').length - 1; // 基本カラム数 (種別カラムを除外)
      let ret = 100 * ((cols + additional_columns) / (13 + additional_columns));
      return String(ret) + '%';
    },
    getProviderCategoryTableWidth() {
      let cols = this.page.monthlyTable.getHeaderCell('種別').length - 1; // 基本カラム数 (種別カラムを除外)
      let ret = 85 * ((cols + 2) / 15);
      if (cols == -1) {
        ret = 85;
      }
      return ['15%', String(ret + 15) + '%'];
    },
    getProviderTableWidth() {
      return document.getElementById('providerWidthTable')?.clientWidth + 'px';
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    BoxSpinner,
    ScreenBarProgress2,
    RangeCalendar,
    ColumnShowHide,
  },
  props: {
    adx: Object,
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.scroller);

    document.removeEventListener('click', this.onClickEvent);
    this.page.cancelAllAPICall();

    this.isActive = false;
    this.adx.changeLastUpdate('');

    this.adx.excelreport.setCallback(null);
    this.adx.excelreport.reset();
  },
};
</script>
