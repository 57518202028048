<template>
  <div>
    <div class="overlay_layer" v-if="isShowRange">
      <div class="summary_rangecalendar_background" v-on:click="rangecalendarevent"></div>
      <div class="summary_rangecalendar_layer">
        <div class="summary_rangecalendar">
          <div class="summary_rangecalendar__wrapper">
            <div v-if="dateRangeSelector">
              <RangeCalendar
                :range="dateRangeSelector.timeYmdRange"
                :todayYmd="getTodayYmd()"
                @rangecalendarevent="rangecalendarevent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_content_wrapper">
      <div class="adx_report_keyword">
        <div class="adv_main_content">
          <div style="background: white">
            <div
              style="position: relative"
              class="adv_main_content__header"
              :class="[
                page.isSprintPanelOpen
                  ? 'adv_main_content__header--sprintopen'
                  : 'adv_main_content__header--sprintclose',
              ]"
            >
              <ContentHeader
                title="サマリー"
                :timeRange="dateRangeSelector"
                :adx="adx"
                @timerangeevent="timerangeevent"
              />
              <div style="position: absolute; top: 76px; left: 0px; right: 0">
                <ScreenBarProgress2 :process="page.process" />
              </div>
            </div>
          </div>
          <div class="adv_main_content__body">
            <BoxSpinner
              v-if="page.recordHolder.records == 0"
              style="height: 333px; width: 85.7143%; max-width: 1600px"
            />
            
            <div class="box" v-if="page.recordHolder.records.length > 0">
              <div class="frame" style="max-width: 1248px">
                <div class="frame__body" style="display: flex">
                  <template
                    v-for="(table, index2) in page
                      .getManagerTarget()
                      .getTables(page.recordHolder.getTableColHeaders())"
                    :key="index2"
                  >
                    <div class="rspc" :style="[index2 != 0 ? 'margin-left:8px;' : '']">
                      <div class="rspc__header" style="text-align: center">
                        {{ table?.structure?.alias }}
                      </div>
                      <CircleProgress
                        :value="table.getRateValue().data"
                        :design="
                          page.getProgressSummaryCellDesign(
                            table.getRateValue().data,
                            table.structure.backendKey
                          )
                        "
                      />
                      <div
                        style="display: flex; border-bottom: 1px solid #333; padding-bottom: 8px"
                      >
                        <div>{{ rpctitle() }}</div>
                        <div style="margin-left: auto">
                          {{
                            getcellFindMonthlyTableBykey(
                              '着地想定',
                              table.structure.backendKey,
                              table.structure
                            )
                          }}
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 8px">
                        <div>目標</div>
                        <div style="margin-left: auto">
                          {{
                            getcellFindMonthlyTableBykey(
                              '目標',
                              table.structure.backendKey,
                              table.structure
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <div
                    style="width: 100%; padding-left: 8px"
                    v-if="page.recordHolder.records.length > 0"
                  >
                    <div class="rspc" style="width: 100%">
                      <div class="rspc__header" style="text-align: center">月次推移</div>
                      <div style="height: 260px">
                        <M2ChartJs
                          :labels="page.getManagerTarget().getGraphTitle()"
                          :graphs="page.getManagerTarget().getGraphs()"
                          :option="{
                            categoryPercentage: 0.6,
                            barPercentage: 1.6,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="box"
              :class="[page.isSprintPanelOpen ? 'box--sprintopen' : 'box--sprintclose']"
            >
              <div class="frame">
                <div class="frame__body" style="position: relative;">
                  <div class="mousepointer" style="position: absolute; top: 8px; z-index: 9;" v-if="this.page != null"
                    :style="menuTitleStyle('summary', this.page.monthlyTable.getHeaderCell('種別'), 'summary')"
                    v-on:click="showReportMenu(item, $event, 'summary_table')">
                    ...
                  </div>
                  <div class="frame__header frame__header--expansion_lock" v-if="!isRangeMode()">
                    <div :style="{ paddingLeft: '1px' }" style="position: relative;">
                      <div
                        :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                        v-on:click="copyToClipboard('summary_table')"
                      >
                        {{ title01() }}
                      </div>
                    </div>
                  </div>
                  <BoxSpinner
                    v-if="page.isMonthlyTableNetworking || !isLoad"
                    style="height: 143px; width: 85.7143%; max-width: 1600px"
                  />
                  <div class="frame__body scroll_bar" v-if="!isRangeMode()" style="overflow: auto">
                    <Spinner v-if="page.isMonthlyTableNetworking && false" />
                    <div
                      :style="
                        summarytalbewidth(
                          'summary',
                          this.page.monthlyTable.getHeaderCell('種別'),
                          'summary'
                        )
                      "
                    >
                      <table
                        id="summary_table_tag"
                        v-bind:class="[dragstate > 0 ? 'draglock' : '']"
                        v-if="!page.isMonthlyTableNetworking && isLoad"
                        class="floater_table floater_table--full summary_table expansion_table"
                        style="table-layout: auto"
                      >
                        <thead class="floater_table__header expansion_table__header">
                          <tr
                            v-if="
                              isCells2SpanCells(page.monthlyTable.getHeaderCell('種別'), 'summary')
                            "
                          >
                            <template
                              v-for="(item, index) in cells2SpanCells(
                                page.monthlyTable.getHeaderCell('種別'),
                                'summary'
                              )"
                              :key="index"
                            >
                              <th
                                style="background: white; border: initial; position: relative"
                                v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                              ></th>
                              <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                {{ item.getTitle() }}
                              </th>
                              <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                {{ item.getTitle() }}
                              </th>
                            </template>
                          </tr>
                          <tr>
                            <template
                              v-for="(item, index) in page.monthlyTable.getHeaderCell('種別')"
                              :key="index"
                            >
                              <th
                                style="cursor: pointer; position: relative"
                                ref="summary_header_ref"
                                :id="'summary_header_id_' + index"
                                v-on:click.stop="showShowHide($event, index, 'summary')"
                                class="floater_table__summary_weekly expansion_table__th"
                                v-if="isShowSummaryColum(index, 'summary')"
                                :style="[teststyle('summary', index)]"
                              >
                                <div
                                  class="expansion_table__dragmarker"
                                  :class="[
                                    expMarkerId == index && expType == 'summary'
                                      ? 'expansion_table__dragmarker--active'
                                      : '',
                                  ]"
                                  v-on:click.stop=""
                                  v-on:mousedown.stop="dragstart('summary', index, $event)"
                                >
                                  <div class="expansion_table__dragmarker__arrow">
                                    <div class="expansion_table__dragmarker__lrarrow">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="18"
                                        viewBox="0 0 16 18"
                                        transform="rotate(90)"
                                      >
                                        <g
                                          id="g484"
                                          data-name="g484"
                                          transform="translate(-8 -123)"
                                        >
                                          <path
                                            id="p530"
                                            data-name="p530"
                                            d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                            transform="translate(4 120)"
                                            fill="#fff"
                                          />
                                          <rect
                                            id="s_949"
                                            data-name="s949"
                                            width="16"
                                            height="2"
                                            transform="translate(8 131)"
                                            fill="#fff"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                {{ spanTilte2HeaderTitle(item.getString('consultant')) }}
                              </th>
                            </template>
                          </tr>
                        </thead>

                        <tbody class="floater_table__body">
                          <template
                            v-for="(cellColms, index) in page.monthlyTable.getMonthSummary()"
                            :key="index"
                          >
                            <tr
                              v-bind:class="[
                                index == 0 ? 'consultant_record__trmark' : '',
                                index == 3 ? 'consultant_record__trmark2' : '',
                              ]"
                              v-if="(index != 2 || (index == 2 && page.isCurrentMonth() == true))"
                            >
                              <template v-for="(cell, cindex) in cellColms" :key="cindex">
                                <td
                                  v-if="(index == 3) & isShowSummaryColum(cindex, 'summary')"
                                  :set="
                                    (cellDesign = page.monthlyTable.getMonthSummaryCellDesign(
                                      cell.data,
                                      cindex
                                    ))
                                  "
                                  v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                  class="floater_table__summary_weekly"
                                >
                                  <div
                                    style="
                                      padding: 8px;
                                      position: relative;
                                      top: 0;
                                      left: 0;
                                      right: 0;
                                      bottom: 0;
                                    "
                                    :class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                  >
                                    <div
                                      :class="[cindex == 0 ? 'font_smaller' : '']"
                                      style="position: relative; margin-left: auto"
                                      :style="[
                                        cindex != 0 ? 'width: fit-content' : '',
                                        cellDesign.cellclass != null
                                          ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px'
                                          : '',
                                      ]"
                                    >
                                      <svg
                                        v-if="cellDesign.cellclass != null"
                                        v-bind:class="cellDesign.cellclass"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 11.268 13.002"
                                      >
                                        <path
                                          id="合体_22"
                                          data-name="合体 22"
                                          d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                          transform="translate(-10732.001 -12773.001)"
                                        />
                                      </svg>

                                      {{ titlewrapper(cell.getString('consultant')) }}
                                    </div>
                                  </div>
                                </td>

                                <td
                                  v-if="index != 3 && isShowSummaryColum(cindex, 'summary')"
                                  v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                  class="floater_table__summary_weekly expansion_table__td"
                                >
                                  <div
                                    style="
                                      padding: 8px;
                                      position: relative;
                                      top: 0;
                                      left: 0;
                                      right: 0;
                                      bottom: 0;
                                    "
                                    :class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                  >
                                    <div :class="[cindex == 0 ? 'font_smaller' : '']">
                                      {{ titlewrapper(cell.getString('consultant')) }}
                                    </div>
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="box"
              :class="[page.isSprintPanelOpen ? 'box--sprintopen' : 'box--sprintclose']"
            >
              <div class="frame">
                <div class="box" style="width: 100%">
                  <div style="width: 100%; position: relative;">
                    <div class="mousepointer" style="position: absolute; top: 8px; z-index: 9;"
                      :style="menuTitleStyle('mwd', page.monthlyTable.getHeaderCell('種別'), 'monthly')"
                      v-on:click="showReportMenu(item, $event, page.selectedMode == 1
                            ? 'monthly_table'
                            : page.selectedMode == 2
                            ? 'weekly_table'
                            : page.selectedMode == 3
                            ? 'daily_table'
                            : '')">
                      ...
                    </div>
                    <div class="frame__header frame__header--expansion_lock" v-if="!isRangeMode()">
                      <div :style="{ paddingLeft: '1px' }" style="position: relative;">
                        <div
                          :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                          v-on:click="
                            copyToClipboard(
                              page.selectedMode == 1
                                ? 'monthly_table'
                                : page.selectedMode == 2
                                ? 'weekly_table'
                                : page.selectedMode == 3
                                ? 'daily_table'
                                : ''
                            )
                          "
                        >
                          {{ getModeName() }}実績
                        </div>
                      </div>
                    </div>

                    <div class="frame__body">
                      <div :style="{ paddingLeft: '1px' }" />
                      <div style="display: flex">
                        <div
                          class="selectbtn clickable"
                          v-bind:class="[page.selectedMode == 1 ? 'selectbtn--active' : '']"
                          v-on:click="changeMode(1)"
                        >
                          月別
                        </div>
                        <div
                          class="selectbtn clickable"
                          v-bind:class="[page.selectedMode == 2 ? 'selectbtn--active' : '']"
                          v-on:click="changeMode(2)"
                        >
                          週別
                        </div>
                        <div
                          class="selectbtn clickable"
                          v-bind:class="[page.selectedMode == 3 ? 'selectbtn--active' : '']"
                          v-on:click="changeMode(3)"
                        >
                          日別
                        </div>
                      </div>
                    </div>
                    <BoxSpinner
                      v-if="page.isMonthlyTableNetworking || !isLoad"
                      style="height: 263px; width: 85.7143%; max-width: 1600px"
                    />

                    <div
                      class="frame__body scroll_bar"
                      v-if="page.selectedMode == 1"
                      style="overflow: auto"
                    >
                      <div
                        class="nodataline"
                        v-if="!page.isMonthlyResultNetworking && page.isMonthlyResultNoData"
                      >
                        NoData
                      </div>
                      <div
                        :style="
                          summarytalbewidth(
                            'mwd',
                            page.monthlyTable.getHeaderCell('種別'),
                            'monthly'
                          )
                        "
                      >
                        <Spinner v-if="page.isMonthlyTableNetworking && false" />
                        <table
                          id="mwd_table_tag"
                          class="floater_table floater_table--full monthly_table expansion_table"
                          v-else
                        >
                          <thead class="floater_table__header expansion_table__header">
                            <tr
                              v-if="
                                isCells2SpanCells(
                                  page.monthlyTable.getHeaderCell('種別'),
                                  'monthly'
                                )
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  page.monthlyTable.getHeaderCell('種別'),
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <th
                                  class="sticky_title"
                                  style="background: white; border: initial"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <template
                                v-for="(item, index) in page.monthlyTable.getHeaderCell('種別')"
                                :key="index"
                              >
                                <th
                                  style="position: relative; cursor: pointer"
                                  ref="mwd_header_ref"
                                  :id="'mwd_header_id_' + index"
                                  v-on:click.stop="showShowHide($event, index, 'monthly')"
                                  v-if="isShowSummaryColum(index, 'monthly')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  :style="[teststyle('mwd', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'mwd'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('mwd', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ mwd_title_change(spanTilte2HeaderTitle(item.getString('consultant'))) }}
                                </th>
                              </template>
                            </tr>
                          </thead>

                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.monthlyTable.getTable()"
                              :key="index"
                            >
                              <tr>
                                <template v-for="(cell, cindex) in cellColms" :key="cindex">
                                  <td
                                    v-if="false"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__summary_weekly"
                                  >
                                    {{ cell.getString('consultant') }}
                                  </td>

                                  <td
                                    v-if="isShowSummaryColum(cindex, 'monthly')"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    class="floater_table__summary_weekly"
                                    style="padding: 0px; position: relative"
                                    :set="
                                      (cellDesign = page.monthlyTable.getCellDesign(
                                        cell.data,
                                        cindex
                                      ))
                                    "
                                  >
                                    <div
                                      v-bind:style="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        padding: cellDesign.design == 'box' ? '0px' : '4px',
                                        paddingLeft: '0px',
                                      }"
                                    >
                                      <div
                                        style="height: 100%; width: "
                                        v-bind:style="{
                                          width: cellDesign.isColored
                                            ? cellDesign.percent + '%'
                                            : '0%',
                                          background: cellDesign.isColored
                                            ? cellDesign.color
                                            : '#FFFFFF',
                                          border:
                                            cellDesign.border != null ? cellDesign.border : '',
                                        }"
                                      ></div>
                                    </div>
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                    >
                                      <div
                                        v-if="!cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant') }}
                                      </div>
                                      <div
                                        v-if="cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant').replace('(着地想定)', '') }}
                                        <div style="font-size: 80%; display: inline-block">
                                          (着地想定)
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="frame__body scroll_bar"
                      style="overflow: auto"
                      v-if="page.selectedMode == 2"
                    >
                      <div
                        class="nodataline"
                        v-if="!page.isMonthlyResultNetworking && page.isMonthlyResultNoData"
                      >
                        NoData
                      </div>
                      <div
                        :style="
                          summarytalbewidth(
                            'mwd',
                            page.monthlyTable.getHeaderCell('種別'),
                            'monthly'
                          )
                        "
                      >
                        <Spinner v-if="page.isWeeklyTableNetworking" />
                        <table
                          id="mwd_table_tag"
                          v-else
                          class="floater_table floater_table--full weekly_table expansion_table"
                        >
                          <thead class="floater_table__header expansion_table__header">
                            <tr
                              v-if="
                                page.weeklyTable.cellRows.length > 0 &&
                                isCells2SpanCells(page.weeklyTable.getHeaderCell('週'), 'monthly')
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  page.weeklyTable.getHeaderCell('週'),
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <th
                                  style="background: white; border: initial"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                              </template>
                            </tr>
                            <tr v-if="page.weeklyTable.cellRows.length > 0">
                              <template
                                v-for="(item, index) in page.weeklyTable.getHeaderCell('週')"
                                :key="index"
                              >
                                <th
                                  ref="mwd_header_ref"
                                  :id="'mwd_header_id_' + index"
                                  style="position: relative; cursor: pointer"
                                  v-on:click.stop="showShowHide($event, index, 'monthly')"
                                  v-if="isShowSummaryColum(index, 'monthly')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  :style="[teststyle('mwd', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'mwd'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('mwd', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ mwd_title_change(spanTilte2HeaderTitle(item.getString('consultant'))) }}
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.weeklyTable.getTable()"
                              :key="index"
                            >
                              <tr>
                                <template v-for="(cell, cindex) in cellColms.cells" :key="cindex">
                                  <td
                                    v-if="isShowSummaryColum(cindex, 'monthly')"
                                    class="floater_table__summary_weekly"
                                    v-bind:class="[cindex == 0 ? 'floater_table__titlecell' : '']"
                                    style="padding: 0px; position: relative"
                                    :set="
                                      (cellDesign = page.weeklyTable.getCellDesign(
                                        cell.data,
                                        cindex
                                      ))
                                    "
                                  >
                                    <div
                                      v-bind:style="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        padding: cellDesign.design == 'box' ? '0px' : '4px',
                                        paddingLeft: '0px',
                                      }"
                                    >
                                      <div
                                        style="height: 100%; width: "
                                        v-bind:style="{
                                          width: cellDesign.isColored
                                            ? cellDesign.percent + '%'
                                            : '0%',
                                          background: cellDesign.isColored
                                            ? cellDesign.color
                                            : '#FFFFFF',
                                          border:
                                            cellDesign.border != null ? cellDesign.border : '',
                                        }"
                                      ></div>
                                    </div>
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                    >
                                      <div
                                        v-if="!cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant') }}
                                      </div>
                                      <div
                                        v-if="cell.getString('consultant').includes('(着地想定)')"
                                      >
                                        {{ cell.getString('consultant').replace('(着地想定)', '') }}
                                        <div style="font-size: 80%; display: inline-block">
                                          (着地想定)
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="frame__body scroll_bar"
                      style="overflow: auto"
                      v-if="page.selectedMode == 3"
                    >
                      <div
                        class="nodataline"
                        v-if="!page.isMonthlyResultNetworking && page.isMonthlyResultNoData"
                      >
                        NoData
                      </div>
                      <div
                        :style="
                          summarytalbewidth(
                            'mwd',
                            page.monthlyTable.getHeaderCell('種別'),
                            'monthly'
                          )
                        "
                      >
                        <Spinner v-if="page.isDailyTableNetWorking" />
                        <table
                          id="mwd_table_tag"
                          v-else
                          class="floater_table floater_table--full daily_table expansion_table"
                        >
                          <thead class="floater_table__header expansion_table__header" v-if="true">
                            <tr
                              v-if="
                                isCells2SpanCells(
                                  titles2Cell(page.dailyTable.getTableTitle()),
                                  'monthly'
                                )
                              "
                            >
                              <template
                                v-for="(item, index) in cells2SpanCells(
                                  titles2Cell(page.dailyTable.getTableTitle()),
                                  'monthly'
                                )"
                                :key="index"
                              >
                                <th
                                  style="background: white; border: initial"
                                  v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                ></th>
                                <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                  {{ item.getTitle() }}
                                </th>
                                <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                  {{ item.getTitle() }}
                                </th>
                                <th
                                  style="background: white; border: initial"
                                  v-if="index==0">
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <template
                                v-for="(title, index) in page.dailyTable.getTableTitle()"
                                :key="index"
                              >
                                <th
                                  ref="mwd_header_ref"
                                  :id="'mwd_header_id_' + index"
                                  style="position: relative; cursor: pointer"
                                  v-on:click.stop="showShowHide($event, index, 'monthly')"
                                  v-if="isShowSummaryColum(index, 'monthly')"
                                  class="floater_table__summary_weekly expansion_table__th"
                                  :style="[teststyle('mwd', index)]"
                                >
                                  <div
                                    class="expansion_table__dragmarker"
                                    :class="[
                                      expMarkerId == index && expType == 'mwd'
                                        ? 'expansion_table__dragmarker--active'
                                        : '',
                                    ]"
                                    v-on:click.stop=""
                                    v-on:mousedown.stop="dragstart('mwd', index, $event)"
                                  >
                                    <div class="expansion_table__dragmarker__arrow">
                                      <div class="expansion_table__dragmarker__lrarrow">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          transform="rotate(90)"
                                        >
                                          <g
                                            id="g484"
                                            data-name="g484"
                                            transform="translate(-8 -123)"
                                          >
                                            <path
                                              id="p530"
                                              data-name="p530"
                                              d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                              transform="translate(4 120)"
                                              fill="#fff"
                                            />
                                            <rect
                                              id="s_949"
                                              data-name="s949"
                                              width="16"
                                              height="2"
                                              transform="translate(8 131)"
                                              fill="#fff"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                  {{ mwd_title_change(spanTilte2HeaderTitle(title)) }}
                                </th>
                                <th class="floater_table__summary_weekly table_dow_header" v-if="index==0" style="width: 47px">
                                  曜日
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody class="floater_table__body">
                            <template
                              v-for="(cellColms, index) in page.dailyTable.getTable()"
                              :key="index"
                            >
                              <tr v-if="true">
                                <td
                                  class="floater_table__summary_weekly floater_table__titlecell"
                                  style="padding: 0px; position: relative"
                                >
                                  <div
                                    style="
                                      padding: 8px;
                                      position: relative;
                                      top: 0;
                                      left: 0;
                                      right: 0;
                                      bottom: 0;
                                    "
                                  >
                                    {{ cellColms.name }}
                                  </div>
                                </td>
                                <td class="table_dow_cell" style="width: 47px">{{ cellColms.dayOfWeek }}</td>
                                <template v-for="(item, cindex) in cellColms.items" :key="cindex">
                                  <td
                                    v-if="isShowSummaryColum(cindex + 1, 'monthly')"
                                    class="floater_table__summary_weekly"
                                    style="padding: 0px; position: relative"
                                    :set="
                                      (cellDesign = page.dailyTable.getCellDesign(
                                        item.cell.data,
                                        cindex
                                      ))
                                    "
                                  >
                                    <div
                                      v-bind:style="{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        padding: cellDesign.design == 'box' ? '0px' : '4px',
                                        paddingLeft: '0px',
                                      }"
                                    >
                                      <div
                                        style="height: 100%; width: "
                                        v-bind:style="{
                                          width: cellDesign.isColored
                                            ? cellDesign.percent + '%'
                                            : '0%',
                                          background: cellDesign.isColored
                                            ? cellDesign.color
                                            : '#FFFFFF',
                                          border:
                                            cellDesign.border != null ? cellDesign.border : '',
                                        }"
                                      ></div>
                                    </div>
                                    <div
                                      style="
                                        padding: 8px;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                      "
                                    >
                                      <div
                                        v-if="
                                          !item.cell.getString('consultant').includes('(着地想定)')
                                        "
                                      >
                                        {{ item.cell.getString('consultant') }}
                                      </div>
                                      <div
                                        v-if="
                                          item.cell.getString('consultant').includes('(着地想定)')
                                        "
                                      >
                                        {{
                                          item.cell
                                            .getString('consultant')
                                            .replace('(着地想定)', '')
                                        }}
                                        <div style="font-size: 80%; display: inline-block">
                                          (着地想定)
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="box"
              :class="[page.isSprintPanelOpen ? 'box--sprintopen' : 'box--sprintclose']"
            >
              <div class="frame">
                <div class="frame__body" style="position: relative;">
                  <div class="mousepointer" style="position: absolute; top: 8px; z-index: 9;"
                    v-on:click="showReportMenu(item, $event, 'provider_category_table')"
                    :style="menuTitleStyle('d3', page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags())[0].getHeaderCell(), 'dimension3')"
                    v-if="page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags()).length > 0 && page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags())[0].count() == 3">
                    ...
                  </div>
                  <div class="mousepointer" style="position: absolute; top: 8px; z-index: 9;"
                    v-on:click="showReportMenu(item, $event, 'provider_category_table')"
                    :style="menuTitleStyle('d2', page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags())[0].getHeaderCell(), 'dimension2')"
                    v-if="page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags()).length > 0 && page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags())[0].count() == 2">
                    ...
                  </div>
                  <div class="mousepointer" style="position: absolute; top: 8px; z-index: 9;"
                    v-on:click="showReportMenu(item, $event, 'provider_category_table')"
                    :style="menuTitleStyle('d1', page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags())[0].getHeaderCell(), 'dimension1')"
                    v-if="page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags()).length > 0 && page.dimensionHolder.getProviderCategoryTableByTags(page.dimensionSelector.getSelectedTags())[0].count() == 1">
                    ...
                  </div>
                  <div>
                    <div class="frame__header">
                      <div :style="{ paddingLeft: '1px' }" style="position: relative;">
                        <div
                          :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                          v-if="
                            page.dimensionSelector.getSelectedTags() == 0 ||
                            (page.dimensionSelector.getSelectedTags().length > 0 &&
                              page.dimensionHolder.getProviderCategoryTableByTags(
                                page.dimensionSelector.getSelectedTags()
                              ).length == 0)
                          "
                        >
                          {{ page.dimensionSelector.getTitle() }}
                        </div>
                        <div
                          :style="{ borderLeft: '3px solid #39434E', paddingLeft: '8px' }"
                          v-if="
                            page.dimensionHolder.getProviderCategoryTableByTags(
                              page.dimensionSelector.getSelectedTags()
                            ).length > 0
                          "
                          v-on:click="copyToClipboard('provider_category_table')"
                        >
                          {{
                            page.dimensionHolder
                              .getProviderCategoryTableByTags(
                                page.dimensionSelector.getSelectedTags()
                              )[0]
                              .title()
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="frame__body">
                      <div
                        class="dimension_selector"
                        v-if="!(page.isProviderCategoryTableNetworking || !isLoad)"
                      >
                        <template
                          v-for="(item, index) in page.dimensionSelector.getTags()"
                          :key="index"
                        >
                          <div
                            class="dimension_selector__button"
                            v-bind:class="page.dimensionSelector.getClassDimensionNumberByTag(item)"
                            v-on:click="page.dimensionSelector.setSelectByTag(item)"
                          >
                            {{ item }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>

                  <BoxSpinner
                    v-if="page.isProviderCategoryTableNetworking || !isLoad"
                    style="height: 30px; width: 200px; margin-bottom: 30px"
                  />
                  <div style="display: flex; max-width: 1600px" v-if="false">
                    <BoxSpinner style="height: 400px; width: calc(10% - 5px); margin-right: 5px" />
                    <BoxSpinner style="height: 400px; width: 90%" />
                  </div>
                  <div style="display: flex; max-width: 1600px" v-if="page.isDimensionNetwork">
                    <BoxSpinner style="height: 200px; width: 100%" />
                  </div>
                  <div style="width: 100%; height: 1px;" id="dm0position"></div>
                  <div v-if="!page.isDimensionNetwork">
                    <template
                    v-for="(
                      dimensionOrdered, index
                    ) in page.dimensionHolder.getProviderCategoryTableByTags(
                      page.dimensionSelector.getSelectedTags()
                    )"
                    :key="index"
                  >
                    <div v-if="dimensionOrdered.count() == 3">
                      <div class="frame__body scroll_bar">
                        <div
                          :style="
                            summarytalbewidth('d3', dimensionOrdered.getHeaderCell(), 'dimension3')
                          "
                        >
                          <table
                            id="d3_table_tag"
                            class="
                              floater_table floater_table--full
                              provider_category_table
                              expansion_table
                            "
                          >
                            <thead id="dm_header_position" class="floater_table__header expansion_table__header" style="position: relative;">
                              <tr
                                v-if="
                                  isCells2SpanCells(dimensionOrdered.getHeaderCell(), 'dimension3')
                                "
                              >
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <template
                                  v-for="(item, ih) in cells2SpanCells(
                                    dimensionOrdered.getHeaderCell(),
                                    'dimension3'
                                  )"
                                  :key="ih"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>

                              <tr>
                                <template
                                  v-for="(cell, index2) in dimensionOrdered.getHeaderCell()"
                                  :key="index2"
                                >
                                  <th
                                    class="floater_table__in2line copyremovecell"
                                    v-if="index2 == 3"
                                    style="border-left: 2px solid #cfcfcf"
                                  >
                                    COST
                                  </th>
                                  <th class="floater_table__in2line copyremovecell" v-if="index2 == 3">CV</th>
                                  <th
                                    class="floater_table__in2line copyremovecell"
                                    v-if="index2 == 3"
                                    style="border-right: 2px solid #cfcfcf"
                                  >
                                    {{page.getMainCpaLabel()}}
                                  </th>

                                  <th
                                    ref="d3_header_ref"
                                    :id="'d3_header_id_' + index2"
                                    style="position: relative; cursor: pointer"
                                    v-on:click.stop="showShowHide($event, index2, 'dimension3')"
                                    class="floater_table__summary_weekly expansion_table__th"
                                    v-if="isShowSummaryColum(index2, 'dimension3')"
                                    :style="[teststyle('d3', index2)]"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == index2 && expType == 'd3'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('d3', index2, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div style="padding-top: 4px; padding-bottom: 4px; box-sizing: border-box;">{{ spanTilte2HeaderTitle(cell.getString('consultant')) }}</div>
                                    <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 0) || (dmCt1label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt1label }}</div></div>
                                    <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 1) || (dmCt2label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt2label }}</div></div>
                                    <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 2) || (dmCt3label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt3label }}</div></div>
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody class="floater_table__body floater_table__body--in2line">
                              <template
                                v-for="(pdimension, pindex) in dimensionOrdered.dimensions"
                                :key="pindex"
                              >
                                <template
                                  v-for="(dimension, index2) in dcgtmpsplice(
                                    pdimension.dimensionCategoryGroups
                                  )"
                                  :key="index2"
                                >
                                  <template
                                    v-for="(group, gindex) in dctmpsplice(
                                      dimension.dimensionCategories
                                    )"
                                    :key="gindex"
                                  >
                                    <template
                                      v-for="(
                                        cells, index3
                                      ) in page.dimensionHolder.generateProviderCategoryRows(
                                        group.cellRows
                                      )"
                                      :key="index3"
                                    >
                                      <tr
                                        v-if="cells.length > 0 && !isreoffset(cells)"
                                        v-bind:class="[
                                          group.cellRows.length == 1 ? 'tr_one_line' : '',
                                          index3 == 0 ? 'consultant_record__trmark' : '',
                                        ]"
                                      >
                                        <td
                                          :class="[true ? 'dm1position' : '']"
                                          style="background: white; padding: 15px"
                                          class="
                                            floater_table__summary_weekly
                                            floater_table__keyword__1categorytitle
                                          "
                                          v-if="
                                            gindex == 0 &&
                                            index3 == 0 &&
                                            index2 == 0
                                          "
                                          :rowSpan="pdcount(pdimension.dimensionCategoryGroups)"
                                        >
                                          <div style="position: absolute; top: 15px">
                                            <div style="text-align: left">
                                              {{ pdimension.group }}
                                            </div>
                                          </div>
                                        </td>

                                        <td
                                          :class="[true ? 'dm2position' : '']"
                                          style="background: white; padding: 15px"
                                          class="
                                            floater_table__summary_weekly
                                            floater_table__keyword__1categorytitle
                                          "
                                          v-if="
                                            gindex == 0 &&
                                            index3 == 0
                                          "
                                          :rowSpan="dcount(dimension.dimensionCategories)"
                                        >
                                          <div style="position: absolute; top: 15px">
                                            <div style="text-align: left">
                                              {{ dimension.group }}
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          :class="[true ? 'dm3position' : '']"
                                          style="background: white; padding: 15px"
                                          class="
                                            floater_table__summary_weekly
                                            floater_table__keyword__1categorytitle
                                          "
                                          :rowSpan="cells.pairent.length - (isreoffsetinclude(group.cellRows) ? 1 : 0)"
                                          v-if="index3 == 0 && isShowSummaryColum(1, 'dimension3')"
                                        >
                                          <div style="position: absolute; top: 15px">
                                            <div style="text-align: left">
                                              {{ group.categoryCell.getString('consultant') }}
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                          v-if="index3 == 0"
                                          :rowspan="cccline01_row(group.cellRows)"
                                          style="border-left: 2px solid #cfcfcf"
                                        >
                                          <div class="in2line">
                                            <div class="in2line__up">
                                              <div class="in2line__singleline">
                                                <div class="in2line__title">実績</div>
                                                <div class="in2line__value">
                                                  {{
                                                    getcellFindGroupcellBykey(
                                                      '実績',
                                                      page.getMainCostCvCpaBackendKey('COST'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  }}
                                                </div>
                                                <div v-if="false" class="in2line__unit">円</div>
                                              </div>
                                            </div>
                                            <div class="in2line__down">
                                              <div class="in2line__singleline">
                                                <div class="in2line__title">目標</div>
                                                <div class="in2line__value">
                                                  {{
                                                    getcellFindGroupcellBykey(
                                                      '目標',
                                                      page.getMainCostCvCpaBackendKey('COST'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  }}
                                                </div>
                                                <div v-if="false" class="in2line__unit">円</div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                          v-if="index3 == 0"
                                          :rowspan="cccline01_row(group.cellRows)"
                                        >
                                          <div class="in2line">
                                            <div class="in2line__up">
                                              <div class="in2line__singleline">
                                                <div class="in2line__title">実績</div>
                                                <div class="in2line__value">
                                                  {{
                                                    getcellFindGroupcellBykey(
                                                      '実績',
                                                      page.getMainCostCvCpaBackendKey('CV'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  }}
                                                </div>
                                                <div v-if="false" class="in2line__unit">円</div>
                                              </div>
                                            </div>
                                            <div class="in2line__down">
                                              <div class="in2line__singleline">
                                                <div class="in2line__title">目標</div>
                                                <div class="in2line__value">
                                                  {{
                                                    getcellFindGroupcellBykey(
                                                      '目標',
                                                      page.getMainCostCvCpaBackendKey('CV'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  }}
                                                </div>
                                                <div v-if="false" class="in2line__unit">円</div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                          v-if="index3 == 0"
                                          :rowspan="cccline01_row(group.cellRows)"
                                          style="border-right: 2px solid #cfcfcf"
                                        >
                                          <div class="in2line">
                                            <div class="in2line__up">
                                              <div class="in2line__singleline">
                                                <div class="in2line__title">実績</div>
                                                <div class="in2line__value">
                                                  {{
                                                    getcellFindGroupcellBykey(
                                                      '実績',
                                                      page.getMainCostCvCpaBackendKey('CPA'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  }}
                                                </div>
                                                <div v-if="false" class="in2line__unit">円</div>
                                              </div>
                                            </div>
                                            <div class="in2line__down">
                                              <div class="in2line__singleline">
                                                <div class="in2line__title">目標</div>
                                                <div class="in2line__value">
                                                  {{
                                                    getcellFindGroupcellBykey(
                                                      '目標',
                                                      page.getMainCostCvCpaBackendKey('CPA'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  }}
                                                </div>
                                                <div v-if="false" class="in2line__unit">円</div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td
                                          style="border-left: 2px solid #cfcfcf"
                                          class="floater_table__in2line copyremovecell"
                                          v-if="cccline02_show(index3)"
                                          :rowspan="cccline02_row()"
                                        >
                                          <div class="pcheckline">
                                            <div class="pcheckline__arrow">
                                              <svg
                                                v-bind:class="[
                                                  ratescaleArrawClass(
                                                    'cost',
                                                    getcellValueFindGroupcellBykey(
                                                      '想定乖離率',
                                                      page.getMainCostCvCpaBackendKey('COST'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  ),
                                                ]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11.268 13.002"
                                              >
                                                <path
                                                  id="合体_22"
                                                  data-name="合体 22"
                                                  d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                  transform="translate(-10732.001 -12773.001)"
                                                />
                                              </svg>
                                            </div>
                                            <div class="pcheckline__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '想定乖離率',
                                                  page.getMainCostCvCpaBackendKey('COST'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    group.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div class="pcheckline__unit"></div>
                                          </div>
                                        </td>
                                        <td
                                          class="floater_table__in2line copyremovecell"
                                          v-if="cccline02_show(index3)"
                                          :rowspan="cccline02_row()"
                                        >
                                          <div class="pcheckline">
                                            <div class="pcheckline__arrow">
                                              <svg
                                                v-bind:class="[
                                                  ratescaleArrawClass(
                                                    'cv',

                                                    getcellValueFindGroupcellBykey(
                                                      '想定乖離率',
                                                      page.getMainCostCvCpaBackendKey('CV'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  ),
                                                ]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11.268 13.002"
                                              >
                                                <path
                                                  id="合体_22"
                                                  data-name="合体 22"
                                                  d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                  transform="translate(-10732.001 -12773.001)"
                                                />
                                              </svg>
                                            </div>
                                            <div class="pcheckline__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '想定乖離率',
                                                  page.getMainCostCvCpaBackendKey('CV'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    group.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div class="pcheckline__unit"></div>
                                          </div>
                                        </td>
                                        <td
                                          class="floater_table__in2line copyremovecell"
                                          v-if="cccline02_show(index3)"
                                          :rowspan="cccline02_row()"
                                          style="border-right: 2px solid #cfcfcf"
                                        >
                                          <div class="pcheckline">
                                            <div class="pcheckline__arrow">
                                              <svg
                                                v-bind:class="[
                                                  ratescaleArrawClass(
                                                    'cpa',

                                                    getcellValueFindGroupcellBykey(
                                                      '想定乖離率',
                                                      page.getMainCostCvCpaBackendKey('CPA'),
                                                      page.dimensionHolder.generateProviderCategoryRows(
                                                        group.cellRows
                                                      ),
                                                      dimensionOrdered.structureHolder.structure
                                                        .chart
                                                    )
                                                  ),
                                                ]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11.268 13.002"
                                              >
                                                <path
                                                  id="合体_22"
                                                  data-name="合体 22"
                                                  d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                  transform="translate(-10732.001 -12773.001)"
                                                />
                                              </svg>
                                            </div>
                                            <div class="pcheckline__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '想定乖離率',
                                                  page.getMainCostCvCpaBackendKey('CPA'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    group.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div class="pcheckline__unit"></div>
                                          </div>
                                        </td>

                                        <template v-for="(cell, index4) in cells" :key="index4">
                                          <td
                                            v-if="isShowSummaryColum(index4 + 3, 'dimension3')"
                                            v-bind:class="[
                                              index3 == 0 ? 'consultant_record__trmark_' : '',
                                              index3 == 3 ? 'consultant_record__trmark2' : '',
                                            ]"
                                            class="floater_table__summary_weekly"
                                            style="padding: 0px; position: relative"
                                            :set="
                                              (cellDesign =
                                                page.dimensionHolder.getProviderCategoryReportCellDesign(
                                                  cell.data,
                                                  pdimension.dimensionCategoryGroups,
                                                  group.cellRows,
                                                  index3,
                                                  index4
                                                ))
                                            "
                                          >
                                            <div
                                              v-bind:style="{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                padding:
                                                  index3 == 2 ? '4px' : index3 == 3 ? '0px' : '',
                                                paddingLeft: '0px',
                                              }"
                                            >
                                              <div
                                                v-if="index3 < 3"
                                                style="height: 100%; width: "
                                                v-bind:style="{
                                                  width: cellDesign.isColored
                                                    ? cellDesign.percent + '%'
                                                    : '0%',
                                                  background: cellDesign.isColored
                                                    ? cellDesign.color
                                                    : '#FFFFFF',
                                                  border:
                                                    cellDesign.border != null
                                                      ? cellDesign.border
                                                      : '',
                                                }"
                                              ></div>
                                            </div>
                                            <div
                                              style="
                                                padding: 8px;
                                                position: relative;
                                                top: 0;
                                                left: 0;
                                                right: 0;
                                                bottom: 0;
                                              "
                                              :class="[
                                                index4 == 0 ? 'floater_table__titlecell' : '',
                                              ]"
                                            >
                                              <div
                                                :class="[index4 == 0 ? 'font_smaller' : '']"
                                                v-if="index3 != 3"
                                              >
                                                {{ cell.getString('consultant') }}
                                              </div>
                                              <div
                                                :class="[index4 == 0 ? 'font_smaller' : '']"
                                                v-if="index3 == 3"
                                                style="margin-left: auto; position: relative"
                                                :style="[
                                                  index4 != 0 ? 'width: fit-content;' : '',
                                                  cellDesign.cellclass != null
                                                    ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px;'
                                                    : '',
                                                ]"
                                              >
                                                <svg
                                                  v-if="cellDesign.cellclass != null"
                                                  v-bind:class="cellDesign.cellclass"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 11.268 13.002"
                                                >
                                                  <path
                                                    id="合体_22"
                                                    data-name="合体 22"
                                                    d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                    transform="translate(-10732.001 -12773.001)"
                                                  />
                                                </svg>

                                                {{ titlewrapper(cell.getString('consultant')) }}
                                              </div>
                                            </div>
                                          </td>
                                        </template>
                                      </tr>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div v-if="dimensionOrdered.count() == 2">
                      <div class="frame__body scroll_bar">
                        <div
                          :style="
                            summarytalbewidth('d2', dimensionOrdered.getHeaderCell(), 'dimension2')
                          "
                        >
                          <table
                            id="d2_table_tag"
                            class="
                              floater_table floater_table--full
                              provider_category_table
                              expansion_table
                            "
                          >
                            <thead id="dm_header_position" class="floater_table__header expansion_table__header" style="position: relative;">
                              <tr
                                v-if="
                                  isCells2SpanCells(dimensionOrdered.getHeaderCell(), 'dimension2')
                                "
                              >
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <template
                                  v-for="(item, ih) in cells2SpanCells(
                                    dimensionOrdered.getHeaderCell(),
                                    'dimension2'
                                  )"
                                  :key="ih"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>

                              <tr>
                                <template
                                  v-for="(cell, index2) in dimensionOrdered.getHeaderCell()"
                                  :key="index2"
                                >
                                  <th
                                    class="floater_table__in2line copyremovecell"
                                    v-if="index2 == 2"
                                    style="border-left: 2px solid #cfcfcf"
                                  >
                                    COST
                                  </th>
                                  <th class="floater_table__in2line copyremovecell" v-if="index2 == 2">CV</th>
                                  <th
                                    class="floater_table__in2line copyremovecell"
                                    v-if="index2 == 2"
                                    style="border-right: 2px solid #cfcfcf"
                                  >
                                    {{page.getMainCpaLabel()}}
                                  </th>
                                  <th
                                    ref="d2_header_ref"
                                    :id="'d2_header_id_' + index2"
                                    style="position: relative; cursor: pointer"
                                    v-on:click.stop="showShowHide($event, index2, 'dimension2')"
                                    class="floater_table__summary_weekly expansion_table__th"
                                    v-if="isShowSummaryColum(index2, 'dimension2')"
                                    :style="[teststyle('d2', index2)]"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == index2 && expType == 'd2'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('d2', index2, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div style="padding-top: 4px; padding-bottom: 4px; box-sizing: border-box;">{{ spanTilte2HeaderTitle(cell.getString('consultant')) }}</div>
                                    <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 0) || (dmCt1label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt1label }}</div></div>
                                    <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 1) || (dmCt2label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt2label }}</div></div>
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody class="floater_table__body floater_table__body--in2line">
                              <template
                                v-for="(dimension, index2) in dimensionOrdered.dimensions"
                                :key="index2"
                              >
                                <template
                                  v-for="(group, gindex) in dctmpsplice(
                                    dimension.dimensionCategories
                                  )"
                                  :key="gindex"
                                >
                                  <template
                                    v-for="(
                                      cells, index3
                                    ) in page.dimensionHolder.generateProviderCategoryRows(
                                      group.cellRows
                                    )"
                                    :key="index3"
                                  >
                                    <tr
                                      v-if="cells.length > 0 && !isreoffset(cells)"
                                      v-bind:class="[
                                        group.cellRows.length == 1 ? 'tr_one_line' : '',
                                        index3 == 0 ? 'consultant_record__trmark' : '',
                                      ]"
                                    >
                                      <td
                                        :class="[true ? 'dm1position' : '']"
                                        style="background: white; padding: 15px"
                                        class="
                                          floater_table__summary_weekly
                                          floater_table__keyword__1categorytitle
                                        "
                                        v-if="
                                          gindex == 0 &&
                                          index3 == 0 &&
                                          isShowSummaryColum(0, 'dimension2')
                                        "
                                        :rowSpan="dcount(dimension.dimensionCategories)"
                                      >
                                        <div style="position: absolute; top: 15px">
                                          <div style="text-align: left">
                                            {{ dimension.group }}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        :class="[true ? 'dm2position' : '']"
                                        style="background: white; padding: 15px"
                                        class="
                                          floater_table__summary_weekly
                                          floater_table__keyword__1categorytitle
                                        "
                                        :rowSpan="cells.pairent.length - (isreoffsetinclude(group.cellRows) ? 1 : 0)"
                                        v-if="index3 == 0 && isShowSummaryColum(1, 'dimension2')"
                                      >
                                        <div style="position: absolute; top: 15px">
                                          <div style="text-align: left">
                                            {{ group.categoryCell.getString('consultant') }}
                                          </div>
                                        </div>
                                      </td>

                                      <td
                                        class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                        v-if="index3 == 0"
                                        :rowspan="cccline01_row(group.cellRows)"
                                        style="border-left: 2px solid #cfcfcf"
                                      >
                                        <div class="in2line">
                                          <div class="in2line__up">
                                            <div class="in2line__singleline">
                                              <div class="in2line__title">実績</div>
                                              <div class="in2line__value">
                                                {{
                                                  getcellFindGroupcellBykey(
                                                    '実績',
                                                    page.getMainCostCvCpaBackendKey('COST'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                }}
                                              </div>
                                              <div v-if="false" class="in2line__unit">円</div>
                                            </div>
                                          </div>
                                          <div class="in2line__down">
                                            <div class="in2line__singleline">
                                              <div class="in2line__title">目標</div>
                                              <div class="in2line__value">
                                                {{
                                                  getcellFindGroupcellBykey(
                                                    '目標',
                                                    page.getMainCostCvCpaBackendKey('COST'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                }}
                                              </div>
                                              <div v-if="false" class="in2line__unit">円</div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                        v-if="index3 == 0"
                                        :rowspan="cccline01_row(group.cellRows)"
                                      >
                                        <div class="in2line">
                                          <div class="in2line__up">
                                            <div class="in2line__singleline">
                                              <div class="in2line__title">実績</div>
                                              <div class="in2line__value">
                                                {{
                                                  getcellFindGroupcellBykey(
                                                    '実績',
                                                    page.getMainCostCvCpaBackendKey('CV'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                }}
                                              </div>
                                              <div v-if="false" class="in2line__unit">円</div>
                                            </div>
                                          </div>
                                          <div class="in2line__down">
                                            <div class="in2line__singleline">
                                              <div class="in2line__title">目標</div>
                                              <div class="in2line__value">
                                                {{
                                                  getcellFindGroupcellBykey(
                                                    '目標',
                                                    page.getMainCostCvCpaBackendKey('CV'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                }}
                                              </div>
                                              <div v-if="false" class="in2line__unit">円</div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                        v-if="index3 == 0"
                                        :rowspan="cccline01_row(group.cellRows)"
                                        style="border-right: 2px solid #cfcfcf"
                                      >
                                        <div class="in2line">
                                          <div class="in2line__up">
                                            <div class="in2line__singleline">
                                              <div class="in2line__title">実績</div>
                                              <div class="in2line__value">
                                                {{
                                                  getcellFindGroupcellBykey(
                                                    '実績',
                                                    page.getMainCostCvCpaBackendKey('CPA'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                }}
                                              </div>
                                              <div v-if="false" class="in2line__unit">円</div>
                                            </div>
                                          </div>
                                          <div class="in2line__down">
                                            <div class="in2line__singleline">
                                              <div class="in2line__title">目標</div>
                                              <div class="in2line__value">
                                                {{
                                                  getcellFindGroupcellBykey(
                                                    '目標',
                                                    page.getMainCostCvCpaBackendKey('CPA'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                }}
                                              </div>
                                              <div v-if="false" class="in2line__unit">円</div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style="border-left: 2px solid #cfcfcf"
                                        class="floater_table__in2line copyremovecell"
                                        v-if="cccline02_show(index3)"
                                        :rowspan="cccline02_row()"
                                      >
                                        <div class="pcheckline">
                                          <div class="pcheckline__arrow">
                                            <svg
                                              v-bind:class="[
                                                ratescaleArrawClass(
                                                  'cost',
                                                  getcellValueFindGroupcellBykey(
                                                    '想定乖離率',
                                                    page.getMainCostCvCpaBackendKey('COST'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                ),
                                              ]"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 11.268 13.002"
                                            >
                                              <path
                                                id="合体_22"
                                                data-name="合体 22"
                                                d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                transform="translate(-10732.001 -12773.001)"
                                              />
                                            </svg>
                                          </div>
                                          <div class="pcheckline__value">
                                            {{
                                              getcellFindGroupcellBykey(
                                                '想定乖離率',
                                                page.getMainCostCvCpaBackendKey('COST'),
                                                page.dimensionHolder.generateProviderCategoryRows(
                                                  group.cellRows
                                                ),
                                                dimensionOrdered.structureHolder.structure.chart
                                              )
                                            }}
                                          </div>
                                          <div class="pcheckline__unit"></div>
                                        </div>
                                      </td>
                                      <td
                                        class="floater_table__in2line copyremovecell"
                                        v-if="cccline02_show(index3)"
                                        :rowspan="cccline02_row()"
                                      >
                                        <div class="pcheckline">
                                          <div class="pcheckline__arrow">
                                            <svg
                                              v-bind:class="[
                                                ratescaleArrawClass(
                                                  'cv',

                                                  getcellValueFindGroupcellBykey(
                                                    '想定乖離率',
                                                    page.getMainCostCvCpaBackendKey('CV'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                ),
                                              ]"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 11.268 13.002"
                                            >
                                              <path
                                                id="合体_22"
                                                data-name="合体 22"
                                                d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                transform="translate(-10732.001 -12773.001)"
                                              />
                                            </svg>
                                          </div>
                                          <div class="pcheckline__value">
                                            {{
                                              getcellFindGroupcellBykey(
                                                '想定乖離率',
                                                page.getMainCostCvCpaBackendKey('CV'),
                                                page.dimensionHolder.generateProviderCategoryRows(
                                                  group.cellRows
                                                ),
                                                dimensionOrdered.structureHolder.structure.chart
                                              )
                                            }}
                                          </div>
                                          <div class="pcheckline__unit"></div>
                                        </div>
                                      </td>
                                      <td
                                        class="floater_table__in2line copyremovecell"
                                        v-if="cccline02_show(index3)"
                                        :rowspan="cccline02_row()"
                                        style="border-right: 2px solid #cfcfcf"
                                      >
                                        <div class="pcheckline">
                                          <div class="pcheckline__arrow">
                                            <svg
                                              v-bind:class="[
                                                ratescaleArrawClass(
                                                  'cpa',

                                                  getcellValueFindGroupcellBykey(
                                                    '想定乖離率',
                                                    page.getMainCostCvCpaBackendKey('CPA'),
                                                    page.dimensionHolder.generateProviderCategoryRows(
                                                      group.cellRows
                                                    ),
                                                    dimensionOrdered.structureHolder.structure.chart
                                                  )
                                                ),
                                              ]"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 11.268 13.002"
                                            >
                                              <path
                                                id="合体_22"
                                                data-name="合体 22"
                                                d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                transform="translate(-10732.001 -12773.001)"
                                              />
                                            </svg>
                                          </div>
                                          <div class="pcheckline__value">
                                            {{
                                              getcellFindGroupcellBykey(
                                                '想定乖離率',
                                                page.getMainCostCvCpaBackendKey('CPA'),
                                                page.dimensionHolder.generateProviderCategoryRows(
                                                  group.cellRows
                                                ),
                                                dimensionOrdered.structureHolder.structure.chart
                                              )
                                            }}
                                          </div>
                                          <div class="pcheckline__unit"></div>
                                        </div>
                                      </td>

                                      <template v-for="(cell, index4) in cells" :key="index4">
                                        <td
                                          v-if="isShowSummaryColum(index4 + 2, 'dimension2')"
                                          v-bind:class="[
                                            index3 == 0 ? 'consultant_record__trmark_' : '',
                                            index3 == 3 ? 'consultant_record__trmark2' : '',
                                          ]"
                                          class="floater_table__summary_weekly"
                                          style="padding: 0px; position: relative"
                                          :set="
                                            (cellDesign =
                                              page.dimensionHolder.getProviderCategoryReportCellDesign(
                                                cell.data,
                                                dimensionOrdered.dimensions,
                                                group.cellRows,
                                                index3,
                                                index4
                                              ))
                                          "
                                        >
                                          <div
                                            v-bind:style="{
                                              position: 'absolute',
                                              top: 0,
                                              left: 0,
                                              right: 0,
                                              bottom: 0,
                                              padding:
                                                index3 == 2 ? '4px' : index3 == 3 ? '0px' : '',
                                              paddingLeft: '0px',
                                            }"
                                          >
                                            <div
                                              v-if="index3 < 3"
                                              style="height: 100%; width: "
                                              v-bind:style="{
                                                width: cellDesign.isColored
                                                  ? cellDesign.percent + '%'
                                                  : '0%',
                                                background: cellDesign.isColored
                                                  ? cellDesign.color
                                                  : '#FFFFFF',
                                                border:
                                                  cellDesign.border != null
                                                    ? cellDesign.border
                                                    : '',
                                              }"
                                            ></div>
                                          </div>
                                          <div
                                            style="
                                              padding: 8px;
                                              position: relative;
                                              top: 0;
                                              left: 0;
                                              right: 0;
                                              bottom: 0;
                                            "
                                            :class="[index4 == 0 ? 'floater_table__titlecell' : '']"
                                          >
                                            <div
                                              :class="[index4 == 0 ? 'font_smaller' : '']"
                                              v-if="index3 != 3"
                                            >
                                              {{ cell.getString('consultant') }}
                                            </div>
                                            <div
                                              :class="[index4 == 0 ? 'font_smaller' : '']"
                                              v-if="index3 == 3"
                                              style="margin-left: auto; position: relative"
                                              :style="[
                                                index4 != 0 ? 'width: fit-content;' : '',
                                                cellDesign.cellclass != null
                                                  ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px;'
                                                  : '',
                                              ]"
                                            >
                                              <svg
                                                v-if="cellDesign.cellclass != null"
                                                v-bind:class="cellDesign.cellclass"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11.268 13.002"
                                              >
                                                <path
                                                  id="合体_22"
                                                  data-name="合体 22"
                                                  d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                  transform="translate(-10732.001 -12773.001)"
                                                />
                                              </svg>

                                              {{ titlewrapper(cell.getString('consultant')) }}
                                            </div>
                                          </div>
                                        </td>
                                      </template>
                                    </tr>
                                  </template>
                                </template>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div v-if="dimensionOrdered.count() == 1">
                      <div class="frame__body scroll_bar">
                        <div
                          :style="
                            summarytalbewidth('d1', dimensionOrdered.getHeaderCell(), 'dimension1')
                          "
                        >
                          <table
                            id="d1_table_tag"
                            class="
                              floater_table floater_table--full
                              provider_category_table
                              expansion_table
                            "
                          >
                            <thead id="dm_header_position" class="floater_table__header expansion_table__header" style="position: relative;">
                              <tr
                                v-if="
                                  isCells2SpanCells(dimensionOrdered.getHeaderCell(), 'dimension1')
                                "
                              >
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <th style="background: white; border: initial" class="copyremovecell"></th>
                                <template
                                  v-for="(item, ih) in cells2SpanCells(
                                    dimensionOrdered.getHeaderCell(),
                                    'dimension1'
                                  )"
                                  :key="ih"
                                >
                                  <th
                                    style="background: white; border: initial"
                                    v-if="item.spanCount() == 1 && !is2SpanCell(item)"
                                  ></th>
                                  <th v-if="item.spanCount() == 1 && is2SpanCell(item)">
                                    {{ item.getTitle() }}
                                  </th>
                                  <th v-if="item.spanCount() > 1" :colspan="item.spanCount()">
                                    {{ item.getTitle() }}
                                  </th>
                                </template>
                              </tr>

                              <tr>
                                <template
                                  v-for="(cell, index2) in dimensionOrdered.getHeaderCell()"
                                  :key="index2"
                                >
                                  <th class="floater_table__in2line copyremovecell" v-if="index2 == 1">COST</th>
                                  <th class="floater_table__in2line copyremovecell" v-if="index2 == 1">CV</th>
                                  <th class="floater_table__in2line copyremovecell" v-if="index2 == 1">{{page.getMainCpaLabel()}}</th>

                                  <th
                                    ref="d1_header_ref"
                                    :id="'d1_header_id_' + index2"
                                    style="position: relative; cursor: pointer"
                                    v-if="isShowSummaryColum(index2, 'dimension1')"
                                    v-on:click.stop="showShowHide($event, index2, 'dimension1')"
                                    class="floater_table__summary_weekly expansion_table__th"
                                    :style="[teststyle('d1', index2)]"
                                  >
                                    <div
                                      class="expansion_table__dragmarker"
                                      :class="[
                                        expMarkerId == index2 && expType == 'd1'
                                          ? 'expansion_table__dragmarker--active'
                                          : '',
                                      ]"
                                      v-on:click.stop=""
                                      v-on:mousedown.stop="dragstart('d1', index2, $event)"
                                    >
                                      <div class="expansion_table__dragmarker__arrow">
                                        <div class="expansion_table__dragmarker__lrarrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="18"
                                            viewBox="0 0 16 18"
                                            transform="rotate(90)"
                                          >
                                            <g
                                              id="g484"
                                              data-name="g484"
                                              transform="translate(-8 -123)"
                                            >
                                              <path
                                                id="p530"
                                                data-name="p530"
                                                d="M13,6.99h3L12,3,8,6.99h3V17.01H8L12,21l4-3.99H13Z"
                                                transform="translate(4 120)"
                                                fill="#fff"
                                              />
                                              <rect
                                                id="s_949"
                                                data-name="s949"
                                                width="16"
                                                height="2"
                                                transform="translate(8 131)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div style="padding-top: 4px; padding-bottom: 4px; box-sizing: border-box;">{{ spanTilte2HeaderTitle(cell.getString('consultant')) }}</div>
                                    <div style="height: 20px; padding-bottom: 8px; box-sizing: border-box;" v-bind:style="{display: ((index2 != 0) || (dmCt1label.length == 0)) ? 'none' : ''}" ><div style="width: 100%; position: absolute; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 12px; padding-left: 8px; padding-right: 8px">{{ dmCt1label }}</div></div>
                                  </th>
                                </template>
                              </tr>
                            </thead>
                            <tbody class="floater_table__body floater_table__body--in2line">
                              <template
                                v-for="(dimension, index2) in dimensionOrdered.dimensions"
                                :key="index2"
                              >
                                <template
                                  v-for="(
                                    cells, index3
                                  ) in page.dimensionHolder.generateProviderCategoryRows(
                                    dimension.cellRows
                                  )"
                                  :key="index3"
                                >
                                  <tr
                                    v-if="cells.length > 0 && !isreoffset(cells)"
                                    v-bind:class="[
                                      dimension.cellRows.length == 1 ? 'tr_one_line' : '',
                                      index3 == 0 ? 'consultant_record__trmark' : '',
                                    ]"
                                  >
                                    <td
                                      :class="[true ? 'dm1position' : '']"
                                      style="background: white; padding: 15px"
                                      class="
                                        floater_table__summary_weekly
                                        floater_table__keyword__1categorytitle
                                      "
                                      :rowSpan="cells.pairent.length - (isreoffsetinclude(dimension.cellRows) ? 1 : 0)"
                                      v-if="index3 == 0 && isShowSummaryColum(0, 'dimension1')"
                                    >
                                      <div style="position: absolute; top: 15px">
                                        <div style="text-align: left">
                                          {{ dimension.categoryCell.getString('consultant') }}
                                        </div>
                                      </div>
                                    </td>

                                    <td
                                      class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                      v-if="index3 == 0"
                                      :rowspan="cccline01_row(dimension.cellRows)"
                                    >
                                      <div class="in2line">
                                        <div class="in2line__up">
                                          <div class="in2line__singleline">
                                            <div class="in2line__title">実績</div>
                                            <div class="in2line__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '実績',
                                                  page.getMainCostCvCpaBackendKey('COST'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div v-if="false" class="in2line__unit">円</div>
                                          </div>
                                        </div>
                                        <div class="in2line__down">
                                          <div class="in2line__singleline">
                                            <div class="in2line__title">目標</div>
                                            <div class="in2line__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '目標',
                                                  page.getMainCostCvCpaBackendKey('COST'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div v-if="false" class="in2line__unit">円</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                      v-if="index3 == 0"
                                      :rowspan="cccline01_row(dimension.cellRows)"
                                    >
                                      <div class="in2line">
                                        <div class="in2line__up">
                                          <div class="in2line__singleline">
                                            <div class="in2line__title">実績</div>
                                            <div class="in2line__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '実績',
                                                  page.getMainCostCvCpaBackendKey('CV'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div v-if="false" class="in2line__unit">円</div>
                                          </div>
                                        </div>
                                        <div class="in2line__down">
                                          <div class="in2line__singleline">
                                            <div class="in2line__title">目標</div>
                                            <div class="in2line__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '目標',
                                                  page.getMainCostCvCpaBackendKey('CV'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div v-if="false" class="in2line__unit">円</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      class="floater_table__in2line floater_table__in2line-data copyremovecell"
                                      v-if="index3 == 0"
                                      :rowspan="cccline01_row(dimension.cellRows)"
                                    >
                                      <div class="in2line">
                                        <div class="in2line__up">
                                          <div class="in2line__singleline">
                                            <div class="in2line__title">実績</div>
                                            <div class="in2line__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '実績',
                                                  page.getMainCostCvCpaBackendKey('CPA'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div v-if="false" class="in2line__unit">円</div>
                                          </div>
                                        </div>
                                        <div class="in2line__down">
                                          <div class="in2line__singleline">
                                            <div class="in2line__title">目標</div>
                                            <div class="in2line__value">
                                              {{
                                                getcellFindGroupcellBykey(
                                                  '目標',
                                                  page.getMainCostCvCpaBackendKey('CPA'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              }}
                                            </div>
                                            <div v-if="false" class="in2line__unit">円</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      class="floater_table__in2line copyremovecell"
                                      v-if="cccline02_show(index3)"
                                      :rowspan="cccline02_row()"
                                    >
                                      <div class="pcheckline">
                                        <div class="pcheckline__arrow">
                                          <svg
                                            v-bind:class="[
                                              ratescaleArrawClass(
                                                'cost',
                                                getcellValueFindGroupcellBykey(
                                                  '想定乖離率',
                                                  page.getMainCostCvCpaBackendKey('COST'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              ),
                                            ]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.268 13.002"
                                          >
                                            <path
                                              id="合体_22"
                                              data-name="合体 22"
                                              d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                              transform="translate(-10732.001 -12773.001)"
                                            />
                                          </svg>
                                        </div>
                                        <div class="pcheckline__value">
                                          {{
                                            getcellFindGroupcellBykey(
                                              '想定乖離率',
                                              page.getMainCostCvCpaBackendKey('COST'),
                                              page.dimensionHolder.generateProviderCategoryRows(
                                                dimension.cellRows
                                              ),
                                              dimensionOrdered.structureHolder.structure.chart
                                            )
                                          }}
                                        </div>
                                        <div class="pcheckline__unit"></div>
                                      </div>
                                    </td>
                                    <td
                                      class="floater_table__in2line copyremovecell"
                                      v-if="cccline02_show(index3)"
                                      :rowspan="cccline02_row()"
                                    >
                                      <div class="pcheckline">
                                        <div class="pcheckline__arrow">
                                          <svg
                                            v-bind:class="[
                                              ratescaleArrawClass(
                                                'cv',

                                                getcellValueFindGroupcellBykey(
                                                  '想定乖離率',
                                                  page.getMainCostCvCpaBackendKey('CV'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              ),
                                            ]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.268 13.002"
                                          >
                                            <path
                                              id="合体_22"
                                              data-name="合体 22"
                                              d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                              transform="translate(-10732.001 -12773.001)"
                                            />
                                          </svg>
                                        </div>
                                        <div class="pcheckline__value">
                                          {{
                                            getcellFindGroupcellBykey(
                                              '想定乖離率',
                                              page.getMainCostCvCpaBackendKey('CV'),
                                              page.dimensionHolder.generateProviderCategoryRows(
                                                dimension.cellRows
                                              ),
                                              dimensionOrdered.structureHolder.structure.chart
                                            )
                                          }}
                                        </div>
                                        <div class="pcheckline__unit"></div>
                                      </div>
                                    </td>
                                    <td
                                      class="floater_table__in2line copyremovecell"
                                      v-if="cccline02_show(index3)"
                                      :rowspan="cccline02_row()"
                                    >
                                      <div class="pcheckline">
                                        <div class="pcheckline__arrow">
                                          <svg
                                            v-bind:class="[
                                              ratescaleArrawClass(
                                                'cpa',

                                                getcellValueFindGroupcellBykey(
                                                  '想定乖離率',
                                                  page.getMainCostCvCpaBackendKey('CPA'),
                                                  page.dimensionHolder.generateProviderCategoryRows(
                                                    dimension.cellRows
                                                  ),
                                                  dimensionOrdered.structureHolder.structure.chart
                                                )
                                              ),
                                            ]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 11.268 13.002"
                                          >
                                            <path
                                              id="合体_22"
                                              data-name="合体 22"
                                              d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                              transform="translate(-10732.001 -12773.001)"
                                            />
                                          </svg>
                                        </div>
                                        <div class="pcheckline__value">
                                          {{
                                            getcellFindGroupcellBykey(
                                              '想定乖離率',
                                              page.getMainCostCvCpaBackendKey('CPA'),
                                              page.dimensionHolder.generateProviderCategoryRows(
                                                dimension.cellRows
                                              ),
                                              dimensionOrdered.structureHolder.structure.chart
                                            )
                                          }}
                                        </div>
                                        <div class="pcheckline__unit"></div>
                                      </div>
                                    </td>

                                    <template v-for="(cell, index4) in cells" :key="index4">
                                      <td
                                        v-if="isShowSummaryColum(index4 + 1, 'dimension1')"
                                        v-bind:class="[
                                          index3 == 0 ? 'consultant_record__trmark_' : '',
                                          index3 == 3 ? 'consultant_record__trmark2' : '',
                                        ]"
                                        class="floater_table__summary_weekly"
                                        style="padding: 0px; position: relative"
                                        :set="
                                          (cellDesign =
                                            page.dimensionHolder.getProviderReportCellDesign(
                                              cell.data,
                                              dimensionOrdered.dimensions,
                                              dimension.cellRows,
                                              index3,
                                              index4
                                            ))
                                        "
                                      >
                                        <div
                                          v-bind:style="{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            padding: index3 == 2 ? '4px' : index3 == 3 ? '0px' : '',
                                            paddingLeft: '0px',
                                          }"
                                        >
                                          <div
                                            v-if="index3 < 3"
                                            style="height: 100%; width: "
                                            v-bind:style="{
                                              width: cellDesign.isColored
                                                ? cellDesign.percent + '%'
                                                : '0%',
                                              background: cellDesign.isColored
                                                ? cellDesign.color
                                                : '#FFFFFF',
                                              border:
                                                cellDesign.border != null ? cellDesign.border : '',
                                            }"
                                          ></div>
                                        </div>
                                        <div
                                          style="
                                            padding: 8px;
                                            position: relative;
                                            top: 0;
                                            left: 0;
                                            right: 0;
                                            bottom: 0;
                                          "
                                          :class="[index4 == 0 ? 'floater_table__titlecell' : '']"
                                        >
                                          <div
                                            :class="[index4 == 0 ? 'font_smaller' : '']"
                                            v-if="index3 != 3"
                                          >
                                            {{ cell.getString('consultant') }}
                                          </div>
                                          <div
                                            :class="[index4 == 0 ? 'font_smaller' : '']"
                                            v-if="index3 == 3"
                                            style="margin-left: auto; position: relative"
                                            :style="[
                                              index4 != 0 ? 'width: fit-content;' : '',
                                              cellDesign.cellclass != null
                                                ? 'font: normal normal 900 15px/15px Noto Sans JP; top: -4px;'
                                                : '',
                                            ]"
                                          >
                                            <svg
                                              v-if="cellDesign.cellclass != null"
                                              v-bind:class="cellDesign.cellclass"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 11.268 13.002"
                                            >
                                              <path
                                                id="合体_22"
                                                data-name="合体 22"
                                                d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                                transform="translate(-10732.001 -12773.001)"
                                              />
                                            </svg>

                                            {{ titlewrapper(cell.getString('consultant')) }}
                                          </div>
                                        </div>
                                      </td>
                                    </template>
                                  </tr>
                                </template>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="box" v-if="false">
              <div class="frame" style="margin-right: 10px">
                <div class="box">
                  <div style="width: 100%; position: relative">
                    <div class="frame__header">
                      週次表
                      <div style="float: right">
                        <div
                          v-on:mouseover="mouseOverWeekly"
                          v-on:mouseleave="mouseLeaveWeekly"
                          v-on:click="toggleWeeklyAlert"
                          class="small_circle small_circle--danger"
                          v-if="page.weeklyTable.isAlert()"
                        >
                          <div
                            style="
                              margin-top: 1px;
                              margin-left: 3px;
                              font-size: 15px;
                              text-align: center;
                              color: #fff;
                              font-weight: bold;
                            "
                          >
                            !
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="frame__body">
                      <Spinner v-if="page.isWeeklyResultNetworking" />
                      <div
                        class="nodataline"
                        v-if="!page.isWeeklyResultNetworking && page.isWeeklyResultNoData"
                      >
                        NoData
                      </div>
                      <table
                        v-if="!page.isWeeklyResultNetworking && !page.isWeeklyResultNoData"
                        class="floater_table floater_table--full"
                      >
                        <thead class="floater_table__header">
                          <tr style="height: 41px" v-show="page.weeklyTable.cellRows.length > 0">
                            <template
                              v-for="(item, index) in page.weeklyTable.getHeaderCell('週')"
                              :key="index"
                            >
                              <th
                                v-bind:class="[index == 0 ? 'floater_table__titlecell' : '']"
                                class="floater_table__summary_weekly"
                              >
                                {{ item.getString() }}
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody class="floater_table__body">
                          <template
                            v-for="(cellColms, index) in page.weeklyTable.cellRows"
                            :key="index"
                          >
                            <tr style="height: 41px">
                              <template v-for="(cell, cindex) in cellColms.cells" :key="cindex">
                                <td
                                  class="floater_table__summary_weekly"
                                  v-bind:class="[
                                    cellColms.isAlert == true || cellColms.isAlert == 'true'
                                      ? 'floater_table__summary_weekly--alert'
                                      : '',
                                    cindex == 0 ? 'floater_table__titlecell' : '',
                                  ]"
                                >
                                  {{ cell.getString() }}
                                </td>
                              </template>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div
                      style="position: absolute; top: 10px; right: 70px"
                      v-if="isShowWeeklyAlertCheck()"
                    >
                      <div
                        style="
                          background: #fff;
                          width: 300px;
                          padding: 15px;
                          box-shadow: 0px 2px 10px #00000027;
                        "
                      >
                        <div style="overflow: auto; height: 400px">
                          <template v-for="(card, index) in page.weeklyAlert.cards" :key="index">
                            <div style="padding: 10px">
                              <div style="margin-top: 10px">{{ card.title }}</div>
                              <template v-for="(info, index) in card.infos" :key="index">
                                <div style="margin-top: 5px">{{ info.label }}</div>
                                <div style="display: flex; color: #ff6565">
                                  <div>↓{{ info.rate }}%</div>
                                  <div>({{ info.cell.getString() }})</div>
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame" style="margin-left: 10px">
                <div class="box">
                  <div style="width: 100%; position: relative">
                    <div class="frame__header">
                      日次グラフ
                      <div style="float: right">
                        <div
                          v-on:mouseover="mouseOverDaily"
                          v-on:mouseleave="mouseLeaveDaily"
                          v-on:click="toggleDailyAlert"
                          class="small_circle small_circle--danger"
                          v-if="page.dailyGraph.isAlert()"
                        >
                          <div
                            style="
                              margin-top: 1px;
                              margin-left: 3px;
                              font-size: 15px;
                              text-align: center;
                              color: #fff;
                              font-weight: bold;
                            "
                          >
                            !
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="frame__body">
                      <div style="width: 100%">
                        <ChartJs :graph="page.dailyGraph" :titleskip="2" v-if="false" />
                      </div>
                    </div>
                    <div
                      style="position: absolute; top: 10px; right: 70px"
                      v-if="isShowDailyAlertCheck()"
                    >
                      <div
                        style="
                          background: #fff;
                          width: 300px;
                          padding: 15px;
                          box-shadow: 0px 2px 10px #00000027;
                        "
                      >
                        <div style="overflow: auto; height: 400px">
                          <template v-for="(card, index) in page.dailyAlert.cards" :key="index">
                            <div style="padding: 10px">
                              <div style="margin-top: 10px">{{ card.title }}</div>
                              <template v-for="(info, index) in card.infos" :key="index">
                                <div style="margin-top: 5px">{{ info.label }}</div>
                                <div style="display: flex; color: #ff6565">
                                  <div>↓{{ info.rate }}%</div>
                                  <div>({{ info.cell.getString() }})</div>
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="adv_right_content_layer"
          :class="[
            page.isSprintPanelOpen
              ? 'adv_right_content_layer--sprintopen'
              : 'adv_right_content_layer--sprintclose',
          ]"
        >
          <div style="width: 552px; height: 100%; position: relative">
            <div class="adv_right_content_content_area">
              <div class="adv_right_content_content_layer" style="height: 100%; overflow-y: auto">
                <div class="sprintitem_box" v-if="page.sprintHolder.selectedSprint() != null">
                  <!-- 大方針 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().mayorpolicy?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">大方針</div>
                    </div>
                    <div class="sprintitem__body">
                      <div style="margin-left: 12px">
                        {{ page.sprintHolder.selectedSprint().mayorpolicy }}
                      </div>
                    </div>
                  </div>
                  <!-- 単月目標達成可否 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().monthlygoalachieve?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">単月目標達成可否</div>
                    </div>
                    <div class="sprintitem__body">
                      <div style="margin-left: 12px">
                        {{ page.sprintHolder.selectedSprint().monthlygoalachieve }}
                      </div>
                    </div>
                  </div>
                  <!-- 月内方針 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().monthlypolicy?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">月内方針</div>
                    </div>
                    <div class="sprintitem__body">
                      <div style="margin-left: 12px">
                        {{ page.sprintHolder.selectedSprint().monthlypolicy }}
                      </div>
                    </div>
                  </div>
                  <!-- 月内進捗状況 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().monthlystatustext?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">月内進捗状況</div>
                    </div>
                    <div class="sprintitem__body">
                      <template
                        v-for="(stat, sindex) in monthlystatusJson(
                          page.sprintHolder.selectedSprint().monthlystatustext
                        )"
                        :key="sindex"
                      >
                        <div style="margin-left: 12px">
                          <div class="monthlystatusline">
                            <div>{{ data2str(stat.label) }}</div>
                            <div class="monthlystatusline__line">-</div>
                            <div class="monthlystatusline__value">
                              {{ vtunit(stat.value, stat.type) }}
                            </div>
                            <div
                              style="
                                padding-left: 8px;
                                padding-right: 4px;
                                color: #000;
                                font-weight: bold;
                                position: relative;
                              "
                            >
                              <div style="position: absolute; left: 12px; top: 0px">
                                <svg
                                  v-bind:class="[ratescaleArrawClass(stat.label, stat.rate)]"
                                  v-if="ratescaleArrawClass(stat.label, stat.rate) != ''"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 11.268 13.002"
                                >
                                  <path
                                    id="合体_22"
                                    data-name="合体 22"
                                    d="M10735.467,12786v-5.2H10732l5.634-7.8,5.634,7.8h-3.466v5.2Z"
                                    transform="translate(-10732.001 -12773.001)"
                                  />
                                </svg>
                              </div>

                              ({{ data2str(stat.rate) }}%)
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- 概況 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().overview?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">概況</div>
                    </div>
                    <div class="sprintitem__body">
                      <div style="margin-left: 12px">
                        {{ page.sprintHolder.selectedSprint().overview }}
                      </div>
                    </div>
                  </div>
                  <!-- 課題箇所 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().overvproblempointsiew?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">課題箇所</div>
                    </div>
                    <div class="sprintitem__body">
                      <div style="margin-left: 12px">
                        {{ page.sprintHolder.selectedSprint().problempoints }}
                      </div>
                    </div>
                  </div>
                  <!-- 課題要因 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().problemfactors?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">課題要因</div>
                    </div>
                    <div class="sprintitem__body">
                      <div style="margin-left: 12px">
                        {{ page.sprintHolder.selectedSprint().problemfactors }}
                      </div>
                    </div>
                  </div>
                  <!-- 対策方針 -->
                  <div class="sprintitem" v-if="page.sprintHolder.selectedSprint().approachpolicy?.length > 0">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">対策方針</div>
                    </div>
                    <div class="sprintitem__body">
                      <template
                        v-for="(ap, index) in approachpolicyJson(
                          page.sprintHolder.selectedSprint().approachpolicy
                        )"
                        :key="index"
                      >
                        <div style="margin-left: 12px">
                          <div class="sprint_title">{{ type2str(ap) }}</div>
                          <div class="sprint_body">{{ ap.data }}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- 改善施策 -->
                  <div class="sprintitem">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">改善施策</div>
                    </div>
                    <div class="sprintitem__body">
                      <div>
                        <template
                          v-for="(action, sindex) in chengeOrderActionLogs(
                            page.sprintHolder.selectedSprint().todoactionitemsList
                          )"
                          :key="sindex"
                        >
                          <div
                            style="
                              padding: 4px 0px;
                              border-radius: 4px;
                              margin: 4px 0px;
                              height: initial;
                              display: block;
                            "
                          >
                            <div style="margin-left: 12px">
                              <template
                                v-for="(ci, cindex) in csvarr(action.provider)"
                                :key="cindex"
                              >
                                <div style="padding: 0px 3px; float: left">
                                  <div
                                    style="
                                      font-size: 10px;
                                      background: #eeeeee;
                                      color: #000;
                                      padding: 0px 8px;
                                      margin: 1px 0px;
                                      border-radius: 4px;
                                      width: fit-content;
                                    "
                                  >
                                    {{ ci }}
                                  </div>
                                </div>
                              </template>
                              <div style="clear: both"></div>
                            </div>
                            <div
                              style="
                                color: #fff;
                                vertical-align: middle;
                                margin-top: auto;
                                margin-bottom: auto;
                                padding: 4px 8px;
                              "
                            >
                              {{ action.title
                              }}<b
                                >【{{ action.implementationdate }}-{{ action.validationdate }}】</b
                              >
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- 進行中施策 -->
                  <div class="sprintitem">
                    <div class="sprintitem__header">
                      <div class="sprintitem__title" style="">進行中施策</div>
                    </div>
                    <div class="sprintitem__body">
                      <div>
                        <template
                          v-for="(action, sindex) in chengeOrderActionLogs(
                            page.sprintHolder.selectedSprint().inprogressactionitemsList
                          )"
                          :key="sindex"
                        >
                          <div
                            style="
                              padding: 4px 0px;
                              border-radius: 4px;
                              margin: 4px 0px;
                              height: initial;
                              display: block;
                            "
                          >
                            <div style="margin-left: 8px">
                              <template
                                v-for="(ci, cindex) in csvarr(action.provider)"
                                :key="cindex"
                              >
                                <div style="padding: 0px 3px; float: left">
                                  <div
                                    style="
                                      font-size: 10px;
                                      background: #eeeeee;
                                      color: #000;
                                      padding: 0px 8px;
                                      margin: 1px 0px;
                                      border-radius: 4px;
                                      width: fit-content;
                                    "
                                  >
                                    {{ ci }}
                                  </div>
                                </div>
                              </template>
                              <div style="clear: both"></div>
                            </div>
                            <div
                              style="
                                color: #fff;
                                vertical-align: middle;
                                margin-top: auto;
                                margin-bottom: auto;
                                padding: 4px 8px;
                              "
                            >
                              {{ action.title
                              }}<b
                                >【{{ action.implementationdate }}-{{ action.validationdate }}】</b
                              >
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sprintitem_box" v-if="page.sprintHolder.selectedSprint() == null">
                  <div class="sprintitem">
                    <div class="sprintitem__body">データがありません</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="adv_right_content_tab_layer">
              <div class="adv_right_content_tab_item_layer">
                <div style="display: flex">
                  <template v-for="(item, index) in page.sprintHolder.raws" :key="index">
                    <div
                      style="color: #fff; cursor: pointer;"
                      class="adv_right_content_tab_item"
                      v-bind:class="[
                        page.sprintHolder.selectedSprint().id == item.id
                          ? 'sprintselecter--active adv_right_content_tab_item--active'
                          : '',
                      ]"
                      v-on:click="page.sprintHolder.selectSprint(item.id)"
                    >
                      {{ item.startdate }}～
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="adv_right_content_control_layer" v-on:click="page.toggleSprintPanel">
          <svg
            v-if="!page.isSprintPanelOpen"
            style="margin-top: 124px; margin-left: 20px; fill: #fff;"
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            width="48"
            viewBox="0 0 80 80"
          >
            <path
              d="M20 15.55h16.05v-3H20Zm0 6h16.05v-3H20Zm0 6h10v-3H20Zm-5.95-11.5q.85 0 1.425-.575.575-.575.575-1.425 0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575-.575.575-.575 1.425 0 .85.575 1.425.575.575 1.425.575Zm0 6q.85 0 1.425-.575.575-.575.575-1.425 0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575-.575.575-.575 1.425 0 .85.575 1.425.575.575 1.425.575Zm0 6q.85 0 1.425-.575.575-.575.575-1.425 0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575-.575.575-.575 1.425 0 .85.575 1.425.575.575 1.425.575ZM4 44V7q0-1.15.9-2.075Q5.8 4 7 4h34q1.15 0 2.075.925Q44 5.85 44 7v26q0 1.15-.925 2.075Q42.15 36 41 36H12Zm3-7.25L10.75 33H41V7H7ZM7 7v29.75Z"
            />
          </svg>
          <svg
            style="margin-top: 124px; margin-left: 20px; fill: #fff;"
            v-if="page.isSprintPanelOpen"
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            width="48"
            viewBox="0 0 80 80"
          >
            <path
              d="m12.75 35.95-2.1-2.1 9.9-9.9-9.9-9.9 2.1-2.1 12 12Zm12.65 0-2.1-2.1 9.9-9.9-9.9-9.9 2.1-2.1 12 12Z"
            />
          </svg>
        </div>
        <div class="sub_content_layer" v-if="false">
          <div class="sub_content_wrapper">
            <div id="lay_right_sidemenu" class="right_sidemenu_layer">
              <SideMenu :adx="adx" selectId="summary" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ColumnShowHide :proxy="csh" />

    <div class="menu_area" v-if="menu != null">
      <div v-if="menu != null" class="report_menu" :style="{
        top: `${menu.y}px`,
        left: `${menu.x}px`,
      }">
        <div class="report_menu_cover" v-on:click="closeReportMenu()"></div>
        <div class="page_menuitem_menu__box">
          <div class="page_menuitem_menu__title mousepointer" v-on:click.stop="menuCopy()">
            <img class="page_menuitem_menu__icon" height="14" src="@/assets/icon/content_copy_FILL0_wght400_GRAD0_opsz48.svg" />
            コピー
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.mousepointer {
  cursor: pointer;
}
.menu_area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.report_menu {
  position: absolute;
}

.report_menu_cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000000;
  z-index: 1;
  pointer-events: all;
}

.page_menuitem_menu__box {
  padding: 4px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d7dae2;
  border-radius: 3px;
  width: 112px;
  font: normal normal 300 10px/13px Noto Sans JP;
  pointer-events: all;
  z-index: 10;
  position: relative;
  padding: 8px;
}

.page_menuitem_menu__title {
  padding: 4px 0px;
  padding-left: 20px;
  position: relative
}

.page_menuitem_menu__icon {
  position: absolute;
  top: 4px;
  left: 4px;
}

.page_menuitem_menu__title:hover {
  background: #eeeeee;
}
</style>

<style scoped>

.sprintitem_box {
  user-select: text;
}

</style>

<style scoped>
.floater_table__body.floater_table__body--in2line div {
  height: initial;
}
.floater_table__body.floater_table__body--in2line tr {
  height: 40px;
}
.floater_table__body.floater_table__body--in2line td {
}

.floater_table__body--in2line {
}

.floater_table__in2line {
  width: 130px;
}
.floater_table__in2line-data {
  font: 12px 'Noto Sans JP';
  text-align: center;
  background: #fff;
}

.in2line {
  padding: 8px;
  font-weight: bold;
}

.in2line__singleline {
  display: flex;
}

.in2line__value {
  margin-left: auto;
}

.in2line__up {
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
.in2line__down {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}

.pcheckline {
  display: flex;
}

.pcheckline__arrow {
  margin-left: auto;
  position: relative;
}

.pcheckline__value {
  font: 900 20px / 20px 'Noto Sans JP';
}

.pcheckline__unit {
  margin-right: auto;
  font: 900 20px / 20px 'Noto Sans JP';
}
</style>

<style scoped>
.rspc {
  padding: 8px 8px;
  width: 210px;
}

.rspc__header {
  background: #39434e;
  color: #fff;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 32px;
  font: 19px/11px Noto Sans JP;
  font-weight: 300;
  line-height: 100%;
}
</style>

<style scoped>
.monthlystatusline {
  display: flex;
  width: fit-content;
  background: rgb(238, 238, 238);
  color: #333;
  padding: 4px 16px;
  border-radius: 4px;
  margin: 4px;
}

.monthlystatusline__value {
  font-weight: bold;
  margin-left: 8px;
  margin-right: 8px;
}

.monthlystatusline__line {
  margin-left: 8px;
  margin-right: 8px;
}

.gbarrow3step--1 {
  position: absolute;
  top: -1px;
  left: -16px;
  width: 14px;
  height: 15px;

  fill: #088dff;
}
.gbarrow3step--2 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 11px;
  height: 12px;

  fill: #088dff;
}
.gbarrow3step--3 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;

  fill: #088dff;
}
.gbarrow3step--4 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--5 {
  position: absolute;
  top: 3px;
  left: -14px;
  width: 10px;
  height: 13px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--6 {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 14px;
  height: 15px;
  transform: rotate(180deg);
  fill: #ff1744;
}
.gbarrow3step--11 {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 14px;
  height: 15px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--12 {
  position: absolute;
  top: 3px;
  left: -14px;
  width: 10px;
  height: 13px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--13 {
  position: absolute;
  top: 7px;
  left: -12px;
  width: 8px;
  height: 8px;
  transform: rotate(180deg);
  fill: #088dff;
}
.gbarrow3step--14 {
  position: absolute;
  top: 6px;
  left: -12px;
  width: 8px;
  height: 8px;

  fill: #ff1744;
}
.gbarrow3step--15 {
  position: absolute;
  top: 2px;
  left: -14px;
  width: 11px;
  height: 12px;

  fill: #ff1744;
}
.gbarrow3step--16 {
  position: absolute;
  top: -1px;
  left: -16px;
  width: 14px;
  height: 15px;

  fill: #ff1744;
}

.sticky_title {
  background: white;
}
.sticky_title__span {
  top: 130px;
}

.selectdimension1 {
  background: #2680eb;
  color: #fff;
  border: 1px solid #e3e3e3;
}
.selectdimension2 {
  background: #5a9ff0;
  color: #fff;
}
.selectdimension3 {
  background: #088dff55;
}

.selectdimension0 {
  border: 1px solid #e3e3e3;
}

.dimension_selector {
  display: flex;
}

.dimension_selector__button {
  min-width: 80px;
  margin-left: 8px;
  border: 0.5px solid #e3e3e3;
  border-radius: 3px;
  font-size: 13px;
  padding: 5px;
  text-align: center;
  user-select: none;
}

.sprint_title {
  padding: 4px 16px;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  margin: 4px;
  width: fit-content;
  color: #333;
}

.sprint_body {
  padding: 4px 16px;
}
</style>

<style scoped>
.adv_right_content_content_area {
  position: absolute;
  top: 0px;
  bottom: 64px;
  width: 100%;
  padding: 32px 32px 16px 32px;
  box-shadow: 0px 0px 8px 3px rgb(39, 39, 39) inset;
}

.adv_right_content_tab_item {
  padding: 4px 8px;
  width: 100px;
  margin: 8px 8px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 8px;
  font: 12px / 17px 'Noto Sans JP';
  white-space: nowrap;
}

.adv_right_content_tab_item--active {
  background: rgba(93, 144, 252, 1);
}

.adv_right_content_content_layer {
  word-break: break-all;
  height: 100%;
  overflow-y: auto;
  color: #fff;
  font: 12px / 17px 'Noto Sans JP';
}

.adv_right_content_content_layer::-webkit-scrollbar {
  width: 2px;
}

.adv_right_content_content_layer::-webkit-scrollbar-track {
  background: rgba(212, 212, 212, 0);
}

.adv_right_content_content_layer::-webkit-scrollbar-thumb {
  background: rgba(212, 212, 212, 0);
}

.adv_right_content_tab_item_layer {
  width: 522px;
  overflow-x: auto;
  margin-left: 16px;
  margin-top: 12px;
}

.adv_right_content_tab_layer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 64px;
}

.adv_right_content_tab_item_layer::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.adv_right_content_tab_item_layer::-webkit-scrollbar-track {
  background: #fff;
}

.adv_right_content_tab_item_layer::-webkit-scrollbar-thumb {
  background: #ccc;
}
</style>

<style>
.header_layer {
  z-index: 200;
}
</style>

<style scoped>
.adv_right_content_layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 100px 8px 8px 0px;
  background: #39434e 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 6px #00000029;
  transition: 0.2s;
  overflow: hidden;
  z-index: 100;
}
.adv_right_content_layer--sprintclose {
  width: 65px;
}
.adv_right_content_layer--sprintopen {
  width: calc(619px + 16px);
}

.adv_right_content_control_layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(65px + 16px);
  background: #39434e 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 6px #00000029;
  /* transition: 0.5s; */
  overflow: hidden;
  /* -webkit-animation: sprint_keyframes 0.5s 1;
  animation: sprint_keyframes 0.5s 1; */
  z-index: 101;
  cursor: pointer;
}
@-webkit-keyframes sprint_keyframes {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes sprint_keyframes {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.adv_main_content__header--sprintclose {
  width: calc(100% - (65px));
  transition: 0.2s;
}

.adv_main_content__header--sprintopen {
  width: calc(100% - (619px + 16px));
  transition: 0.2s;
}

.box--sprintclose {
  width: calc(100% - (65px + 16px));
  transition: 0.2s;
}

.box--sprintopen {
  width: calc(100% - (619px + 16px));
  transition: 0.2s;
}
</style>

<style scoped>
.main_content_wrapper {
  width: initial;
}
</style>

<style scoped>
.sprintitem {
  padding: 4px 8px;
  /* background: rgba(62, 71, 91, 1); */
  margin-bottom: 32px;
  color: #fff;
}

.sprintitem__title {
  display: inline-block;
  padding: 0px 8px;
  font: 15px Noto Sans JP;
  border-left: 5px solid #fff;
}

.sprintitem__header {
  margin-bottom: 16px;
}
.sprintitem__body {
  white-space: break-spaces;
  font-weight: 400;
  line-height: 17px;
}

.sprintselecter--active {
  border: 1px solid rgba(93, 144, 252, 1);
}
</style>

<style scoped>
.draglock {
  user-select: none;
}

.expansion_table {
  table-layout: auto;
  width: initial;
  max-width: initial;
}

.expansion_table__th {
  width: initial;
  min-width: 104px;
}

.expansion_table__th:hover {
  user-select: none;
}

.expansion_table__td {
  width: initial;
  min-width: initial;
}

.expansion_table__dragmarker {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 8px;
  height: 29px;
}
.expansion_table__dragmarker--active {
  background: #2680ebdd;
}

.expansion_table__dragmarker:hover {
  background: #2680ebdd;
}

.frame--expansion {
  width: initial;
}

.frame__header--expansion_lock {
  width: 1544px;
}

.scroll_bar {
  overflow: auto;
}

.scroll_bar::-webkit-scrollbar {
  border-radius: 6px;
  width: 8px;
  height: 12px;
}
.scroll_bar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: rgba(241, 241, 241, 1);
}
.scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(193, 193, 193, 1);
}

.expansion_table__dragmarker__arrow {
  display: none;
  position: relative;
  pointer-events: none;
}
.expansion_table__dragmarker__larrow {
  position: absolute;
  top: 6px;
  left: -14px;
}
.expansion_table__dragmarker__rarrow {
  position: absolute;
  top: 6px;
  left: -2px;
}
.expansion_table__dragmarker__lrarrow {
  position: absolute;
  top: 6px;
  left: -4px;
}

.expansion_table__dragmarker:hover .expansion_table__dragmarker__arrow {
  display: block;
}
.expansion_table__dragmarker--active .expansion_table__dragmarker__arrow {
  display: block;
}
</style>

<style scoped>
.selectbtn {
  font-size: 12px;
  border-bottom: solid 2px #cccccc;
  padding: 0px 32px 16px;
  color: #cccccc;
}

.selectbtn--active {
  color: #2680eb;
  border-bottom: solid 2px #2680eb;
}
</style>

<style scoped>
.consultant_record__trmark {
  background: #e3f2fd;
}

.consultant_record__trmark:hover {
  background-color: rgba(212, 212, 212, 0.3);
}
.consultant_record__trmark2 {
  background: #eeeeee;
}

.consultant_record__trmark2:hover {
  background-color: rgba(212, 212, 212, 0.3);
}
</style>

<style scoped>
.dimension_selector__button {
  cursor: pointer;
}
</style>

<style scoped>
.tr_one_line {
  height: 80px;
}

.alert_color {
  color: #ff6565;
}

.success_color {
  color: #3b86ff;
}
</style>

<style>
.floater_table__body td .itemwithtitle {
  border: solid 0px;
}

.floater_table__keyword__categorytitle {
  width: 220px;
  position: relative;
  text-align: left;
  padding: 15px;
}

.floater_table__body td .itemwithtitle-sm {
  padding-right: 5px;
}

.floater_table__keyword__2categorytitle {
  position: relative;
  text-align: left;
  width: 120px;
}

.floater_table__keyword__1categorytitle {
  position: relative;
  width: 140px;
}

.small_circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: rgb(59, 134, 255);
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -o-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
}

.small_circle--danger {
  background: #ff6565;
}

.low_campaign_card__info {
  font: normal normal 300 15px/21px Noto Sans JP;
}

.low_campaign_card__info_main {
  font-size: 25px;
}

.low_campaign_card__info_main_title {
  font-weight: bold;
}
.low_campaign_card__info_main_value {
  font: bold;
  color: #ff6565;
}
</style>

<style scoped>
.low_campaign_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000027;
  padding: 15px;
}

.low_campaign_card--infobox {
  position: relative;
  margin-right: 20px;
}

.low_campaign_card--right {
  margin-left: 20px;
}

.low_campaign_card__title {
  font: normal normal 300 12px Noto Sans JP;
}

.low_campaign_card__name {
  margin-top: 5px;
  font: normal normal 300 15px Noto Sans JP;
}

.low_campaign_card__info {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.low_campaign_card__info_sub {
  display: flex;
  color: #ff6565;
  margin-top: 10px;
}

.floater_table__summary_weekly--alert {
  color: #ff6565;
}
</style>

<script>
// @ is an alias to /src
import SideMenu from '@/components/report/SideMenu.vue';
import ContentHeader from '@/components/report/ContentHeader.vue';
import Spinner from '@/components/layout/Spinner.vue';

// import Vprogress from '@/components/element/Vprogress.vue';
// import HProgressCard from '@/components/element/HProgressCard.vue';
import ChartJs from '@/components/element/ChartJs.vue';

import RangeCalendar from '@/components/RangeCalendar.vue';

import { SummaryPage } from '@/js/page/report/SummaryPage.js';

import { TimeYmd } from '@/js/tacklebox/date/TimeYmd.js';

import {
  cells2SpanCells,
  spanTilte2HeaderTitle,
  titles2Cell,
  is2SpanCell,
} from '@/js/components/spancell.js';

import ColumnShowHide from '@/components/ColumnShowHide.vue';
import { ColumnShowHideProxy, ColumnInfo } from '@/js/components/ColumnShowHideProxy.js';

// import BarProgress from '@/components/BarProgress.vue';
import M2ChartJs from '@/components/element/M2ChartJs.vue';
import { sprintApps, ratescale3Step } from '@/js/setting.js';

import CircleProgress from '@/components/CircleProgress.vue';
import BoxSpinner from '@/components/layout/BoxSpinner.vue';
import ScreenBarProgress2 from '@/components/ScreenBarProgress2.vue';

export default {
  name: 'Summary',
  data() {
    return {
      name: 'サマリ',
      page: new SummaryPage(),
      isLoad: false,
      dateRangeSelector: null,
      provider: '',
      isShowRange: false,
      isShowDailyAlert: false,
      isShowDailyAlertHover: false,
      isShowWeeklyAlert: false,
      isShowWeeklyAlertHover: false,
      csh: new ColumnShowHideProxy(),
      isDragmode: false,
      dragstate: 0,
      baseClientX: 0,
      baseWidth: 100,
      exptarget: null,
      expMarkerId: -1,
      expType: '',
      expTemp: 3000,
      isActive: true,
      menu: null,
      dmCt1label: '',
      dmCt2label: '',
      dmCt3label: '',
    };
  },
  emits: ['screenevent'],
  mounted() {
    let self = this;
    this.$emit('screenevent', 'keyword');

    let p = this.adx.getScreenStructureById('report').getPageByKey('サマリー');

    this.adx.excelreport.setCallback(function () {
      // self.page.xlsx();
      self.excelreportdownload();
    });

    if (p) {
      self.adx.excelreport.ready();
      this.dateRangeSelector = this.adx.getScreenStructureById('report').dateRangeSelector;
      this.dateRangeSelector.timeYmdRange.subject.removeAll();
      this.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
        console.log(self.dateRangeSelector.timeYmdRange.getYm());
        self.isLoad = false;
        self.initialize(
          p.id,
          p.pageComponents,
          self.adx.getScreenStructureById('report').advertizerId,
          self.dateRangeSelector
        );
      });
      this.initialize(
        p.id,
        p.pageComponents,
        this.adx.getScreenStructureById('report').advertizerId,
        self.dateRangeSelector
      );
    } else {
      this.adx.getScreenStructureById('report').subject.addObserver(function () {
        let p = self.adx.getScreenStructureById('report').getPageByKey('サマリー');
        if (p) {
          if (self.isActive) {
            self.adx.excelreport.ready();
          }
          if (!self.isLoad && p.pageComponents.length > 0) {
            self.dateRangeSelector = self.adx.getScreenStructureById('report').dateRangeSelector;
            self.dateRangeSelector.timeYmdRange.subject.removeAll();
            self.dateRangeSelector.timeYmdRange.subject.addObserver(function () {
              self.isLoad = false;
              self.initialize(
                p.id,
                p.pageComponents,
                self.adx.getScreenStructureById('report').advertizerId,
                self.dateRangeSelector
              );
            });
            self.initialize(
              p.id,
              p.pageComponents,
              self.adx.getScreenStructureById('report').advertizerId,
              self.dateRangeSelector
            );
          }
        }
      });
    }

    document.addEventListener('click', this.onClickEvent);
    document.addEventListener('mousemove', this.onMouseMoveEvent);
    document.addEventListener('mouseup', this.onMouseUpEvent);

    this.page.isDimensionNetworkEvent = function(isNetwork) {
      self.page.isDimensionNetwork = isNetwork;
    }

    document.addEventListener("scroll", this.scroller);
  },
  methods: {
    scroller() {
      const br = document.getElementById("dm0position").getBoundingClientRect();
      let dmCt1label = "";
      let dmCt2label = "";
      let dmCt3label = "";
      if (br) {        
        let h1p = document.getElementById("dm_header_position");
        if (h1p) {
          if (br.top - 90 < 0) {
            h1p.style.top = (((br.top - 90) * -1) + "px");
          }else{
            h1p.style.top = "0px";
          }
        }

        let dm1positions = document.getElementsByClassName("dm1position");
        if (dm1positions) {
          for (let i = dm1positions.length - 1 ; i >= 0 ; i--) {
            if (dm1positions[i].getBoundingClientRect().top < 160) {
              dmCt1label = dm1positions[i].innerText;
              break;
            }
          }
        }

        let dm2positions = document.getElementsByClassName("dm2position");
        if (dm2positions) {
          for (let i = dm2positions.length - 1 ; i >= 0 ; i--) {
            if (dm2positions[i].getBoundingClientRect().top < 160) {
              dmCt2label = dm2positions[i].innerText;
              break;
            }
          }
        }

        let dm3positions = document.getElementsByClassName("dm3position");
        if (dm3positions) {
          for (let i = dm3positions.length - 1 ; i >= 0 ; i--) {
            if (dm3positions[i].getBoundingClientRect().top < 160) {
              dmCt3label = dm3positions[i].innerText;
              break;
            }
          }
        }
      }
      this.dmCt1label = dmCt1label;
      this.dmCt2label = dmCt2label;
      this.dmCt3label = dmCt3label;
    },
    menuwidth(type, hs, showtype = '') {
      let width = 0;
      for (let i = 0; i < hs.length; i++) {
        let css = this.page.columnStyleStocker
          .getColumnStyles(this.page.advertizerId, 'summary', type)
          ?.getColumnStyle(i);
        if (this.isShowSummaryColum(i, showtype)) {
          if (css) {
            if (css.width && css.width > 0) {
              width += css.width;
            }
          } else {
            width += 104;
          }
        }
      }

      if (type == 'd3' || type == 'd2' || type == 'd1') {
        width += 130 * 3;
      }

      width = width - 24;
      if (width > 0) {
        if (width < 1740) {
          return width;
        }
      }

      return 1740;
    },
    menuTitleStyle(type, hs, showtype = '') {
      let width = 0;
      for (let i = 0; i < hs.length; i++) {
        let css = this.page.columnStyleStocker
          .getColumnStyles(this.page.advertizerId, 'summary', type)
          ?.getColumnStyle(i);
        if (this.isShowSummaryColum(i, showtype)) {
          if (css) {
            if (css.width && css.width > 0) {
              width += css.width;
            }
          } else {
            width += 104;
          }
        }
      }

      if (type == 'd3' || type == 'd2' || type == 'd1') {
        width += 130 * 3;
      }

      let tw = 1740;
      // width = width - 24;
      if (width > 0) {
        if (width < 1740) {
          tw = width;
        }
      }

      let ret = {};
      if (tw < 1740) {
        ret.left = (tw + 'px');
      }else{
        ret.right = '16px';
      }
      return ret;
    },
    menuCopy() {
      this.copyToClipboard(this.menu.classname);
      this.closeReportMenu();
    },
    closeReportMenu() {
      this.menu = null;
    },
    showReportMenu(item, e, classname = "") {
      this.menu = {
        item: item,
        x: e.pageX - 100,
        y: e.pageY,
        classname: classname
      };
    },
    copyToClipboard: (className) => {
      // display copy message
      let copyMessage = document.createElement('div');
      copyMessage.className = 'copy_message';
      copyMessage.style.position = 'absolute';
      copyMessage.style.zIndex = '999';
      copyMessage.style.top = `${window.event.clientY + document.documentElement.scrollTop + 5}px`;
      copyMessage.style.left = `${
        window.event.clientX + document.documentElement.scrollLeft + 10
      }px`;
      copyMessage.style.backgroundColor = 'rgb(200,200,200)';
      copyMessage.style.padding = '3px';
      copyMessage.style.borderRadius = '5px';
      copyMessage.innerHTML = 'Copying...';
      // document.getElementsByTagName('body')[0].appendChild(copyMessage);

      // copy element
      let applyInline = (el_clone, el_origin) => {
        const cs = window.getComputedStyle(el_origin);

        for (let key in cs) {
          if (!isNaN(parseInt(key))) continue;
          if (['length', 'parentRule'].includes(key)) continue;
          //if(! ["background", "font"].includes(key)) return ;
          if (['border'].includes(key)) {
            let sps = cs[key].split(" ");
            if (sps) {
              if (sps.length > 0) {
                if (sps[0] != "0px") {
                  el_clone.style['border'] = "1px solid rgb(0,0,0)";
                }
              }
            }
          }
          if (['font', 'backgroundColor', 'color'].includes(key)) el_clone.style[key] = cs[key];
        }
        el_clone.style['fontSize'] = '100%';
        Array.from(el_clone.children).forEach((n, i) => applyInline(n, el_origin.children[i]));
      };
      try {
        let origin = document.getElementsByClassName(className)[0];
        let tbl = origin.cloneNode(true);
        applyInline(tbl, origin);

        let os = tbl.getElementsByClassName("copyremovecell");
        if (os) {
          for (let oi = os.length -1 ; oi >= 0 ; oi--) {
            os[oi].remove();
          }
        }

        var type = 'text/html';
        var blob = new Blob([tbl.outerHTML], { type });
        var data = [new window.ClipboardItem({ [type]: blob })];
        navigator.clipboard.write(data).then(
          () => {
            // copyMessage.innerHTML = 'Copy done!';
            // setTimeout(() => {
            //   copyMessage.remove();
            // }, 1000);
          },
          () => {
            // copyMessage.innerHTML = 'Copy failed!';
            // setTimeout(() => {
            //   copyMessage.remove();
            // }, 1000);
          }
        );
      } catch (e) {
        // copyMessage.innerHTML = 'Copy failed!';
        // setTimeout(() => {
        //   copyMessage.remove();
        // }, 1000);
      }
    },
    title01() {
      if (this.page.isCurrentMonth()) {
        return "全体予実";
      }
      return "全体実績";
    },
    cccline01_show(index) {
      if (index != 0) {
        return false;
      }
      return true;
    },
    cccline01_row(records) {
      let row = 2;
      function _t(rs) {
        for (let i in rs) {
          if (rs[i].length > 0) {
            if (rs[i][0]?.data.indexOf("目標") >= 0) {
              return true;
            }
          }
        }
        return false;
      }

      if ((!_t(records)) && (this.isreoffsetinclude(records) == true)) {
        row = row - 1
      }
      return row;
    },
    cccline02_show(index) {
      if (this.page.isCurrentMonth()) {
        if (index == 2) {
          return true;
        }
      }else{
        if (index == 3) {
          return true;
        }
      }
      return false;
    },
    cccline02_row() {
      if (this.page.isCurrentMonth()) {
          return 2;
      }else{
          return 1;
      }
    },
    titlewrapper(title) {
      if (!this.page.isCurrentMonth()) {
        if (title.indexOf("想定乖離率") >= 0) {
          return "乖離率";
        }
        if (title.indexOf("当月実績") >= 0) {
          return "実績";
        }
      }

      return title;
    },
    rpctitle() {
      if (this.page.isCurrentMonth()) {
          return "着地想定";
      }

      return "実績";
    },
    isreoffset(cells) {
      console.log("cells", cells[0].data);

      if (!this.page.isCurrentMonth()) {
        if (cells && cells.length > 0) {
          if (cells[0]?.data.indexOf("着地想定") >= 0) {
            return true;
          }
        }
      }
      return false;
    },
    isreoffsetinclude(records) {
      if (!this.page.isCurrentMonth()) {
        for (let i in records) {
          if (records[i].length > 0) {
            if (records[i][0]?.data.indexOf("着地想定") >= 0) {
              return true;
            }
          }
        }
      }

      return false;
    },
    mwd_title_change(title) {
      if (title == "種別" || title == "週") {
        if (this.page.selectedMode == 1) {
          return "月別";
        }
        if (this.page.selectedMode == 2) {
          return "週別";
        }
        if (this.page.selectedMode == 3) {
          return "日別";
        }
      }
      return title;
    },
    dcgtmpsplice(dimensionCategoryGroups) {
      /*
      let dcgs = [];
      for (let i in dimensionCategoryGroups) {
        let dcs = this.dctmpsplice(dimensionCategoryGroups[i].dimensionCategories);
        if (dcs.length > 0) {
          dcgs.push(dimensionCategoryGroups[i]);
        }
      }
      return dcgs;
      */
      return dimensionCategoryGroups;
    },
    dctmpsplice(dimensionCategories) {
      // let dcs = [];
      // for (let i in dimensionCategories) {
      //   try {
      //     let isActive = false;
      //     for (let x in dimensionCategories[i].cellRows) {
      //       let cls = dimensionCategories[i].cellRows[x];
      //       if (cls.length > 0) {
      //         if (cls[0].getString().indexOf('実績') >= 0) {
      //           isActive = true;
      //           break;
      //         }
      //       }
      //     }
      //     if (isActive) {
      //       dcs.push(dimensionCategories[i]);
      //     }
      //   } catch (ex) {
      //     console.log(ex);
      //   }
      // }
      // return dcs;
      return dimensionCategories;
    },
    ratescaleArrawStyleWrapper(type, data) {
      let cln = 'gbarrow3stepwrapper';

      let step = ratescale3Step(type, data);
      switch (step) {
        case 1:
        case 16:
          return 'gbarrow3step--1wrapper';
        case 2:
        case 15:
          return 'gbarrow3step--2wrapper';
        case 3:
        case 14:
          return 'gbarrow3step--3wrapper';
        case 4:
        case 13:
          return 'gbarrow3step--4wrapper';
        case 5:
        case 12:
          return 'gbarrow3step--5wrapper';
        case 6:
        case 11:
          return 'gbarrow3step--6wrapper';
      }

      return cln;
    },
    getcellFindMonthlyTableBykey(target, backendKey) {
      if (this.page.monthlyTable?.structure?.structure?.chart) {
        for (let i = 0; i < this.page.monthlyTable?.structure?.structure?.chart.length; i++) {
          if (this.page.monthlyTable?.structure?.structure?.chart[i].backendKey == backendKey) {
            // return cellRow[i+1];
            for (let r in this.page.monthlyTable?.cellRows) {
              let cellRow = this.page.monthlyTable?.cellRows[r];
              if (cellRow[0].data == target) {
                return cellRow[i + 1].getString();
              }
            }
          }
        }
      }

      return '';
    },
    _getcellFindGroupcellBykey(target, backendKey, groupcells, structures) {
      // console.log(target);
      // console.log(backendKey);
      // console.log(groupcells);
      // console.log(structures);
      for (let i = 0; i < structures.length; i++) {
        if (structures[i].backendKey == backendKey) {
          for (let j in groupcells) {
            if (groupcells[j][0].data == target) {
              return groupcells[j][i + 1];
            }
          }
          break;
        }
      }

      return null;
    },
    getcellFindGroupcellBykey(target, backendKey, groupcells, structures) {
      let r = this._getcellFindGroupcellBykey(target, backendKey, groupcells, structures);
      if (r != null) {
        return r?.getString();
      }

      return '';
    },
    getcellValueFindGroupcellBykey(target, backendKey, groupcells, structures) {
      let r = this._getcellFindGroupcellBykey(target, backendKey, groupcells, structures);
      if (r != null) {
        return r.data;
      }

      return 0;
    },
    is2SpanCell(cell) {
      return is2SpanCell(cell);
    },
    summarytalbewidth(type, hs, showtype = '') {
      if (this.dragstate == 2 && type == this.expType) {
        return {
          width: this.expTemp + 'px',
        };
      }
      let width = 0;
      for (let i = 0; i < hs.length; i++) {
        let css = this.page.columnStyleStocker
          .getColumnStyles(this.page.advertizerId, 'summary', type)
          ?.getColumnStyle(i);
        if (this.isShowSummaryColum(i, showtype)) {
          if (css) {
            if (css.width && css.width > 0) {
              width += css.width;
            }
          } else {
            width += 104;
          }
        }
      }

      if (type == 'd3' || type == 'd2' || type == 'd1') {
        width += 130 * 3;
      }

      if ( type == "mwd") {
        width = width + 50;
      }


      if (width > 0) {
        return {
          width: width + 'px',
        };
      }

      return {};
    },
    pdcount(pgs) {
      let cnt = 0;
      console.log('pdcount', pgs);
      for (let i in pgs) {
        for (let j in pgs[i].dimensionCategories) {
          cnt += this.page.dimensionHolder.generateProviderCategoryRows(
            pgs[i].dimensionCategories[j].cellRows
          )?.length;

          if (!this.page.isCurrentMonth()) {
            if (pgs[i].dimensionCategories[j].cellRows) {
              if (pgs[i].dimensionCategories[j].cellRows.length > 0) {
                for (let x in pgs[i].dimensionCategories[j].cellRows) {
                  let c = pgs[i].dimensionCategories[j].cellRows[x];
                  if (c[0].data.indexOf("着地想定") >= 0) {
                    cnt = cnt - 1;
                    break;
                  }
                }
              }
            }
          }

        }
      }
      return cnt;
    },
    dcount(gs) {
      let cnt = 0;
      for (let i in gs) {
        cnt += this.page.dimensionHolder.generateProviderCategoryRows(gs[i].cellRows)?.length;
        if (!this.page.isCurrentMonth()) {
          if (gs[i].cellRows) {
            if (gs[i].cellRows.length > 0) {
              for (let j in gs[i].cellRows) {
                let c = gs[i].cellRows[j];
                if (c[0].data.indexOf("着地想定") >= 0) {
                  cnt = cnt - 1;
                  break;
                }
              }
            }
          }
        }
      }
      return cnt;
    },
    teststyle(type, index) {
      let css = this.page.columnStyleStocker
        .getColumnStyles(this.page.advertizerId, 'summary', type)
        ?.getColumnStyle(index);
      if (css) {
        return css.style;
      }
      return {};
    },
    onMouseUpEvent(/*event*/) {
      // console.log('onMouseUpEvent', event);
      if (this.dragstate > 0) {
        this.page.columnStyleStocker.setWidth(
          this.page.advertizerId,
          'summary',
          this.expType,
          this.expMarkerId,
          this.exptarget.clientWidth
        );
      }
      this.dragstate = 0;
      this.expMarkerId = -1;
      this.expTemp = 3000;
    },
    onMouseMoveEvent(event) {
      if (this.dragstate > 0) {
        // console.log('onMouseMoveEvent', event);
        this.dragstate = 2;
        this.exptarget.style.width = this.baseWidth + event.clientX - this.baseClientX + 'px';
      }
    },
    dragstart(type, index, e) {
      this.baseClientX = e.clientX;
      this.dragstate = 1;
      if (type == 'summary') {
        // this.exptarget = this.$refs.summary_header_ref[index];
        this.exptarget = document.getElementById('summary_header_id_' + index);
        this.expTemp = document.getElementById('summary_table_tag').clientWidth + 1000;
      } else if (type == 'mwd') {
        // this.exptarget = this.$refs.mwd_header_ref[index];
        this.exptarget = document.getElementById('mwd_header_id_' + index);
        this.expTemp = document.getElementById('mwd_table_tag').clientWidth + 1000;
      } else if (type == 'd3') {
        // this.exptarget = this.$refs.d2_header_ref[index];
        this.exptarget = document.getElementById('d3_header_id_' + index);
        this.expTemp = document.getElementById('d3_table_tag').clientWidth + 1000;
      } else if (type == 'd2') {
        // this.exptarget = this.$refs.d2_header_ref[index];
        this.exptarget = document.getElementById('d2_header_id_' + index);
        this.expTemp = document.getElementById('d2_table_tag').clientWidth + 1000;
      } else if (type == 'd1') {
        // this.exptarget = this.$refs.d1_header_ref[index];
        this.exptarget = document.getElementById('d1_header_id_' + index);
        this.expTemp = document.getElementById('d1_table_tag').clientWidth + 1000;
      }
      this.baseWidth = this.exptarget.clientWidth;
      this.expMarkerId = index;
      this.expType = type;
    },
    onClickEvent() {
      this.csh.clear();
    },
    showShowHide(e, index, type) {
      this.csh.setTop(e.pageY);
      this.csh.setLeft(e.pageX);

      let is = [];

      if (type == 'summary' || type == 'monthly') {
        let hs = this.page.monthlyTable.getHeaderCell('種別');
        for (let i = 0; i < hs.length; i++) {
          is.push(new ColumnInfo(i, hs[i].data));
        }
      } else if (type == 'dimension1') {
        for (let i in this.page.dimensionHolder.dimensions) {
          if (this.page.dimensionHolder.dimensions[i].count() == 1) {
            let hs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
            for (let x = 0; x < hs.length; x++) {
              is.push(new ColumnInfo(x, hs[x].data));
            }
            break;
          }
        }
      } else if (type == 'dimension2') {
        for (let i in this.page.dimensionHolder.dimensions) {
          if (this.page.dimensionHolder.dimensions[i].count() == 2) {
            let hs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
            for (let x = 0; x < hs.length; x++) {
              is.push(new ColumnInfo(x, hs[x].data));
            }
            break;
          }
        }
      } else if (type == 'dimension3') {
        for (let i in this.page.dimensionHolder.dimensions) {
          if (this.page.dimensionHolder.dimensions[i].count() == 3) {
            let hs = this.page.dimensionHolder.dimensions[i].getHeaderCell();
            for (let x = 0; x < hs.length; x++) {
              is.push(new ColumnInfo(x, hs[x].data));
            }
            break;
          }
        }
      }
      this.csh.setColumnInfos(is);
      this.csh.setStocker(
        this.page.invisibleColumnStocker.getComponentInvisibleColumn(
          this.page.advertizerId,
          'summary',
          type
        ),
        index
      );
    },
    isShowSummaryColum(index, type = 'summary') {
      let ic = this.page.invisibleColumnStocker.getComponentInvisibleColumn(
        this.page.advertizerId,
        'summary',
        type
      );
      if (ic) {
        for (let i in ic.invisiblenumbers) {
          let number = ic.invisiblenumbers[i];
          if (index == number) {
            return false;
          }
        }
      }

      return true;
    },
    excelreportdownload() {
      const self = this;
      let p2 = this.adx.getScreenStructureById('report').getPageByKey('媒体別実績');
      this.page.initializeProviderTab(p2.id, function () {
        self.page.providerTablesInitialize(
          p2.id,
          self.adx.getScreenStructureById('consultant').advertizerId,
          self.dateRangeSelector,
          self.adx.user,
          function () {
            self.page.xlsx();
          }
        );
      });
    },
    csvarr(csv) {
      if (csv) {
        return csv.split(',');
      }
      return ['-'];
    },
    chengeOrderActionLogs(logs) {
      let stocks = [];
      for (let i in logs) {
        stocks.push(logs[i]);
      }

      let pmaster = [];
      pmaster = [
        '全媒体',
        'GoogleSearch',
        'YahooSearch',
        'GDN',
        'YDN',
        'GoogleDiscovery',
        'Facebook',
        'Criteo',
        'LINE',
        'RTBHOUSE',
        'SmartNews',
        'Twitter',
      ];

      function _str2rank(str) {
        if (str) {
          for (let i = 0; i < pmaster.length; i++) {
            if (str.indexOf(pmaster[i]) >= 0) {
              return i;
            }
          }
        }
        return 99999;
      }

      function _timestr2number(str) {
        try {
          let md = str.split('/');
          if (md && md.length > 1) {
            return Number(md[1]) * 100 + Number(md[0]);
          }
        } catch (ex) {
          console.log(ex);
        }

        return 99999;
      }

      stocks.sort(function (a, b) {
        try {
          if (_str2rank(a.provider) < _str2rank(b.provider)) {
            return -1;
          } else if (_str2rank(a.provider) > _str2rank(b.provider)) {
            return 1;
          } else {
            let dlen = a.provider.split(',').length - b.provider.split(',').length;
            if (dlen != 0) {
              return dlen;
            } else {
              return _timestr2number(a.implementationdate) - _timestr2number(b.implementationdate);
            }
          }
        } catch (ex) {
          console.log(ex);
        }

        return 0;
      });

      return stocks;
    },
    type2str(ap) {
      let type = 0;
      try {
        type = ap.type;
      } catch (ex) {
        console.log(ex);
      }
      for (let i in sprintApps) {
        let sa = sprintApps[i];
        if (sa.id == type) {
          return sa.data;
        }
      }
      return '-';
    },
    ratescale3StepSprintEx(type, data) {
      let typ = type;
      if (isNaN(data) || data == '') {
        return 0;
      }
      switch (typ) {
        case '売上':
        case '売り上げ':
          if (data >= 130) return 1;
          else if (data >= 115) return 2;
          else if (data >= 100) return 3;
          else if (data >= 86) return 4;
          else if (data >= 71) return 5;
          else return 6;
      }
      return 0;
    },
    ratescaleArrawClass(type, data) {
      let cln = '';
      let typ = '';
      if (type) {
        typ = type.toLowerCase();
      }
      let step = ratescale3Step(typ, data);
      if (step == 0) {
        step = this.ratescale3StepSprintEx(typ, data);
      }
      switch (step) {
        case 1:
          return 'gbarrow3step--1';
        case 2:
          return 'gbarrow3step--2';
        case 3:
          return 'gbarrow3step--3';
        case 4:
          return 'gbarrow3step--4';
        case 5:
          return 'gbarrow3step--5';
        case 6:
          return 'gbarrow3step--6';
        case 11:
          return 'gbarrow3step--11';
        case 12:
          return 'gbarrow3step--12';
        case 13:
          return 'gbarrow3step--13';
        case 14:
          return 'gbarrow3step--14';
        case 15:
          return 'gbarrow3step--15';
        case 16:
          return 'gbarrow3step--16';
      }

      return cln;
    },
    data2str(str) {
      if (str) {
        if (str.length > 0) {
          return str;
        }
      }
      return 'None';
    },
    vtunit(value, dataType) {
      if (value) {
        console.log(value);
      } else {
        return 'None';
      }
      function comma(num) {
        return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }
      let result = value;
      let ret = null;
      switch (dataType) {
        case 'rate':
          ret = comma(value);
          if (Number(value) < 10) {
            // 小数点以下2位まで表示
            if (ret.includes('.')) {
              if (ret.split('.')[1].length == 1) ret = ret + '0';
            } else {
              ret = ret + '.00';
            }
          } else {
            // 小数点以下1位まで表示
            if (!ret.includes('.')) {
              ret = ret + '.0';
            }
            if (ret.split('.')[1].length == 2) ret = ret.slice(0, -1);
          }
          return ret + '%';
        case 'currency':
          return '¥' + comma(value);
        case 'number':
          return comma(value);
        case 'count':
          return comma(value);
        case 'rate_roas':
          return comma(value) + '%';
      }

      return result;
    },
    approachpolicyJson(str) {
      try {
        if (str) {
          let json = JSON.parse(str);
          return json;
        }
      } catch (ex) {
        console.log(ex);
      }
      return JSON.parse('[]');
    },
    monthlystatusJson(str) {
      try {
        if (str) {
          let json = JSON.parse(str);
          return json;
        }
      } catch (ex) {
        console.log(ex);
      }
      return JSON.parse('[]');
    },
    ratescale(type, data) {
      let colScale2 = [
        '#37803A', // good
        '#449E48',
        '#5CB860',
        '#9BD39E',
        '#FAAEAA',
        '#F66D65',
        '#F2382C',
        '#DA1B0E', // bad
      ];
      if (data == '') {
        return '#ffffff';
      }
      if (type == 'cost') {
        if (data >= 130) return colScale2[7];
        else if (data >= 120) return colScale2[6];
        else if (data >= 110) return colScale2[5];
        else if (data >= 105) return colScale2[4];
        else if (data >= 103) return colScale2[3];
        else if (data >= 102) return colScale2[2];
        else if (data >= 101) return colScale2[1];
        else if (data >= 100) return colScale2[0];
        else if (data >= 99) return colScale2[1];
        else if (data >= 98) return colScale2[2];
        else if (data >= 96) return colScale2[3];
        else if (data >= 91) return colScale2[4];
        else if (data >= 81) return colScale2[5];
        else if (data >= 71) return colScale2[6];
        else return colScale2[7];
      }
      if (type == 'cv') {
        if (data >= 130) return colScale2[0];
        else if (data >= 120) return colScale2[1];
        else if (data >= 110) return colScale2[2];
        else if (data >= 100) return colScale2[3];
        else if (data >= 91) return colScale2[4];
        else if (data >= 81) return colScale2[5];
        else if (data >= 71) return colScale2[6];
        else return colScale2[7];
      }
      if (type == 'cpa') {
        if (data >= 130) return colScale2[7];
        else if (data >= 120) return colScale2[6];
        else if (data >= 110) return colScale2[5];
        else if (data >= 101) return colScale2[4];
        else if (data >= 91) return colScale2[3];
        else if (data >= 81) return colScale2[2];
        else if (data >= 71) return colScale2[1];
        else return colScale2[0];
      }
      if (type == 'roas') {
        if (data >= 130) return colScale2[0];
        else if (data >= 120) return colScale2[1];
        else if (data >= 110) return colScale2[2];
        else if (data >= 101) return colScale2[3];
        else if (data >= 91) return colScale2[4];
        else if (data >= 81) return colScale2[5];
        else if (data >= 71) return colScale2[6];
        else return colScale2[7];
      }

      return '#ffffff';
    },
    spanTilte2HeaderTitle(title) {
      return spanTilte2HeaderTitle(title);
    },
    isCells2SpanCells(cells, type = null) {
      let cs = cells2SpanCells(cells, type);
      for (let i in cs) {
        let c = cs[i];
        if (c.isSpan()) {
          return true;
        }
      }
      return false;
    },
    cells2SpanCells(cells, type = null) {
      let numbers = [];
      if (type != null) {
        let ic = this.page.invisibleColumnStocker.getComponentInvisibleColumn(
          this.page.advertizerId,
          'summary',
          type
        );
        if (ic) {
          numbers = ic.invisiblenumbers;
        }
      }
      return cells2SpanCells(cells, numbers);
    },
    titles2Cell(titles) {
      return titles2Cell(titles);
    },
    initialize(id, pageComponents, advertizerId, timeRange) {
      const self = this;
      if (this.isLoad) {
        return;
      }
      this.isLoad = true;
      this.page.cancelAllAPICall();
      this.page.initialize(id, pageComponents, advertizerId, timeRange, this.adx.user, this.adx.getScreenStructureById('report').colorMaster);
      this.page.updateload(function (date) {
        if (self.isActive) {
          self.adx.changeLastUpdate(date);
        }
      });
      this.page.updatecstrp(function(isExist){
        if (self.isActive) {
          if (isExist) {
            self.adx.changeCstCustomReport(true);
          }
        }
      });
    },
    getTodayYmd() {
      let time = this.adx.getNowTime();
      return new TimeYmd(time.ymd.year, time.ymd.month, time.ymd.day);
    },
    mouseOverDaily() {
      this.isShowDailyAlertHover = true;
    },
    mouseLeaveDaily() {
      this.isShowDailyAlertHover = false;
    },
    mouseOverWeekly() {
      this.isShowWeeklyAlertHover = true;
    },
    mouseLeaveWeekly() {
      this.isShowWeeklyAlertHover = false;
    },
    toggleWeeklyAlert() {
      //this.isShowWeeklyAlert = !this.isShowWeeklyAlert;//[ALERT1]alertは１件でいい
    },
    isShowWeeklyAlertCheck() {
      return this.isShowWeeklyAlertHover || this.isShowWeeklyAlert;
    },
    toggleDailyAlert() {
      //this.isShowDailyAlert = !this.isShowDailyAlert;//[ALERT1]alertは１件でいい
    },
    isShowDailyAlertCheck() {
      return this.isShowDailyAlertHover || this.isShowDailyAlert;
    },
    isRangeMode() {
      if (this.dateRangeSelector) {
        return this.dateRangeSelector.mode == 1;
      }
      return false;
    },
    cpccpacvter(pmtf, cell) {
      if (cell) {
        if (cell.key === 'cpc') {
          return !pmtf;
        }
        if (cell.key.match(/cpa/)) {
          return !pmtf;
        }
      }
      return pmtf;
    },
    rangecalendarevent: function (event, arg) {
      if (event == 'apply') {
        this.dateRangeSelector.setRangeYmd(
          arg.startYmd.year,
          arg.startYmd.month,
          arg.startYmd.day,
          arg.endYmd.year,
          arg.endYmd.month,
          arg.endYmd.day
        );
      }
      this.isShowRange = false;
    },
    timerangeevent: function (event) {
      console.log(event);
      this.isShowRange = true;
    },
    getModeName() {
      let name = '';
      switch (this.page.selectedMode) {
        case 1:
          name = '月別';
          break;
        case 2:
          name = '週別';
          break;
        case 3:
          name = '日別';
          break;
      }
      return name;
    },
    changeMode(mode) {
      this.page.selectedMode = mode;
    },
    getIcon(id) {
      //TBD
      switch (id) {
        case 1:
          return require('@/assets/icon/Google.png');
        case 2:
          return require('@/assets/icon/Google.png');
        case 3:
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 4:
          return require('@/assets/icon/Google.png');
        case 5:
          return require('@/assets/thirdparty/icon/yahoo.png');
        case 6:
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
        case 7:
          return require('@/assets/icon/LINE_APP_Android.png');
        case 8:
          return require('@/assets/thirdparty/icon/Criteo.png');
        case 9:
          return require('@/assets/icon/Google.png');
        case 10:
          return require('@/assets/icon/Google.png');
        case 11:
          return require('@/assets/icon/f_logo_RGB-Blue_1024.png');
        case 12:
          return require('@/assets/icon/Google.png');
        case 13:
          return require('@/assets/icon/Google.png');
        case 14:
          return require('@/assets/icon/Google.png');
        case 126:
          return require('@/assets/icon/Google.png');
      }
      return '';
    },
  },
  components: {
    SideMenu,
    ContentHeader,
    Spinner,
    // Vprogress,
    // HProgressCard,
    ChartJs,
    RangeCalendar,
    // BarProgress,
    M2ChartJs,
    CircleProgress,
    ColumnShowHide,
    BoxSpinner,
    ScreenBarProgress2,
  },
  props: {
    adx: Object,
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.scroller);

    this.page.cancelAllAPICall();

    this.isActive = false;
    this.adx.changeLastUpdate('');
    // this.adx.changeCstCustomReport(false);

    this.adx.excelreport.setCallback(null);
    this.adx.excelreport.reset();
  },
};
</script>
